import { IStrategyField } from "../../../../../../../strategy/interface/IStrategyField";

export const getContractsFields = (): IStrategyField[] => {
  return [
    {
      title: "Дата создания документа",
      code: "id",
      isSimple: true,
      immutable: false,
      maxWidth: "90px",
      textAlign: "center",
    },
    {
      title: "Название документа",
      code: "number",
      isSimple: true,
      immutable: false,
      minWidth: "250px",
    },
    {
      title: "НДС",
      code: "isNDS",
      isSimple: true,
      immutable: false,
      minWidth: "50px",
    },
    {
      title: "Марк. наша",
      code: "ourMarking",
      isSimple: true,
      immutable: false,
      minWidth: "50px",
    },
    {
      title: "ID Оплаты / Канал / ID интеграции",
      code: "payment",
      isSimple: true,
      minWidth: "375px",
    },
    {
      title: "Сумма документа",
      code: "summ",
      isSimple: true,
      immutable: false,
      textAlign: "center",
      minWidth: "165px",
      maxWidth: '100px'
    },
    {
      title: "Ссылка на договор в облаке",
      code: "documentPath",
      maxWidth: "100px",
      immutable: false,
      isSimple: true,
    },
    {
      title: "Действия с документом",
      code: "size",
      immutable: false,
      isSimple: true,
      minWidth: "100px",
      maxWidth: "100px",
      textAlign: "center",
    },
  ];
};
