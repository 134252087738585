import { Comment, Favorite, Visibility } from "@mui/icons-material";
import {
  Avatar,
  Box,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { CancelButton } from "../../../components/UI/Buttons/CancelButton/CancelButton";
import { CustomButton } from "../../../components/UI/Buttons/CustomButton/CustomButton";
import { getDatePickerIconsStyle } from "../../../components/UI/DateCustomInput/shared/getDatePickerIconsStyle";
import ReleasesService from "../../../services/releases/releases.service";
import channelStore from "../../../store/channel.store";
import releasePlacementsStore from "../../../store/releasePlacements.store";
import { IAsideReleaseForm } from "../models/IAsideReleaseForm";
import { useDebounce } from "../../../hooks/useDebounce";
import { IReleasesValidateViews } from "../../../services/releases/IReleasesRequest";
import { AxiosError } from "axios";

export const AsideReleaseForm: FC<IAsideReleaseForm> = observer((props) => {
  const {
    releaseId,
    typeValue,
    error = false,
    loading = false,
    onClose,
    onSubmit,
    linkValue,
    onChangeLink,
    viewValue,
    onChangeView,
    likeValue,
    onChangeLike,
    commentsValue,
    onChangeComments,
    selectValueRelease,
    onChangeSelect,
    dateValue,
    onChangeDate,
    timeValue,
    onChangeTime,
    placementValue,
    onChangePlacement,
  } = props;

  const [viewsError, setViewsError] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const checkValidity = useCallback(() => {
    if (
      linkValue === null ||
      linkValue === "" ||
      dateValue === null ||
      !dayjs(dateValue).isValid() ||
      viewsError
    ) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [linkValue, dateValue, viewsError]);

  const debounce = useDebounce((value: string) => {
    if (releaseId) {
      const option: IReleasesValidateViews = {
        releaseId: releaseId,
        views: +value,
      };

      ReleasesService.releaseValidateViews(option)
        .then((res) => {
          setViewsError(!res.data);
        })
        .catch((error) => {
          if (error instanceof AxiosError) {
            setViewsError(true);
          }
        });
    } else return;
  }, 1000);

  const handleChangeView = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    debounce(value);

    checkValidity();

    onChangeView(e);
  };

  // useEffect(() => {
  //   if (viewValue < prevViewValue) {
  //     setViewsError(true);
  //   } else {
  //     setViewsError(false);
  //   }
  // }, [viewValue]);

  useEffect(() => {
    checkValidity();
  }, [checkValidity]);

  return (
    <Box sx={{ p: 1, pb: 0, pt: 0 }}>
      <Stack direction={"column"} spacing={1}>
        <Typography>
          Ссылка релиза:{" "}
          <Typography component={"span"} color={"error"}>
            *
          </Typography>
        </Typography>
        <TextField
          size="small"
          fullWidth
          placeholder="Добавьте ссылку"
          value={linkValue}
          onChange={onChangeLink}
          disabled={loading}
        />
        <Typography>Статистика:</Typography>
        <Stack position="relative">
          <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
            <TextField
              size="small"
              placeholder="Просмотры"
              type="number"
              value={viewValue}
              onChange={handleChangeView}
              disabled={loading}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Visibility color="action" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              size="small"
              placeholder="Лайки"
              type="number"
              value={likeValue}
              onChange={onChangeLike}
              disabled={loading}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Favorite color="error" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              size="small"
              placeholder="Комментарии"
              type="number"
              value={commentsValue}
              onChange={onChangeComments}
              disabled={loading}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Comment color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          {viewsError && (
            <Typography color="error">
              Количество просмотров не может быть меньше числа, внесенного ранее
            </Typography>
          )}
        </Stack>
        <Stack>
          <Typography sx={{ mb: 1 }}>
            Выберите площадку:{" "}
            <Typography component={"span"} color={"error"}>
              *
            </Typography>
          </Typography>
          <FormControl>
            <InputLabel id="demo-simple-select-label" size="small">
              Тип
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              label="Тип"
              size="small"
              value={selectValueRelease}
              onChange={onChangeSelect}
              disabled={loading}
              renderValue={(val) => (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    src={channelStore?.channels![val]?.icon}
                    sx={{ height: 20, width: 20, mr: 1 }}
                  />
                  <Typography variant="body2">{channelStore?.channels![val]?.name}</Typography>
                </Box>
              )}>
              {Object.keys(toJS(channelStore).channels!).map((item) => (
                <MenuItem key={item} value={item}>
                  <Avatar
                    src={channelStore?.channels![item]?.icon}
                    sx={{ height: 25, width: 25, mr: 1 }}
                  />
                  <Typography>{channelStore?.channels![item]?.name}</Typography>
                </MenuItem>
              ))}
            </Select>
            {typeValue && selectValueRelease !== typeValue && (
              <Typography color={"#f44336"}>
                Обратите внимание, выбранная площадка отличается от площадки канала
              </Typography>
            )}
          </FormControl>
        </Stack>
        <Stack>
          <Typography sx={{ mb: 1 }}>Местоположение рекламы:</Typography>
          <FormControl>
            <InputLabel id="select-label" size="small">
              Позиция
            </InputLabel>
            <Select
              labelId="select-label"
              label="Позиция"
              size="small"
              value={placementValue}
              onChange={onChangePlacement}
              disabled={loading}>
              {releasePlacementsStore.placements?.map((item) => (
                <MenuItem key={item.code} value={item.code}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack spacing={1}>
          <Typography>
            Выберите дату:{" "}
            <Typography component={"span"} color={"error"}>
              *
            </Typography>
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
            <DatePicker
              value={dateValue}
              onChange={(val: any) => onChangeDate(val)}
              disabled={loading}
              slotProps={{
                textField: { size: "small", placeholder: "дд.мм.год" },
                ...(getDatePickerIconsStyle() as any),
              }}
            />
          </LocalizationProvider>
        </Stack>
        <Stack spacing={1}>
          <Typography>
            Выберите время:{" "}
            <Typography component={"span"} color={"error"}>
              *
            </Typography>
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
            <TimePicker
              value={timeValue}
              onChange={(val: any) => onChangeTime(val)}
              disabled={loading}
              slotProps={{
                textField: { size: "small", placeholder: "часы:минуты" },
                ...(getDatePickerIconsStyle() as any),
              }}
            />
          </LocalizationProvider>
        </Stack>
        {error && <Typography color={"error"}>Заполните все поля</Typography>}
        <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
          <CancelButton variant="outlined" onClick={onClose} disabled={loading}>
            Отмена
          </CancelButton>
          <CustomButton
            variant="contained"
            disabled={loading || disableButton}
            onClick={onSubmit}
            endIcon={loading && <CircularProgress sx={{ maxHeight: 20, maxWidth: 20 }} />}>
            Сохранить
          </CustomButton>
        </Stack>
      </Stack>
    </Box>
  );
});
