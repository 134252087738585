import { FC, useEffect, useRef, useState } from "react";
import createDocumentStore from "../../../../../../store/createDocument.store";
import { Box, Modal } from "@mui/material";
import { CustomIconButton } from "../../../../../../components/UI/Buttons/CustomIconButton/CustomIconButton";
import { Cancel } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { renderAsync } from "docx-preview";

const ShowDocumentModal: FC = observer(() => {
  const [isBlobReady, setBlobReady] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleCloseModal = () => {
    createDocumentStore.resetBlob();
    setBlobReady(false);
  };

  useEffect(() => {
    if (createDocumentStore.documentBlob && containerRef.current) {
      containerRef.current.innerHTML = "";
      renderAsync(createDocumentStore.documentBlob, containerRef.current)
        .then(() => {
          setBlobReady(true);
        })
        .catch((err) => {
          console.error("Ошибка рендеринга документа:", err);
          setBlobReady(false);
        });
    }
  }, [createDocumentStore.documentBlob]);

  return (
    <Modal
      open={!!createDocumentStore.documentBlob}
      onClose={handleCloseModal}
      keepMounted
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Box
        sx={{
          position: "relative",
          height: "calc(100% - 40px)",
          overflow: "hidden",
          width: "810px",
        }}>
        <CustomIconButton
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            top: 20,
            right: 45,
            zIndex: 1000,
          }}>
          <Cancel />
        </CustomIconButton>

        {!isBlobReady && <Box>Загрузка документа...</Box>}

        <Box
          ref={containerRef}
          sx={{
            height: "calc(100% - 40px)",
            overflowY: "auto",
          }}
        />
      </Box>
    </Modal>
  );
});

export default ShowDocumentModal;
