import {FC} from 'react';
import {IContractItem} from "./IContractItem";
import {Stack} from "@mui/material";
import {AsideCardLayout} from "../../../../../../components/AsideCardLayout/AsideCardLayout";
import ContractTable from "../СontractTable/ContractTable";
import {getContract} from "../../shared/dataList";
import dayjs from "dayjs";

const ContractItem:FC<IContractItem> = (props) => {
    const {
        data
    } = props

    return (
        <AsideCardLayout headerTitle={`Договор №${data.id} от ${dayjs(data.contract.date).format('DD.MM.YYYY')}`} sx={{marginTop: '10px', fontSize: '54px', fontWeight: 'bold'}}>
            <Stack spacing={1}>
                <ContractTable
                    rows={getContract()}
                    data={data}
                />
            </Stack>
        </AsideCardLayout>
    );
};

export default ContractItem;