import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import createDocumentStore from "../../../../../../store/createDocument.store";
import { placementCharacterSamples } from "../CreateAttachment/shared/placementCharacterSamples";
import CreateDocumentSelect from "../CreateDocumentSelect/CreateDocumentSelect";
import { IPlacementCharacterField } from "./IPlacementCharacterFieltd";

export const PlacementCharacterField: FC<IPlacementCharacterField> = observer((props) => {
  const { onChange, name } = props;

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { value } = e.target;

    createDocumentStore.setPlacementCharacterSelect(value);
    createDocumentStore.setAttachmentPlacementCharacter(value);
  };

  return (
    <Stack spacing={2}>
      <CreateDocumentSelect
        onChange={handleSelectChange}
        options={placementCharacterSamples}
        value={createDocumentStore.placementCharacterSelect}
        title="Характер размещения"
        required
        placeholder="Выберите шаблон"
      />
      <TextField
        size="small"
        value={createDocumentStore.attachment.placementCharacter}
        minRows={10}
        name={name}
        multiline
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
        sx={{ maxWidth: "450px" }}
      />
    </Stack>
  );
});
