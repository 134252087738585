import { FormControl, Stack } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { CustomButton } from "../../../../../../components/UI/Buttons/CustomButton/CustomButton";
import { IContractorCreateContract } from "../../../../../../services/contractor/IContractorIRequest";
import createDocumentStore from "../../../../../../store/createDocument.store";
import CreateDocumentAccordion from "../CreateDocumentAccordion/CreateDocumentAccordion";
import CreateDocumentDatePicker from "../CreateDocumentDatePicker/CreateDocumentDatePicker";
import CreateDocumentField from "../CreateDocumentField/CreateDocumentField";
import { CreateDocumentRadioGroup } from "../CreateDocumentRadioGroup/CreateDocumentRadioGroup";

const CreateContract: FC = observer(() => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleChangeCreationMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    createDocumentStore.setOurContract(value === "true");
    validateRequired();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === "ourMarking" || name === "isNDS") {
      createDocumentStore.setContractField(
        name as keyof IContractorCreateContract,
        value === "true"
      );
      return;
    }

    createDocumentStore.setContractField(name as keyof IContractorCreateContract, value);
    validateRequired();
  };

  const handleChangeDate = (date: Dayjs | null, name?: string) => {
    if (date && date.isValid()) {
      createDocumentStore.setContractField(name as keyof IContractorCreateContract, date);
    } else {
      createDocumentStore.setContractField(name as keyof IContractorCreateContract, "");
    }

    validateRequired();
  };

  const validateRequired = () => {
    const isFormValid = Object.entries(createDocumentStore.contract).every(([key, value]) => {
      if (dayjs.isDayjs(value)) {
        return value.isValid();
      }

      if ((key === "link" || key === "city") && !createDocumentStore.ourContract) {
        return true;
      }

      if (typeof value === "string") {
        return value.length > 0;
      }

      if (typeof value === "boolean") {
        return true;
      }

      return !!value;
    });

    setIsFormValid(isFormValid);
  };

  const createContract = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    createDocumentStore.createContract().then(() => {
      validateRequired();
      setIsOpen(false);
    });
  };

  useEffect(() => {
    validateRequired();
  }, []);

  return (
    <CreateDocumentAccordion title="Создание договора" isOpen={isOpen} setIsOpen={setIsOpen}>
      <FormControl onSubmit={createContract} component={"form"} fullWidth>
        <Stack direction={"column"} spacing={2}>
          <CreateDocumentRadioGroup
            name=""
            title="Мы создаем договор"
            value={createDocumentStore.ourContract}
            onChange={handleChangeCreationMethod}
            defaultValue={true}
          />
          <CreateDocumentField
            name="contractNumber"
            title="№ Договора"
            onChange={handleChange}
            placeholder="Введите номер договора"
            required
            value={createDocumentStore.contract.contractNumber}
          />
          <CreateDocumentDatePicker
            name="contractDate"
            title="Дата договора"
            required
            onChange={handleChangeDate}
            placeholder="дд.мм.гггг"
            value={createDocumentStore.contract.contractDate}
          />
          {createDocumentStore.ourContract ? (
            <>
              <CreateDocumentField
                name="city"
                title="Населенный пункт"
                onChange={handleChange}
                placeholder="Введите название населенного пункта"
                value={createDocumentStore.contract.city}
                required
              />
              <CreateDocumentRadioGroup
                name="ourMarking"
                title="Наша маркировка"
                value={createDocumentStore.contract.ourMarking}
                onChange={handleChange}
                defaultValue={true}
              />
            </>
          ) : (
            <CreateDocumentField
              name="link"
              title="Ссылка на договор"
              onChange={handleChange}
              placeholder="Введите ссылку"
              value={createDocumentStore.contract.link}
            />
          )}
          <CreateDocumentRadioGroup
            name="isNDS"
            title="С НДС"
            value={createDocumentStore.contract.isNDS}
            onChange={handleChange}
            defaultValue={false}
          />
          <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>
            <CustomButton
              sx={{ mt: 1 }}
              variant={"contained"}
              type="submit"
              disabled={!isFormValid}>
              Создать договор
            </CustomButton>
          </Stack>
        </Stack>
      </FormControl>
    </CreateDocumentAccordion>
  );
});

export default CreateContract;
