import { useEffect, useState } from "react";
import { IPostedCommentItem } from "../../../../../components/PostedCommentItem/models/IPostedCommentItem";
import { ICreateCommentsRequest } from "../../../../../services/common/ICommonRequest";
import CommonService from "../../../../../services/common/common.service";
import { AxiosError } from "axios";
import { AsideCardComments } from "../../../../TableAsidePage/ui/AsideCardComments/AsideCardComments";
import createDocumentStore from "../../../../../store/createDocument.store";
import { observer } from "mobx-react-lite";

interface Props {
  posted_comments: any[];
  id: number;
}

export const ContrActorAsideCardComments = observer((props: Props) => {
  const { posted_comments, id } = props;

  const [postedComments, setPostedComments] = useState(posted_comments || []);

  const editComment = (id: number, updatedComment: IPostedCommentItem) => {
    setPostedComments(
      postedComments.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            ...updatedComment,
          };
        }
        return item;
      })
    );
  };

  const deletePostedComments = (id: number) => {
    setPostedComments(postedComments.filter((item) => item.id !== id));
  };

  const addComment = (value: string) => {
    if (id) {
      const option: ICreateCommentsRequest = {
        toEntity: "counteragent",
        entity_id: id,
        text: value,
      };
      CommonService.createComments(option)
        .then((res) => {
          setPostedComments((prevState) => [...prevState, res.data]);
        })
        .catch((err) => {
          if (err instanceof AxiosError) {
          }
        });
    }
  };

  return (
    <AsideCardComments
      sxBtnResize={{ display: { xs: "none", lg: "block" } }}
      comments={postedComments || []}
      addComment={addComment}
      sx={{ maxHeight: { xs: "300px", lg: "720px" } }}
      entity="counteragent"
      onChangeComments={() => ""}
      onEditComments={editComment}
      onDeleteComments={deletePostedComments}
      defaultOpen={false}
      onCloseChat={() => createDocumentStore.setChatOpen(false)}
      onOpenChat={() => createDocumentStore.setChatOpen(true)}
      entityId={id}
      showResizeBtn
    />
  );
});
