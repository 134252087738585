import * as yup from "yup";
import { ContractorTypesEnum } from "../../../../../../../entities/Contractor/models/ContractorTypesEnum";

enum FormErrorsEnum {
  TYPE_REQUIRED = "Выберите тип контрагента",
  FULLNAME_ERROR = "Введите полные данные ФИО",
  NAME_INVALID = "Имя должно состоять из литералов",
  INN_10_SYMBOLS = "ИНН должен содержать 10 цифр",
  INN_12_SYMBOLS = "ИНН должен содержать 12 цифр",
  REQUIRED = "Заполните поле",
  URL_INVALID = "Должно быть корректной ссылкой",
  RS_INVALID = "Р/c должен содержать 20 цифр",
  KS_INVALID = "К/c должен содержать 20 цифр",
  BIK_INVALID = "Бик должен содержать 9 цифр",
  OGRN_INVALID = "ОГРН должен содержать 13 цифр",
  OGRNIP_INVALID = "ОГРН должен содержать 15 цифр",
  KPP_INVALID = "КПП должен содержать 9 цифр",
}

const regEx = /^[A-Za-zА-Яа-яЁё\s]+$/;

export const validationSchema = yup.object().shape({
  typeCode: yup.string().required(FormErrorsEnum.TYPE_REQUIRED),
  name: yup
    .string()
    .required(FormErrorsEnum.REQUIRED)
    .when("typeCode", {
      is: (typeCode: string) =>
        typeCode === ContractorTypesEnum.OOO || typeCode === ContractorTypesEnum.OTHER,
      otherwise: (schema) =>
        schema
          .test("is-full-name", FormErrorsEnum.FULLNAME_ERROR, (value) => {
            const words = value ? value.trim().split(" ") : [];
            return words.length === 3;
          })
          .matches(regEx, { message: FormErrorsEnum.NAME_INVALID }),
    }),
  inn: yup
    .string()
    .required(FormErrorsEnum.REQUIRED)
    .when("typeCode", {
      is: (typeCode: string) =>
        typeCode === ContractorTypesEnum.OOO || typeCode === ContractorTypesEnum.OTHER,
      then: (schema) =>
        schema.min(10, FormErrorsEnum.INN_10_SYMBOLS).max(10, FormErrorsEnum.INN_10_SYMBOLS),
      otherwise: (schema) =>
        schema.min(12, FormErrorsEnum.INN_12_SYMBOLS).max(12, FormErrorsEnum.INN_12_SYMBOLS),
    }),
  edo: yup.boolean().required(),
  operatorCode: yup.string().when("edo", {
    is: true,
    then: (schema) => schema.required(FormErrorsEnum.REQUIRED),
    otherwise: (schema) => schema.notRequired(),
  }),
  contractLink: yup.string().url(FormErrorsEnum.URL_INVALID),
  accountNumber: yup.string().min(20, FormErrorsEnum.RS_INVALID).max(20, FormErrorsEnum.RS_INVALID),
  corrAccount: yup.string().min(20, FormErrorsEnum.KS_INVALID).max(20, FormErrorsEnum.KS_INVALID),
  bic: yup.string().min(9, FormErrorsEnum.BIK_INVALID).max(9, FormErrorsEnum.BIK_INVALID),
  companyDetails: yup.object().shape({
    businessAddress: yup.string(),
    kpp: yup.string().when("$typeCode", {
      is: ContractorTypesEnum.OOO,
      then: (schema) =>
        schema.min(9, FormErrorsEnum.KPP_INVALID).max(9, FormErrorsEnum.KPP_INVALID),
    }),
    ogrn: yup.string().when("$typeCode", {
      is: ContractorTypesEnum.OOO,
      then: (schema) =>
        schema.min(13, FormErrorsEnum.OGRN_INVALID).max(13, FormErrorsEnum.OGRN_INVALID),
    }),
  }),
  otherDetails: yup.object().shape({
    businessAddress: yup.string(),
    kpp: yup.string().when("$typeCode", {
      is: ContractorTypesEnum.OTHER,
      then: (schema) =>
        schema.min(9, FormErrorsEnum.KPP_INVALID).max(9, FormErrorsEnum.KPP_INVALID),
    }),
    ogrn: yup.string().when("$typeCode", {
      is: ContractorTypesEnum.OTHER,
      then: (schema) =>
        schema.min(13, FormErrorsEnum.OGRN_INVALID).max(13, FormErrorsEnum.OGRN_INVALID),
    }),
  }),
  ipDetails: yup.object().shape({
    ogrnip: yup.string().when("$typeCode", {
      is: (typeCode: string) =>
        ContractorTypesEnum.IP === typeCode || ContractorTypesEnum.IP_ARBIN === typeCode,
      then: (schema) =>
        schema.min(15, FormErrorsEnum.OGRNIP_INVALID).max(15, FormErrorsEnum.OGRNIP_INVALID),
    }),
  }),
  fio: yup
    .string()
    .test("is-full-name", FormErrorsEnum.FULLNAME_ERROR, (value) => {
      if (!value) return true;
      const words = value ? value.trim().split(" ") : [];
      return words.length === 3;
    })
    .matches(regEx, { message: FormErrorsEnum.NAME_INVALID }),
  directorTitle: yup.string(),
});
