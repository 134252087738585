import { observer } from "mobx-react-lite";
import { FC } from "react";
import ContrActorGridLayout from "../ui/ContrActorGridLayout/ContrActorGridLayout";
import { IContrActorPage } from "./IContrActorPage";
import { BasicInformation } from "./ui/BasicInformation/BasicInformation";
import { ContrActorContacts } from "./ui/ContrActorContacts/ContrActorContacts";

export const ContrActorPage: FC<IContrActorPage> = observer((props) => {
  const { data } = props;

  return (
    <ContrActorGridLayout data={data}>
        <BasicInformation />
        <ContrActorContacts />
      </ContrActorGridLayout>
  )
})