import { FC } from "react";
import { IChannelsPage } from "./IChannelsPage";
import { Box, Typography } from "@mui/material";
import { AsideCardLayout } from "../../../../components/AsideCardLayout/AsideCardLayout";
import ChannelsTable from "./ui/ChannelsTable/ChannelsTable";
import { getChannelContr, getFieldChannelsContr } from "./shared/dataList";
import { TableDefault } from "../../../../components/Table/TableDefault";

const ChannelsPage: FC<IChannelsPage> = (props) => {
  const { data } = props;
  return (
    <>
      <Box
        sx={{
          gridColumnStart: 1,
          gridColumnEnd: { xs: "auto", md: 2 },
          maxWidth: { xs: "calc(100vw - 52px)", sm: "calc(100vw - 70px - 32px)", md: "100%" },
        }}>
        <AsideCardLayout>
          <Box sx={{ display: "grid", height: "inherit" }}>
            {data.length > 0 ? (
              <TableDefault
                showBorderRadius={false}
                maxHeight="100%"
                tableRow={getChannelContr()}
                showBurgerCell
                isAccordion={true}>
                {data.map((item, i) => (
                  <ChannelsTable
                    rows={getChannelContr()}
                    dataField={getFieldChannelsContr(item)}
                    dataCont={item}
                    dataId={item.id}
                    key={item.id}
                  />
                ))}
              </TableDefault>
            ) : (
              <Typography variant="body1" noWrap sx={{ ml: 2, mr: 2, mt: 1 }}>
                В настоящий момент нет каналов
              </Typography>
            )}
          </Box>
        </AsideCardLayout>
      </Box>
    </>
  );
};

export default ChannelsPage;
