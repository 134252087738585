import  {FC} from 'react';
import {IContractTable} from "./IContractTable";
import {
    IAsideTableStatisticField
} from "../../../../../TableAsidePage/ui/AsideTableStatistic/models/IAsideTableStatisticField";
import {TableDefault} from "../../../../../../components/Table/TableDefault";
import {TableItem} from "../../../../../../components/Table/ui/TableItem";
import {getFieldContract} from "../../shared/dataList";

const ContractTable:FC<IContractTable> = (props) => {
    const {rows, data} = props

    const createItem = (fields:IAsideTableStatisticField[]):Object => {
        const item:any = {}

        for(let property of fields) {
            item[property.code] = property.value
            if(item[property.code] === 'payment_statusCode'){
                item['statusColor'] = item[property.code].hexColor
            }
        }

        return item
    }

    return (
        <TableDefault maxWidth='100%' tableRow={rows} showBorderRadius={false}>
            { data.payments.map(item =>
                <TableItem cells={rows} {...createItem(getFieldContract(item))} key={item.id} sx={{p:1, pt:1,pb:1}}/>
            )}
        </TableDefault>
    )
};

export default ContractTable;