import { FC, useEffect, useMemo, useState } from "react";
import { TableDefault } from "../Table/TableDefault";
import {
  IFiltersUpadteRequest,
  IIntegrationsRequest,
  includeInSelect,
} from "../../services/integration/IntegrationsRequest";
import IntegrationsService, {
  ICoolIntegrationParams,
} from "../../services/integration/integrations.service";
import { selectionStretegy } from "../../strategy/strategy";
import { IntegrationTableSettings } from "./ui/IntegrationTableSettings";
import integrationTableStore, { setCheckedParams } from "../../store/integrationTable.store";
import { toJS } from "mobx";
import userStore from "../../store/user.store";
import { Backdrop, Box, CircularProgress, TableRow, Typography } from "@mui/material";
import { ISettingsSelected } from "./models/ISettingsSelected";
import { AxiosError, AxiosProgressEvent } from "axios";
import { observer } from "mobx-react-lite";
import { NotificationAlerts } from "../NotificationAlerts/NotificationAlerts";
import { LS_TAKE_REQUEST_INTEGRATION } from "../../shared/constants/localstorage";
import { IntegrationTableList } from "./ui/IntegrationTableList";
import { useSearchParams } from "react-router-dom";
import { getFilterIntegrationQuery } from "./shared/dataList";
import { downloadExcel } from "../../shared/functions/functions";
import { NotificationProgress } from "../NotificationProgress/NotificationProgress";
import { VirtuosoTable } from "../VirtuosoTable/VirtuosoTable";
import { TestTable } from "../TestTable/TestTable";

interface IIntegrationTable {
  maxHeight?: string;
}

export const IntegrationTable: FC<IIntegrationTable> = observer((props) => {
  const { maxHeight } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const [tableHead, setTableHead] = useState<any[]>([]);
  const [currentSortType, setCurrentSortType] = useState("");

  const [isSettingsOpen, setSettingsOpen] = useState(false);

  const [isLoadingSettings, setLoadingSettings] = useState(false);

  const [tableError, setTableError] = useState<string | null>(null);

  const [showAlert, setShowAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState<string | null>(null);

  const [propgressExport, setPropgressExport] = useState(0);
  const [isExcelLoading, setExcelLoading] = useState(false);

  const [takeParamRequest, setTakeParamRequest] = useState(() => [
    `${
      localStorage.getItem(LS_TAKE_REQUEST_INTEGRATION)
        ? localStorage.getItem(LS_TAKE_REQUEST_INTEGRATION)
        : 100
    }`,
  ]);

  const onChaneTakeRequest = (event: React.MouseEvent<HTMLElement>, newFormats: string[]) => {
    if (newFormats !== null) {
      setTakeParamRequest(newFormats);
      localStorage.setItem(LS_TAKE_REQUEST_INTEGRATION, `${newFormats}`);
      if (searchParams.has("page")) {
        searchParams.set("page", "1");
        setSearchParams(searchParams);
      }
      searchParams.set("take", newFormats as any);
      setSearchParams(searchParams);
      integrationTableStore.setPage(1);
    } else return;
  };

  const sendSaveSettings = async (array: ISettingsSelected[]) => {
    setLoadingSettings(true);
    setErrorAlert(null);

    const options: IFiltersUpadteRequest = {
      entity: "integration",
      new_settings: array,
    };
    try {
      const res = await IntegrationsService.getUpdateFilters(options);
      // console.log(res.data, 'res data upadate filterrs')

      integrationTableStore.setSelectedList(res.data);
      integrationTableStore.setInitSettingsSelect(res.data);

      const totalArr: ISettingsSelected[] = [];

      for (let i = 0; i < res.data.length; i++) {
        totalArr.push(...res.data[i].fields);
      }

      totalArr.sort((a, b) => (a.ordering > b.ordering ? 1 : -1));

      integrationTableStore.setConfirmList([...totalArr].filter((x) => Boolean(x.isSelected)));
      integrationTableStore.setInitSettingsConfirm(
        [...totalArr].filter((x) => Boolean(x.isSelected))
      );

      const rows: any[] = [];

      toJS(integrationTableStore).confirmLists.map((field) => rows.push(selectionStretegy(field)));
      setTableHead(rows);

      setLoadingSettings(false);
      setSettingsOpen(false);
      setShowAlert(true);
    } catch (err) {
      if (err instanceof AxiosError) {
        console.log(err);
        setErrorAlert(err.message);
        setLoadingSettings(false);
      }
    }
  };

  const submitSettings = () => {
    sendSaveSettings(integrationTableStore.confirmLists);
  };

  const resetSettings = () => {
    const store = toJS(integrationTableStore);

    integrationTableStore.setConfirmList(store.initSettingsConfirm);
    integrationTableStore.setSelectedList(store.initSettingsSelect);
  };

  const deleteConfirmItem = (code: string) => {
    integrationTableStore.onConfirmDelete(code);
    integrationTableStore.removeConfirmList(code);
  };

  const getIntergrationData = async (page: number) => {
    integrationTableStore.setLoading(true);
    setTableError(null);

    // const take = localStorage.getItem(LS_TAKE_REQUEST_INTEGRATION)
    //   ? localStorage.getItem(LS_TAKE_REQUEST_INTEGRATION)
    //   : 100;

    const take = 500;

    const params: ICoolIntegrationParams = {
      filter: {
        status: ["release"],
      },
      select: [
        "cr",
        "cac",
        "cpv",
        "ctr",
        "roi",
        "lids",
        "views",
        "traffic",
        "crThrough",
        "ordersSumm",
        "validOrders",
        "canceledOrders",
        "averageOrderSumm",
        "goodsCategory.name",
        "initialPrice",
        "integration_date",
        "payment.isServiceProvided",
        "price",
        "qrCodeLink",
        "releaseUserId",
        "source",
        "promokod.code",
        "ordIntegrations.eridToken",
        "paidAt",
        "paidSum",
        "payment.plannedPaidAt",
        "payment.status.name",
        "payment.status.code",
        "isPreview",
        "isPromoAndSourceInDirectory",
        "isPromoWorks",
        "isTzSelected",
        "prediction_cpv",
        "prediction_cpv_new",
        "prediction_cr",
        "prediction_ctr",
        "prediction_orders",
        "prediction_roi",
        "prediction_roi_new",
        "prediction_traffic",
        "prediction_views",
        "id",
        "channel.id",
        "channel.link",
        "channel.name",
        "channel.govVerification.isVerifiedByGov",
        "channel.update_id",
        "channel.type.code",
        "channel.type.icon",
        "channel.type.name",
        "channel.size.name",
        "channel.theme.name",
        "firstIntegrationWithChannel",
        "tz.id"
      ],
      chunk: 0,
      take: 500,
      callBackType: "items",
      mainAlias: "integration",
      orderBy: [
        {
          field: "integration_date",
          direction: "DESC",
        },
      ],
    };

    try {
      const res = await IntegrationsService.getCoolIntegrations(params);
      // console.log(res, 'res integr table DATA')

      const rows: any[] = [];
      toJS(integrationTableStore).confirmLists.map((field) => rows.push(selectionStretegy(field)));

      setTableHead(rows);
      integrationTableStore.setTableItems(res.data.items);
      integrationTableStore.setTotalCount(res.data.totalCount);
      integrationTableStore.setLoading(false);
    } catch (err) {
      if (err instanceof AxiosError) {
        // console.log(err, 'err interg data')
        setTableError(err.message);
        integrationTableStore.setLoading(false);
      }
    }
  };

  const onDownloadProgress = (progressEvent: AxiosProgressEvent) => {
    if (progressEvent.estimated && progressEvent.total) {
      const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      setPropgressExport((prev) => progress);
    }
  };

  const onExportExcel = () => {
    setExcelLoading(true);
    setPropgressExport((prev) => 0);

    const params: IIntegrationsRequest = {
      includeInSelect: integrationTableStore.allFilter as includeInSelect[],
      take: +`${100}`,
      company: userStore?.currentCompany,
      page: 1,
      filterQuery: getFilterIntegrationQuery(searchParams),
    };
    IntegrationsService.integrationsFilteredExcel(params, onDownloadProgress)
      .then((res) => {
        downloadExcel(res.data, "Интеграции");
        setPropgressExport((prev) => 0);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, 'download excel err')
        }
      })
      .finally(() => {
        setExcelLoading(false);
      });
  };

  const sortHandler = (type: string, sortType: "asc" | "desc") =>
    integrationTableStore.sortTableItem(type, sortType);
  const addConfirmList = (item: ISettingsSelected) => integrationTableStore.addToConfirmList(item);
  const removeConfirmArr = (id: string) => integrationTableStore.removeConfirmList(id);
  const setSelectChecked = (params: setCheckedParams) =>
    integrationTableStore.setSelectedChecked(params);

  const changePage = (page: number) => {
    integrationTableStore.setPage(page);
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
    getIntergrationData(page);
  };

  const getFilteredIntegration = () => {
    const currentPage = Number(searchParams.get("page")) || 1;
    getIntergrationData(currentPage);
    integrationTableStore.setPage(currentPage);
  };

  const totalPages = Math.ceil(
    integrationTableStore.totalCount /
      (Array.isArray(takeParamRequest) ? +takeParamRequest[0] : takeParamRequest)
  );

  const allowedPageSizes = [100, 300, 500];

  const isPageSizeAllowed = allowedPageSizes.some(
    (number) => searchParams.get("take") === number.toString()
  );

  useEffect(() => {
    getFilteredIntegration();
    return () => {
      integrationTableStore.setTableItems([]);
    };
  }, [takeParamRequest]);

  useEffect(() => {
    const takeFromUrl = Number(searchParams.get("take"));
    if (searchParams.has("take")) {
      localStorage.setItem(LS_TAKE_REQUEST_INTEGRATION, `${takeFromUrl}`);
      setTakeParamRequest([takeFromUrl.toString()]);
    }
    if (searchParams.has("take") && !isPageSizeAllowed) {
      integrationTableStore.setIsPageNotFound(true);
    }
  }, []);

  useEffect(() => {
    if (totalPages && searchParams.has("page") && Number(searchParams.get("page")) > totalPages) {
      integrationTableStore.setIsPageNotFound(true);
    }
  }, [totalPages]);

  const restProps = useMemo(() => {
    return integrationTableStore.tableItems.map((item) => {
      const integrationProps = {
        ...item,
        integration_theme: "integration_theme",
      };
      return integrationProps;
    });
  }, [integrationTableStore.tableItems]);

  return (
    <>
      {isExcelLoading && <NotificationProgress propgress={propgressExport} />}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          integrationTableStore.isLoading === false &&
          tableError === null &&
          integrationTableStore.isLoadingSort &&
          !!integrationTableStore.tableItems?.length &&
          (currentSortType !== "" || searchParams.get("sort"))
            ? true
            : false
        }>
        <CircularProgress color="inherit" />
      </Backdrop>

      {integrationTableStore.isLoading === false && tableError && (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 2 }}>
          <Typography variant="h5" color="error">
            Ошибка с таблицей! {tableError}
          </Typography>
        </Box>
      )}
      {tableError === null && (
        <>
          <TestTable
            tableRow={tableHead}
            restProps={restProps}
            data={restProps}
            settingsOnclick={() => setSettingsOpen(true)}
          />
          {isSettingsOpen && (
            <IntegrationTableSettings
              open={isSettingsOpen}
              onClose={() => setSettingsOpen(false)}
              onSubmit={submitSettings}
              onReset={resetSettings}
              loading={isLoadingSettings}
              deleteConfirmItem={deleteConfirmItem}
              arrayConfirmItems={integrationTableStore.confirmLists}
              arraySelectItems={integrationTableStore.selectLists}
              onDragStart={integrationTableStore.onDragStart}
              onDragOver={integrationTableStore.onDragOver}
              onDrop={integrationTableStore.onDrop}
              addToConfirmList={addConfirmList}
              removeConfirmList={removeConfirmArr}
              setSelectedChecked={setSelectChecked}
              currentArray={integrationTableStore.confirmLists}
            />
          )}

          <NotificationAlerts
            sucsess={{
              text: "Настройки успешно сохранились!",
              open: showAlert,
              onClose: () => setShowAlert(false),
            }}
            error={{
              text: errorAlert ? errorAlert : "",
              open: errorAlert ? true : false,
              onClose: () => setErrorAlert(null),
            }}
          />
        </>
      )}
    </>
  );
});
