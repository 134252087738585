import { KeyboardArrowLeft } from "@mui/icons-material";
import { Box, Button, CircularProgress, Drawer, Skeleton, Stack, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { CustomIconButton } from "../../components/UI/Buttons/CustomIconButton/CustomIconButton";
import ContractorService from "../../services/contractor/contractor.service";
import { IContrActorFullResponse } from "../../services/contractor/IContractorIResponse";
import {
  FINANCE_CARD_OPEN,
  INTEGRATION_CARD_OPEN,
  PAYMENT_NUMBER,
} from "../../shared/constants/searchParams";
import createDocumentStore from "../../store/createDocument.store";
import editContrActorStore from "../../store/editContrActor.store";
import { AsideTabs } from "../TableAsidePage/ui/AsideTabs/AsideTabs";
import { IContrActorAsidePage, IContrActorTabItem } from "./IContrActorAsidePage";
import ChannelsPage from "./pages/ChannelsPage/ChannelsPage";
import ContractsPage from "./pages/ContractsPage/ContractsPage";
import { CreateDocument } from "./pages/CreateDocument/CreateDocument";
import { ContrActorPage } from "./pages/Main/ContrActorPage";

export const ContrActorAsidePage: FC<IContrActorAsidePage> = observer((props) => {
  const { contrActorId, onClose } = props;
  const { id } = useParams();
  const urlId = contrActorId ? contrActorId : id;
  const [isOpen, setOpen] = useState(false);
  const [subPage, setSubPage] = useState<number>(0);
  const [data, setData] = useState<IContrActorFullResponse | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const [contractorCardTabs] = useState<IContrActorTabItem[]>([
    { name: "Общее", value: 0 },
    { name: "Создание документов", value: 1 },
    { name: "Договоры", value: 2 },
    { name: "Каналы", value: 3 },
  ]);

  const getFullData = (id: number) => {
    setLoading(true);
    setError(null);

    ContractorService.getFull(id)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        editContrActorStore.setAgent(res.data.agent);
        editContrActorStore.setContacts(res.data.agent.id, res.data.agent.contact);
        editContrActorStore.setChannels(res.data.channels);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          setError(`Ошибка ${err.response?.data.statusCode}. ${err.response?.data.message}`);
          setLoading(false);
        }
      });
  };

  const closeModal = () => {
    setOpen(false);

    setTimeout(() => {
      onClose();
    }, 200);
  };

  useEffect(() => {
    if (urlId) {
      setOpen(true);
      getFullData(+urlId);
      createDocumentStore.setContractorId(urlId);
      createDocumentStore.getContragentContracts();
    }

    return () => {
      createDocumentStore.setContractorId("");
    };
  }, [urlId]);

  useEffect(() => {
    if (searchParams.has(PAYMENT_NUMBER)) {
      setSubPage(1);
    }

    return () => {
      if (searchParams.has(PAYMENT_NUMBER)) {
        let pageType: typeof FINANCE_CARD_OPEN | typeof INTEGRATION_CARD_OPEN | null = null;

        if (searchParams.has(FINANCE_CARD_OPEN)) {
          pageType = FINANCE_CARD_OPEN;
        } else if (searchParams.has(INTEGRATION_CARD_OPEN)) {
          pageType = INTEGRATION_CARD_OPEN;
        }

        if (pageType) {
          createDocumentStore.setPaymentContractAndAttachment(
            searchParams.get(PAYMENT_NUMBER)!,
            pageType
          );
        }
      }
    };
  }, []);

  return (
    <Drawer
      anchor={"right"}
      open={isOpen}
      onClose={closeModal}
      sx={{ position: "relative" }}
      PaperProps={{ sx: { backgroundColor: "#F2F3F4" } }}
      slotProps={{ backdrop: { sx: { left: 70 } } }}>
      <Box
        sx={{
          p: 2,
          pt: 1,
          width: {
            xl: "85vw",
            lg: "calc(100vw - 70px - 100px)",
            md: "calc(100vw - 70px - 50px)",
            sm: "calc(100vw - 70px)",
            xs: "100vw",
          },
        }}>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <CustomIconButton onClick={closeModal} sx={{ ml: -1 }}>
            <KeyboardArrowLeft fontSize="large" />
          </CustomIconButton>
          <Typography variant="h5" noWrap sx={{ maxWidth: "50%" }}>
            Контрагент №{urlId}, {editContrActorStore.editableContractor?.shortName}
          </Typography>
        </Stack>
        {!isError && (
          <Box sx={{ mb: 2 }}>
            {!isLoading ? (
              <AsideTabs
                initValue={subPage}
                setValue={setSubPage as any}
                tabs={contractorCardTabs}
              />
            ) : (
              <Stack direction={"row"} alignItems={"center"} spacing={2} sx={{ mt: 1 }}>
                {contractorCardTabs.map((item) => (
                  <Skeleton key={item.value} variant="rounded" height={24} width={90} />
                ))}
              </Stack>
            )}
          </Box>
        )}
        {isLoading && (
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "70vh",
            }}>
            <CircularProgress sx={{ minHeight: 200, minWidth: 200 }} />
          </Box>
        )}
        {isError && (
          <Box>
            <Typography variant="h4" color={"error"} align="center">
              {isError}
            </Typography>
            <Typography variant="h5" color={"action"} sx={{ mt: 1 }}>
              Страница может больше не существовать или быть перемещена на другой веб адрес.
            </Typography>
            <Button
              fullWidth
              variant="outlined"
              onClick={closeModal}
              sx={{ mt: 2, background: "#fff" }}>
              Вернуться назад
            </Button>
          </Box>
        )}
        {!isLoading && urlId && (
          <Box sx={{ height: "calc(100vh - 134px)" }}>
            {subPage === 0 && <>{!isLoading && data ? <ContrActorPage data={data} /> : null}</>}
            {subPage === 1 && data ? <CreateDocument data={data} /> : null}
            {subPage === 2 && data ? <ContractsPage data={data.contracts} /> : null}
            {subPage === 3 && data && <ChannelsPage data={data.channels} />}
          </Box>
        )}
      </Box>
    </Drawer>
  );
});
