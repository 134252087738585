import { observer } from "mobx-react-lite"
import { ICreateCommentsRequest } from "../../../../services/common/ICommonRequest"
import CommonService from "../../../../services/common/common.service"
import productSamplePageStore from "../../../../store/productSamplePage.store"
import { AsideCardComments } from "../../../TableAsidePage/ui/AsideCardComments/AsideCardComments"
import { AxiosError } from "axios"
import goodsTableStore from "../../../../store/goodsTable.store"
import { IPostedCommentItem } from "../../../../components/PostedCommentItem/models/IPostedCommentItem"

export const GoodFullChat = observer(() => {

  const addComment = (value: string) => {
    if (productSamplePageStore.productSampleId) {

      const option: ICreateCommentsRequest = {
        toEntity: "productSampleInfo",
        entity_id: productSamplePageStore.productSampleId,
        text: value,
      }
      CommonService.createComments(option)
        .then((res) => {
          // console.log(res, "res comment add data")
          productSamplePageStore.addPostedComments(res.data)
          goodsTableStore.changeField(productSamplePageStore.productSampleId!, 'posted_comments', [...productSamplePageStore.comment])
        })
        .catch((err) => {
          if (err instanceof AxiosError) {
            // console.log(err, "err add comment")
          }
        })
    }
  }

  const onEdit = (id: number, comment: IPostedCommentItem) => {
    productSamplePageStore.editComment(id, comment)

    goodsTableStore.changeField(
      productSamplePageStore.productSampleId!, 
      'posted_comments', 
      productSamplePageStore.comment.map(x=>{
        if(x.id === id){
          return comment
        } else return x
      })
    )
  }

  const onDelete = (id: number) => {
    productSamplePageStore.deletePostedComments(id)

    goodsTableStore.changeField(
      productSamplePageStore.productSampleId!, 
      'posted_comments', 
      productSamplePageStore.comment.filter(x=>x.id !== id)
    )
  }

  return (
    <>
      {productSamplePageStore.productSampleId &&
        <AsideCardComments
          comments={productSamplePageStore.comment || []}
          addComment={addComment} 
          sx={{maxHeight:{xs:'300px', lg:'720px'}}}
          entity='productSampleInfo'
          onChangeComments={()=>''}
          onEditComments={onEdit}
          onDeleteComments={onDelete}
          entityId={productSamplePageStore.productSampleId}
          onUpdateCommentsArray={goodsTableStore.onUpdateCommentsArray}
        />
      }
    </>
  )
})