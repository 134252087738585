import { OpenInNew } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { SocialType } from "../../../../../../../../../../../../components/Table/ui/Fields/SocialType/SocialType";
import { CustomIconButton } from "../../../../../../../../../../../../components/UI/Buttons/CustomIconButton/CustomIconButton";
import {
  CONTRACTOR_CARD_OPEN,
  FINANCE_CARD_OPEN,
  PAYMENT_NUMBER,
} from "../../../../../../../../../../../../shared/constants/searchParams";
import createDocumentStore from "../../../../../../../../../../../../store/createDocument.store";
import ContractDeleteBtn from "../ContractDeleteBtn/ContractDeleteBtn";
import { IContractoPaymentProps } from "./IContractPayment";

export const ContractPayment: FC<IContractoPaymentProps> = (props) => {
  const { payment, documentType, showDeleteBtn } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const hanldeOpenPaymentCard = () => {
    if (searchParams.has(FINANCE_CARD_OPEN)) {
      searchParams.delete(CONTRACTOR_CARD_OPEN);
      searchParams.delete(PAYMENT_NUMBER);
    }

    searchParams.set(FINANCE_CARD_OPEN, payment.id.toString());

    setSearchParams(searchParams);
  };

  const handleConfirmDelete = () => {
    return createDocumentStore.deletePayment(payment?.id, documentType);
  };

  return (
    <Stack direction={"row"} alignItems={"center"} maxWidth={"390px"}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        maxWidth={"320px"}
        width={"100%"}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          columnGap={"2px"}
          flexWrap={"wrap"}
          maxWidth={"300px"}
          width={"100%"}>
          <Typography sx={{ textWrap: "nowrap" }}>Оплата {payment.id}</Typography>
          {payment.integrations?.length !== 0 && payment.integrations && (
            <SocialType code={payment.integrations[0].channel?.type.code} />
          )}
          <Typography sx={{ textWrap: "wrap" }}>
            {payment.integrations?.length !== 0 &&
              payment.integrations &&
              payment.integrations[0].channel?.name}
          </Typography>
          {payment.integrations?.map((integration, index) => (
            <Typography key={integration.id} sx={{ textWrap: "nowrap" }}>
              {index < payment.integrations.length - 1 ? `${integration.id},` : integration.id}
            </Typography>
          ))}
        </Stack>
        <CustomIconButton onClick={hanldeOpenPaymentCard}>
          <OpenInNew />
        </CustomIconButton>
      </Stack>
      <Box>
        {showDeleteBtn && (
          <ContractDeleteBtn
            onDelete={handleConfirmDelete}
            subtext="Вы действительно хотите удалить оплату?"
          />
        )}
      </Box>
    </Stack>
  );
};
