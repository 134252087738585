import {
    IAsideTableStatisticField
} from "../../../../TableAsidePage/ui/AsideTableStatistic/models/IAsideTableStatisticField";
import {IStrategyField} from "../../../../../strategy/interface/IStrategyField";
import {IPayments} from "../../../../../services/contractor/IContractorIResponse";
import dayjs from "dayjs";

export const getFieldContract = (data:IPayments):IAsideTableStatisticField[] => {
    const value =  data.contractBlock && data.contractBlock?.attachment
        ?
        `Приложение №${data.contractBlock?.attachment.id} от ${dayjs(data.contractBlock?.attachment.date).format('DD.MM.YYYY')}`
        :
        '—'
    const fields:IAsideTableStatisticField[] = [
        {code:'payment_id', value:data.id,},
        {code:'payment_statusCode', value:data.status,},
        {code:'payment_summ', value:`${data.totalSumm || 0} руб` },
        {code:'attachment', value:value},
    ]

    return fields
}
export const getContract = ():IStrategyField[] => {
    const statisticsRow:IStrategyField[] = [
        {title:'ID оплаты', code:'payment_id', immutable:false, isSimple:false, isSimpleView:true, maxWidth: '25px'},
        {title:'Статус оплаты', code:'payment_statusCode', isSimple:false, immutable:false, paymentStatusReadOnly:true, maxWidth: '30px', minWidth: '30px'},
        {title:'Общая сумма', code:'payment_summ', immutable:false, isSimple:true, maxWidth: '35px'},
        {title:'Приложение', code:'attachment', immutable:false, isSimple:true, maxWidth: '200px', minWidth: '200px'},
    ]

    return statisticsRow
}