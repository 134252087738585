import { TextField, Typography } from "@mui/material";
import { FC } from "react";
import { ContrActorFieldContainer } from "../ContrActorFieldContainer/ContrActorFieldContainer";
import { IContrActorCreateField } from "./IContrActorCreateField";
import { getNestedValue } from "./models/getNestedValue";

export const ContrActorCreateField: FC<IContrActorCreateField> = (props) => {
  const { required, title, form, field, placeholder, maxWidth = "300px", ...restProps } = props;

  const errorText = getNestedValue(form.errors, field.name);
  const touched = getNestedValue(form.touched, field.name);

  return (
    <ContrActorFieldContainer title={title} required={required}>
      <TextField
        size="small"
        {...field}
        required={required}
        {...restProps}
        error={!!errorText && touched}
        sx={{
          position: "relative",
          "& .MuiFormHelperText-root": {
            margin: 0,
            padding: 0,
          },
          maxWidth: { maxWidth },
          width: "100%",
        }}
        placeholder={placeholder}
        helperText={
          errorText &&
          touched && (
            <Typography
              variant="caption"
              color="error"
              component={"span"}
              sx={{
                position: "absolute",
                bottom: "-16px",
                left: "0",
                margin: "0",
              }}>
              {errorText}
            </Typography>
          )
        }
      />
    </ContrActorFieldContainer>
  );
};
