import { Check, Clear } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import { Avatar, Box, IconButton, Link, Stack, Tooltip, Typography } from "@mui/material";
import { Field, Form, Formik, FormikErrors, FormikValues } from "formik";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { AsideCardLayout } from "../../../../../../components/AsideCardLayout/AsideCardLayout";
import { ContrActorCreateField } from "../../../../../../components/ContrActorTable/ui/ContrActorCreateModals/ui/ContrActorCreateField/ContrActorCreateField";
import { EmptyText } from "../../../../../../components/EmptyText/EmptyText";
import { CopyIconButton } from "../../../../../../components/UI/Buttons/CopyIconButton/CopyIconButton";
import { CustomIconButton } from "../../../../../../components/UI/Buttons/CustomIconButton/CustomIconButton";
import { EditIconButton } from "../../../../../../components/UI/Buttons/EditIconButton/EditIconButton";
import editContrActorStore from "../../../../../../store/editContrActor.store";
import { contactsSchema } from "./models/contactsSchema";
import { ContrActorCreatePhoneField } from "./ui/PhoneField/PhoneField";

export const ContrActorContacts = observer(() => {
  const [submitForm, setSubmitForm] = useState<() => void>(() => {});
  const [isEditContact, setIsEditContact] = useState(false);
  const [validateForm, setValidateForm] = useState<() => Promise<FormikErrors<FormikValues>>>(
    () => async () => ({})
  );
  const [setFieldTouched, setSetFieldTouched] = useState<
    (field: string, isTouched: boolean) => void
  >(() => {});

  const [isDirty, setIsDirty] = useState<() => boolean>();

  const initialValues = toJS(editContrActorStore.contacts!);

  const handleSubmitForm = async () => {
    if (!isDirty) {
      setIsEditContact(false)
      return
    }
 // костылище
    setFieldTouched("email", true);
    try {
      const errors = await validateForm();
      if (Object.keys(errors).length === 0) {
        await submitForm();
        setIsEditContact(false);
      }
    } catch {
      setIsEditContact(true);
    }
  };

  return (
    <AsideCardLayout
      headerTitle="Контакты"
      rightContent={
        isEditContact ? (
          <Stack direction={"row"} alignItems={"center"}>
            <CustomIconButton size="small" onClick={() => setIsEditContact(false)}>
              <Clear fontSize="small" />
            </CustomIconButton>
            <CustomIconButton
              size="small"
              onClick={handleSubmitForm}
              disabled={editContrActorStore.isLoading}
              showBackground>
              <Check fontSize="small" />
            </CustomIconButton>
          </Stack>
        ) : (
          <Box sx={{ display: "flex" }}>
            <EditIconButton size="small" onClick={() => setIsEditContact(true)} />
          </Box>
        )
      }>
      <Stack mt={2} direction={"column"}>
        <Box display={"flex"} flexDirection={"column"}>
          {editContrActorStore.channels.map((el, index) => {
            return (
              <Stack direction={"row"} alignItems={"center"} key={el.id}>
                <Typography minWidth={"200px"} display={"flex"} flexDirection={"column"}>
                  <Stack direction={"row"} alignItems={"center"}>
                    {index === 0 && <Typography>Прямой контакт из канала</Typography>}
                    {index === 0 && (
                      <Box>
                        <Tooltip
                          placement={"bottom-start"}
                          title={
                            <Box fontSize={"13px"}>
                              Редактировать прямые контакты канала можно только в канале
                            </Box>
                          }>
                          <IconButton sx={{ padding: "0", marginLeft: "4px" }}>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"} spacing={0.5} gap={0.3} pt={1}>
                    <Typography noWrap>{el.name}</Typography>
                    {el.type && (
                      <Avatar
                        src={el.type.icon}
                        alt={el.type.code}
                        sx={{ height: 20, width: 20 }}
                      />
                    )}
                    :
                    <Typography>
                      {el.contact || (
                        <EmptyText component={"span"} whiteSpace={"nowrap"}>
                          данные отсутствуют
                        </EmptyText>
                      )}
                    </Typography>
                  </Stack>
                </Typography>
              </Stack>
            );
          })}
          {!isEditContact ? (
            <Stack direction={"column"} rowGap={1} pt={1}>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography minWidth={"200px"} noWrap>
                  Контакт по документам:
                </Typography>
                {(editContrActorStore.contacts?.documentContact && (
                  <Link
                    to={editContrActorStore.contacts?.documentContact}
                    color={"secondary"}
                    component={RouterLink}
                    target="_blank"
                    sx={{ display: "inline-block", maxWidth: "198px" }}
                    noWrap>
                    {editContrActorStore.contacts?.documentContact}
                  </Link>
                )) || <EmptyText component={"span"}>данные отсутствуют</EmptyText>}
                {editContrActorStore.contacts?.documentContact && (
                  <CopyIconButton copyText={editContrActorStore.contacts?.documentContact} />
                )}
              </Stack>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography minWidth={"200px"}>Электронная почта:</Typography>
                <Typography
                  whiteSpace={"nowrap"}
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  maxWidth={"198px"}>
                  {editContrActorStore.contacts?.email || (
                    <EmptyText component={"span"}>данные отсутствуют</EmptyText>
                  )}
                </Typography>
                {editContrActorStore.contacts?.email && (
                  <CopyIconButton copyText={editContrActorStore.contacts?.email} />
                )}
              </Stack>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography minWidth={"200px"}>Телефон:</Typography>
                <Typography>
                  {editContrActorStore.contacts?.phone || (
                    <EmptyText component={"span"}>данные отсутствуют</EmptyText>
                  )}
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography minWidth={"200px"}>Telegram:</Typography>
                <Typography>
                  {editContrActorStore.contacts?.telegram || (
                    <EmptyText component={"span"}>данные отсутствуют</EmptyText>
                  )}
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography minWidth={"200px"}>Доп.контакт:</Typography>
                <Typography>
                  {editContrActorStore.contacts?.additionalContact || (
                    <EmptyText component={"span"}>данные отсутствуют</EmptyText>
                  )}
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <Formik
              initialValues={initialValues}
              validateOnChange
              validateOnBlur
              onSubmit={(values) => editContrActorStore.editContractorContacts(values)}
              validationSchema={contactsSchema}
              innerRef={(form) => {
                if (form) {
                  setSubmitForm(() => form.submitForm);
                  setValidateForm(() => form.validateForm);
                  setSetFieldTouched(() => form.setFieldTouched);
                  setIsDirty(() => form.dirty)
                }
              }}>
              {({ setFieldTouched }) => (
                <Form>
                  <Stack direction={"column"} rowGap={2} pt={2}>
                    <Field
                      component={ContrActorCreateField}
                      name="documentContact"
                      title="Контакт по документам"
                    />
                    <Field
                      component={ContrActorCreateField}
                      name="email"
                      title="Электронная почта"
                      onFocus={(e: React.ChangeEvent<any>) => setFieldTouched(e.target.name, true)}
                    />
                    <Field
                      component={ContrActorCreatePhoneField}
                      name="phone"
                      title="Телефон"
                      mask={"999-999-9999-9"}
                    />
                    <Field component={ContrActorCreateField} name="telegram" title="Телеграм" />
                    <Field
                      component={ContrActorCreateField}
                      name="additionalContact"
                      title="Доп. контакт"
                    />
                  </Stack>
                </Form>
              )}
            </Formik>
          )}
        </Box>
      </Stack>
    </AsideCardLayout>
  );
});
