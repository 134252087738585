import { TextField, Typography } from "@mui/material";
import { FC, forwardRef } from "react";
import InputMask from "react-input-mask";
import { ContrActorFieldContainer } from "../../../../../../../../components/ContrActorTable/ui/ContrActorCreateModals/ui/ContrActorFieldContainer/ContrActorFieldContainer";
import { getNestedValue } from "../../../../../../../../components/ContrActorTable/ui/ContrActorCreateModals/ui/ContrActorCreateField/models/getNestedValue";
import { IContrActorCreateField } from "../../../../../../../../components/ContrActorTable/ui/ContrActorCreateModals/ui/ContrActorCreateField/IContrActorCreateField";

const ForwardedInputMask = forwardRef<HTMLInputElement, any>((props, ref) => (
  <InputMask {...props} inputRef={ref} />
));

export const ContrActorCreatePhoneField: FC<IContrActorCreateField> = (props) => {
  const { required, title, form, field, placeholder, maxWidth = "300px", ...restProps } = props;

  const errorText = getNestedValue(form.errors, field.name);
  const touched = getNestedValue(form.touched, field.name);

  return (
    <ContrActorFieldContainer title={title} required={required}>
      <TextField
        {...restProps}
        {...field}
        size="small"
        required={required}
        error={!!errorText && touched}
        sx={{
          position: "relative",
          "& .MuiFormHelperText-root": {
            margin: 0,
            padding: 0,
          },
          maxWidth: { maxWidth },
          width: "100%",
        }}
        InputProps={{
          inputComponent: ForwardedInputMask,
          inputProps: {
            mask: "+7 (999) 999-99-99",
            value: field.value,
            onChange: field.onChange,
            onBlur: field.onBlur,
          },
        }}
        placeholder={placeholder || "+7 (___) ___-__-__"}
        helperText={
          errorText &&
          touched && (
            <Typography
              variant="caption"
              color="error"
              component={"span"}
              sx={{
                position: "absolute",
                bottom: "-14px",
                left: "14px",
                margin: "0",
              }}>
              {errorText}
            </Typography>
          )
        }
      />
    </ContrActorFieldContainer>
  );
};
