import { FC, useEffect, useState } from 'react'
import { IGoodFullPage } from './IGoodFullPage'
import ProductSample from '../../services/productSample/productSample.service'
import { Avatar, Box, Button, CircularProgress, Drawer, Link, Stack, Typography } from '@mui/material'
import { ArrowOutward, Autorenew, Check, KeyboardArrowLeft } from '@mui/icons-material'
import { AxiosError } from 'axios'
import productSamplePageStore from '../../store/productSamplePage.store'
import { GoodSampleBlock } from '../GoodsCreatePage/ui/GoodSampleBlock/GoodSampleBlock'
import { observer } from 'mobx-react-lite'
import { GoodFullChat } from './ui/GoodFullChat/GoodFullChat'
import { IProductSampleInsertOrUpdate } from '../../services/productSample/IProductSampleRequest'
import goodsTableStore from '../../store/goodsTable.store'
import channelStore from '../../store/channel.store'
import { NotificationAlerts } from '../../components/NotificationAlerts/NotificationAlerts'
import { CHANNEL_CARD_OPEN, INTEGRATION_CARD_OPEN } from '../../shared/constants/searchParams'
import { useSearchParams } from 'react-router-dom'
import { CustomIconButton } from '../../components/UI/Buttons/CustomIconButton/CustomIconButton'
import integrationPageStore from '../../store/integrationPage.store'

export const GoodFullPage:FC<IGoodFullPage> = observer((props) => {
  const {
    id,
    onClose
  } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const [isOpenPage, setOpenPage] = useState(false)

  const [isEditMode, setEditMode] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const [isSubmitLoading, setSubmitLoading] = useState(false)
  const [isSubmitError, setSubmitError] = useState<string | null>(null)
  

  const getFullGood = () => {
    setLoading(true)

    ProductSample.getProductSampleFull(id)
      .then(res=>{
        productSamplePageStore.setIntegration(res.data.integration || null)
        productSamplePageStore.setUserId(res.data.userId || null)
        productSamplePageStore.setProductSamples(res.data.productSamples || [])
        productSamplePageStore.setSendAt(res.data.sendAt)
        productSamplePageStore.setSendFrom(res.data.sendFrom?.code || null)
        productSamplePageStore.setCheckbox(res.data.hasRefund)
        productSamplePageStore.setSampleRefund(res.data.isSampleRefund)
        productSamplePageStore.setComment(res.data.posted_comments || [])

        productSamplePageStore.setDeliveryCostValue(res.data.deliveryCost)
        productSamplePageStore.setFioValue(res.data.fio)
        productSamplePageStore.setPhoneValue(res.data.phone)
        productSamplePageStore.setRefundCostValue(res.data.refundCost)
        productSamplePageStore.setSendToValue(res.data.sendTo)
        productSamplePageStore.setTrackNumberCGValue(res.data.trackNumberCG)
        productSamplePageStore.setTrackNumberRefundValue(res.data.trackNumberRefund)
        productSamplePageStore.setTrackNumberTCValue(res.data.trackNumberTC)

        productSamplePageStore.setUpdateId(res.data.update_id)
        productSamplePageStore.setProductSampleId(id)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setError(err?.response?.data?.message)
        }
      })
      .finally(()=>{
        setLoading(false)
      })
  }

  const startEdit = () => {
    setEditMode(true)
    productSamplePageStore.startEdit()
  }

  const closeEdit = () => {
    setEditMode(false)
    productSamplePageStore.closeEdit()
  }

  const submitEdit = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSubmitLoading(true)
    setSubmitError(null)

    const form = e.target as any

    const options:IProductSampleInsertOrUpdate  = {
      id:productSamplePageStore.productSampleId || undefined,
      integration:productSamplePageStore.editIntegration as any || null,
      productSamples:productSamplePageStore.editProductSamples?.map(x=>{
        return {...x, id:x?.id < 0 ? null : x.id} as any
      }) || [],
      deliveryCost:productSamplePageStore.editDeliveryCostValue as any,
      fio:form.fio.value || null,
      hasRefund:Boolean(productSamplePageStore.editHasRefund),
      phone:form.phone.value || null,
      sendAt:productSamplePageStore.editSendAt || undefined,
      sendFrom:productSamplePageStore.editSendFrom || undefined,
      sendTo:form.sendTo.value || undefined,
      trackNumberCG:form.trackNumberCG.value.trim() || null,
      trackNumberTC:form.trackNumberTC.value.trim() || null,
      userId:productSamplePageStore.editUserId || undefined,
      isSampleRefund:Boolean(productSamplePageStore.editIsSampleRefundValue),
      refundCost:productSamplePageStore.editRefundCostValue as any,
      trackNumberRefund:form.trackNumberRefund?.value || null,
      update_id:productSamplePageStore.update_id || undefined
    }

    ProductSample.insertOrUpdate(options)
      .then(res=>{
        productSamplePageStore.setIntegration(res.data.integration || null)
        productSamplePageStore.setUserId(res.data.userId || null)
        productSamplePageStore.setProductSamples(res.data.productSamples || [])
        productSamplePageStore.setSendAt(res.data.sendAt)
        productSamplePageStore.setSendFrom(res.data.sendFrom?.code || null)
        productSamplePageStore.setCheckbox(res.data.hasRefund)
        productSamplePageStore.setSampleRefund(res.data.isSampleRefund)
        productSamplePageStore.setComment(res.data.posted_comments || [])

        productSamplePageStore.setDeliveryCostValue(res.data.deliveryCost)
        productSamplePageStore.setFioValue(res.data.fio)
        productSamplePageStore.setPhoneValue(res.data.phone)
        productSamplePageStore.setRefundCostValue(res.data.refundCost)
        productSamplePageStore.setSendToValue(res.data.sendTo)
        productSamplePageStore.setTrackNumberCGValue(res.data.trackNumberCG)
        productSamplePageStore.setTrackNumberRefundValue(res.data.trackNumberRefund)
        productSamplePageStore.setTrackNumberTCValue(res.data.trackNumberTC)

        productSamplePageStore.setUpdateId(res.data.update_id)
        productSamplePageStore.setProductSampleId(id)

        goodsTableStore.changeItem(id, res.data)

        if (searchParams.has(INTEGRATION_CARD_OPEN)) {
          integrationPageStore.setProductSampleInfo(res.data)
        }
        
        closeEdit()
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setSubmitError(err?.response?.data?.message)
        }
      })
      .finally(()=>{
        setSubmitLoading(false)
      })

  }

  const closeModal = () => {
    setOpenPage(false)
    
    setTimeout(() =>{
      onClose()
    }, 200)
  }

  const channelCardOpen = () => {
    searchParams.set(CHANNEL_CARD_OPEN, `${productSamplePageStore.integration?.channel?.id}`)
    setSearchParams(searchParams)
  }

  const renderIcon = (hasRefund:boolean, isSampleRefund:boolean):React.ReactNode => {
    if(isSampleRefund){
      return <Box sx={{p:0.5, background:'#7ECB41', borderRadius:'5px', display:'inline-flex', alignItems:'center', justifyContent:'center'}}>
                <Check sx={{color:'#fff'}}/>
              </Box>
    } else if (hasRefund) {
      return <Box sx={{p:0.5, background:'#7876FE', borderRadius:'5px', display:'inline-flex', alignItems:'center', justifyContent:'center'}}>
                <Autorenew sx={{color:'#fff'}}/>
              </Box>
    } else return <Box sx={{p:0.5, background:'#7BB8FF', borderRadius:'5px', display:'inline-flex', alignItems:'center', justifyContent:'center'}}>
                    <ArrowOutward sx={{color:'#fff'}}/>
                  </Box>
  }

  useEffect(()=>{
    getFullGood()
    setOpenPage(true)
  }, [id])

  return (
    <>
      <Drawer
        open={isOpenPage}
        onClose={closeModal}
        anchor='right'
        PaperProps={{sx:{backgroundColor: "#F2F3F4"}}}
        slotProps={{backdrop:{sx:{left:70}}}}
        ModalProps={{sx:{left:70}}}
      >
        <Box
          sx={{
            p:2,
            width:{xl:"85vw", lg:'calc(100vw - 70px - 100px)', md:'calc(100vw - 70px - 50px)', sm:'calc(100vw - 70px)', xs:'100vw'},
          }}
        >
          {isLoading &&
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', mt:30}}>
              <CircularProgress sx={{minHeight:200, minWidth:200}}/>
            </Box>
          }
          {isError && 
            <Box>
              <Typography variant='h4' color={'error'} align='center'>{isError}</Typography>
              <Typography variant='h5' color={'action'} align='center' sx={{mt:1}}>
                Страница может больше не существовать или быть перемещена на другой веб адрес.
              </Typography>
              <Button fullWidth variant='outlined' onClick={closeModal} sx={{mt:2}}>Вернуться назад</Button>
            </Box>
          }
          {!isLoading &&
            <>
              <Stack
                sx={{
                  mb:1, 
                  mt:-1,
                }} 
                direction={'row'}
                alignItems={'center'}
                spacing={1}
              >
                <CustomIconButton onClick={closeModal} sx={{ml:-1, mr:-1}}>
                  <KeyboardArrowLeft fontSize="large" />
                </CustomIconButton>
                <Typography variant="h5" noWrap>Образец товара №{id}. Детализация.</Typography>
              </Stack>
              <Box
                sx={{
                  display:'grid',
                  gridTemplateColumns:{xs:'1fr', md:'2fr 1fr'},
                  columnGap:2,
                  rowGap:2,
                  mt:1,
                  width:{xs:'100%', lg:"75%"}
                }}
              >
                <GoodSampleBlock
                  isEdit={isEditMode}
                  showEditButton={true}
                  onCloseEdit={closeEdit}
                  onOpenEdit={startEdit}
                  headerTitle='Товары канала'
                  hasRefundValue={isEditMode ? Boolean(productSamplePageStore.editHasRefund) : Boolean(productSamplePageStore.hasRefund)}
                  onChangeCheckbox={productSamplePageStore.onChangeCheckbox}
                  integration={isEditMode ? productSamplePageStore.editIntegration : productSamplePageStore.integration}
                  onChangeIntegration={productSamplePageStore.onChangeIntegration}
                  productSampleList={isEditMode ? productSamplePageStore.editProductSamples : productSamplePageStore.productSamples}
                  onAddedGood={productSamplePageStore.onAddedGood}
                  onChangeGood={productSamplePageStore.onChangeGood}
                  onDeleteGood={productSamplePageStore.onDeleteGood}
                  sendFrom={isEditMode ? productSamplePageStore.editSendFrom : productSamplePageStore.sendFrom}
                  onChangeCity={productSamplePageStore.onChangeSendFrom}
                  sendAtValue={isEditMode ? productSamplePageStore.editSendAt : productSamplePageStore.sendAt}
                  onChangeDate={productSamplePageStore.onChangeDate}
                  userId={isEditMode ? productSamplePageStore.editUserId : productSamplePageStore.userId}
                  onManagerChange={productSamplePageStore.onManagerChange}
                  onSubmitForm={submitEdit}
                  showRefundContent={isEditMode ? Boolean(productSamplePageStore.editHasRefund) : Boolean(productSamplePageStore.hasRefund)}
                  loading={isSubmitLoading}
                  disabled={isSubmitLoading}
                  showAddedBtn={false}
                  deliveryCostValue={isEditMode ? productSamplePageStore.editDeliveryCostValue : productSamplePageStore.deliveryCostValue}
                  setDeliveryCostValue={productSamplePageStore.onChangeDeliveryCostValue}
                  fioValue={productSamplePageStore.fioValue}
                  phoneValue={productSamplePageStore.phoneValue}
                  refundCostValue={isEditMode ? productSamplePageStore.editRefundCostValue : productSamplePageStore.refundCostValue}
                  setRefundCostValue={productSamplePageStore.onChangeRefundCostValue}
                  sendToValue={productSamplePageStore.sendToValue}
                  trackNumberCGValue={productSamplePageStore.trackNumberCGValue}
                  trackNumberRefundValue={productSamplePageStore.trackNumberRefundValue}
                  trackNumberTCValue={productSamplePageStore.trackNumberTCValue}
                  isSampleRefundValue={isEditMode ? Boolean(productSamplePageStore.editIsSampleRefundValue) : Boolean(productSamplePageStore.isSampleRefundValue)}
                  onChangeSampleRefund={productSamplePageStore.onChangeSampleRefund}
                  headerIcon={
                    isEditMode
                      ? renderIcon(Boolean(productSamplePageStore.editHasRefund), Boolean(productSamplePageStore.editIsSampleRefundValue))
                      : renderIcon(Boolean(productSamplePageStore.hasRefund), Boolean(productSamplePageStore.isSampleRefundValue))
                  }
                  headerContent={
                    <Box sx={{display: 'grid', gridTemplateColumns:' 1fr min-content', alignItems:'center', overflow:'hidden',}}>
                      <Link
                        onClick={channelCardOpen}
                        color={'secondary'}
                        sx={{textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden', cursor:'pointer'}}
                      >
                        {productSamplePageStore.integration?.channel?.name}
                      </Link>
                      {productSamplePageStore?.integration?.channel?.type?.code &&
                        <Avatar
                          src={channelStore?.channels?.[productSamplePageStore?.integration?.channel?.type?.code]?.icon}
                          alt={productSamplePageStore.integration?.channel?.name}
                          sx={{height:20, width:20, ml:0.5}}
                        />
                      }
                    </Box>
                  }
                />
                <GoodFullChat/>
              </Box>
            </>
          }   
        </Box>
      </Drawer>
      <NotificationAlerts
        error={{open:Boolean(isSubmitError), text:isSubmitError || '', onClose:()=>setSubmitError(null)}}
      />
    </>
  )
})