import { makeAutoObservable } from "mobx";
import DocumentType from "../pages/ContrActorAsidePage/pages/CreateDocument/ui/ContractsTable/ui/ContractsList/ui/ContractListItem/shared/documentTypeEnum";
import { ISelectOptions } from "../pages/ContrActorAsidePage/pages/CreateDocument/ui/CreateAttachment/models/ISelectOptions";
import { placementCharacterSamples } from "../pages/ContrActorAsidePage/pages/CreateDocument/ui/CreateAttachment/shared/placementCharacterSamples";
import ContractorService from "../services/contractor/contractor.service";
import {
  IContractorAttachmentRequest,
  IContractorContractRequest,
  IContractorCreateAct,
  IContractorCreateAttachment,
  IContractorCreateContract,
  IContractorCreateNotOurAttachment,
  IContractorCreateNotOurContract,
  IContractorEditContract,
} from "../services/contractor/IContractorIRequest";
import {
  IContragentAct,
  IContragentAttachment,
  IContragentContract,
} from "../services/contractor/IContractorIResponse";
import { downloadWord } from "../shared/functions/functions";
import financeCardPageStore from "./financeCardPage.store";
import { FINANCE_CARD_OPEN, INTEGRATION_CARD_OPEN } from "../shared/constants/searchParams";
import integrationPageStore from "./integrationPage.store";
import PaymentsService from "../services/payments/payments.service";
import dayjs from "dayjs";
import { AxiosError } from "axios";

class CreateDocumentStore {
  isLoading: boolean = false;
  error: boolean = false;
  success: boolean = false;
  errorMessage: string = "";
  successMessage: string = "";
  chatOpen: boolean = false;
  documentBlob: Blob | null = null;
  contractorId: string = "";
  contragentContracts: IContragentContract[] = [];
  contractNumber: string = "";
  contractsOptions: ISelectOptions[] | [] = [];
  attachmentsOptions: ISelectOptions[] | [] = [];
  availablePaymentsForContract: string[] = [];
  ourContract: boolean = true;
  ourAttachment: boolean = true;
  contract: IContractorCreateContract = {
    contractNumber: "",
    contractDate: null,
    city: "",
    ourMarking: true,
    isNDS: false,
  };
  attachment: IContractorCreateAttachment = {
    contractId: 0,
    attachmentNumber: "",
    attachmentDate: null,
    placementCharacter: "",
    channelType: "",
    channelLink: "",
    placementDateUntil: null,
    serviceCost: 0,
    serviceCostInWords: "",
    paymentDate: null,
  };
  act: IContractorCreateAct = {
    attachmentId: 0,
    actNumber: "",
    actDate: null,
    directorTitle: undefined,
    fio: undefined,
  };
  placementCharacterSelect: string = "";
  paymentConract: IContragentContract | null = null;
  paymentAttachment: IContragentAttachment | null = null;
  constructor() {
    makeAutoObservable(this);
  }

  setOurContract = (value: boolean) => {
    this.ourContract = value;
    this.resetContract();
  };

  setOurAttachment = (value: boolean) => {
    this.ourAttachment = value;
    this.getContractsOptions();
    this.resetAttachment();
  };

  setPaymentContractAndAttachment = async (
    paymentId: string,
    pageType: typeof FINANCE_CARD_OPEN | typeof INTEGRATION_CARD_OPEN
  ) => {
    const contract = this.contragentContracts.find((item) =>
      item.payment.some((paymentItem) => paymentItem.id.toString() === paymentId)
    );

    const requestUpdateId = await PaymentsService.getUpdateId(paymentId);
    const updateId = requestUpdateId.data.update_id;

    financeCardPageStore.setUpdateId(updateId);
    integrationPageStore.setPaymentUpdateId(updateId);

    if (!contract) {
      pageType === FINANCE_CARD_OPEN
        ? financeCardPageStore.setContract(null)
        : integrationPageStore.setContract(null);
    } else {
      pageType === FINANCE_CARD_OPEN
        ? financeCardPageStore.setContract(contract)
        : integrationPageStore.setContract(contract);

      const attachment = contract.attachments.find((attachment) =>
        attachment.payment.some((paymentItem) => paymentItem.id.toString() === paymentId)
      );

      if (!attachment) {
        pageType === FINANCE_CARD_OPEN
          ? financeCardPageStore.setAttachment(null)
          : integrationPageStore.setAttachment(null);
      } else {
        pageType === FINANCE_CARD_OPEN
          ? financeCardPageStore.setAttachment(attachment)
          : integrationPageStore.setAttachment(attachment);
      }
    }
  };

  setAvailablePaymentsForContract(value: string[]) {
    this.availablePaymentsForContract = value;
  }

  setContractorId(value: string) {
    this.contractorId = value;
  }

  setPlacementCharacterSelect(value: string) {
    this.placementCharacterSelect = value;
  }

  setContractField<K extends keyof IContractorCreateContract>(
    key: K,
    value: IContractorCreateContract[K]
  ) {
    this.contract[key] = value;
  }

  setAttachmentField<K extends keyof IContractorCreateAttachment>(
    key: K,
    value: IContractorCreateAttachment[K]
  ) {
    this.attachment[key] = value;
  }

  setActField<K extends keyof IContractorCreateAct>(key: K, value: IContractorCreateAct[K]) {
    this.act[key] = value;
  }

  setActDirectorTitle(directorTitle: string | undefined) {
    this.act = { ...this.act, directorTitle: directorTitle };
  }

  setActFio(fio: string | undefined) {
    this.act = { ...this.act, fio: fio };
  }

  setAttachmentPlacementCharacter(value: string) {
    this.attachment.placementCharacter = value;
  }

  setDefaultPlacementCharacter(channelType: string) {
    const getValue = () => {
      const selectedItem = placementCharacterSamples.find((item) => item.type === channelType);
      const defaultItem = placementCharacterSamples.find((item) => item.type === "other");
      return selectedItem ? selectedItem.value : defaultItem?.value ? defaultItem.value : "";
    };

    this.setPlacementCharacterSelect(getValue());
    this.setAttachmentPlacementCharacter(getValue());
  }

  setOurMarking(value: boolean) {
    this.contract.ourMarking = value;
  }

  setContragentContracts(value: IContragentContract[]) {
    this.contragentContracts = value;
  }

  getContragentContracts() {
    this.setIsLoading(true);
    return ContractorService.getContragentContracts(this.contractorId)
      .then((res) => {
        const { contracts, availablePaymentsForContract } = res.data;

        this.setContragentContracts(contracts.reverse());
        this.setAvailablePaymentsForContract(availablePaymentsForContract);
        this.getContractsOptions();
        this.getAttachmentsOptions();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setIsLoading(false);
      });
  }

  createContract() {
    const contract = (): IContractorContractRequest | IContractorCreateNotOurContract => {
      if (this.ourContract) {
        return {
          ...this.contract,
          contractDate: this.contract.contractDate
            ? this.contract.contractDate?.format("YYYY.MM.DD")
            : null,
        };
      } else {
        return {
          contractNumber: this.contract.contractNumber,
          contractDate: this.contract.contractDate
            ? this.contract.contractDate.format("YYYY.MM.DD")
            : null,
          isNDS: this.contract.isNDS,
          link: this.contract.link,
        };
      }
    };

    const generationType = this.ourContract ? "auto" : "manual";

    return ContractorService.createContract(contract(), this.contractorId, generationType)
      .then((res) => {
        this.setSuccess(true);
        this.setSuccessMessage("Договор успешно создан");
        this.resetContract();
        this.addContract(res.data);
        this.setOurContract(true);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorMessage(err.response.data.error);
      });
  }

  createAttachment() {
    const getAttachment = (): IContractorAttachmentRequest | IContractorCreateNotOurAttachment => {
      if (this.ourAttachment) {
        return {
          ...this.attachment,
          attachmentDate: this.attachment.attachmentDate
            ? this.attachment.attachmentDate?.format("YYYY.MM.DD")
            : null,
          placementDateUntil: this.attachment.placementDateUntil
            ? this.attachment.placementDateUntil?.format("YYYY.MM.DD")
            : null,
          paymentDate: this.attachment.paymentDate
            ? this.attachment.paymentDate?.format("YYYY.MM.DD")
            : null,
        };
      } else {
        return {
          contractId: this.attachment.contractId,
          attachmentDate: this.attachment.attachmentDate
            ? this.attachment.attachmentDate?.format("YYYY.MM.DD")
            : null,
          attachmentNumber: this.attachment.attachmentNumber,
          serviceCost: this.attachment.serviceCost,
          serviceCostInWords: this.attachment.serviceCostInWords,
          link: this.attachment.link,
        };
      }
    };

    const currentAttachment = getAttachment();
    const generationType = this.ourAttachment ? "auto" : "manual";

    return ContractorService.createAttachment(currentAttachment, this.contractorId, generationType)
      .then((res) => {
        this.setSuccess(true);
        this.setSuccessMessage("Приложение успешно создано");
        this.resetAttachment();
        this.addAttachment(res.data, currentAttachment.contractId);
        this.setOurAttachment(true);
        this.getAttachmentsOptions();
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          this.setError(true);
          this.setErrorMessage(err?.response?.data.error);
        }
        console.log(err);
      });
  }

  createAct() {
    const act = {
      ...this.act,
      actDate: this.act.actDate ? this.act.actDate?.format("YYYY.MM.DD") : null,
    };

    return ContractorService.createAct(act, this.contractorId)
      .then((res) => {
        this.setSuccess(true);
        this.setSuccessMessage("Акт успешно создан");
        this.resetAct();
        this.addAct(res.data, act.attachmentId);
        this.getAttachmentsOptions();
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          this.setError(true);
          this.setErrorMessage(err?.response?.data.error);
        }

        console.log(err);
      });
  }

  deleteDocument(id: number, documentType: string) {
    return ContractorService.deleteContract(id, documentType)
      .then(() => {
        this.removeDocumentFromTable(id, documentType);
        this.setSuccess(true);
        this.setSuccessMessage("Документ успешно удален");
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorMessage(err.response.data.message);
      });
  }

  getContractsOptions() {
    this.contractsOptions = this.contragentContracts.map((contract) => ({
      value: contract.id.toString(),
      label: `${contract.number} от ${dayjs(contract.date).format("DD.MM.YYYY")} ${
        contract.isNDS ? "с НДС" : "без НДС"
      }`,
    }));
  }

  getAttachmentsOptions() {
    this.attachmentsOptions = this.contragentContracts.flatMap((contract) =>
      contract.attachments
        .filter((attachment) => !attachment.act)
        .map((attachment) => ({
          value: attachment.id.toString(),
          label: `${attachment.number} от ${dayjs(attachment.date).format("DD.MM.YYYY")} ${
            contract.isNDS ? "с НДС" : "без НДС"
          }`,
        }))
    );
  }

  editDocument(options: IContractorEditContract, id: number, documentType: string) {
    return ContractorService.editContract(options, id, documentType)
      .then((res) => {
        const { id, link, summ } = res.data;
        this.setSuccess(true);
        if (options.field === "link") {
          this.updateFieldInDocument(id, options.field, documentType, link);
          this.successMessage = "Ссылка успешно обновлена";
        } else if (options.field === "summ")
          this.updateFieldInDocument(id, options.field, documentType, summ);
        this.successMessage = "Сумма договора изменена";
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorMessage(err.response.data.error);
      });
  }

  downloadDocument(id: number, documentType: DocumentType, name: string) {
    return ContractorService.downloadDocument(id, documentType)
      .then((res) => {
        this.setSuccess(true);
        this.setSuccessMessage("Документ успешно скачан");
        downloadWord(res.data, name);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorMessage("Ошибка при скачивании документа");
      });
  }

  updateFieldInDocument(
    id: number,
    field: keyof IContragentContract,
    documentType: string,
    value: any
  ) {
    if (documentType === DocumentType.ATTACHMENT) {
      this.contragentContracts = this.contragentContracts.map((contract) => {
        const updatedAttachments = contract.attachments.map((attachment) => {
          if (attachment.id === id) {
            return {
              ...attachment,
              [field]: value,
            };
          }

          return attachment;
        });

        return {
          ...contract,
          attachments: updatedAttachments,
        };
      });
    } else if (documentType === DocumentType.CONTRACT) {
      this.contragentContracts = this.contragentContracts.map((contract) => {
        if (contract.id === id) {
          return {
            ...contract,
            [field]: value,
          };
        }

        return contract;
      });
    } else if (documentType === DocumentType.ACT) {
      this.contragentContracts = this.contragentContracts.map((contract) => {
        const updatedAttachments = contract.attachments.map((attachment) => {
          if (attachment.act?.id === id) {
            return {
              ...attachment,
              act: {
                ...attachment.act,
                [field]: value,
              },
            };
          }
          return attachment;
        });

        return {
          ...contract,
          attachments: updatedAttachments,
        };
      });
    }
  }

  addContract(contract: IContragentContract) {
    this.contragentContracts.unshift(contract);
    this.getContractsOptions();
  }

  addAttachment(attachment: IContragentAttachment, contractId: number) {
    const contractIndex = this.contragentContracts.findIndex(
      (contract) => contract.id.toString() === contractId.toString()
    );

    if (contractIndex !== -1) {
      this.contragentContracts[contractIndex].attachments.push(attachment);
    }
  }

  addAct(act: IContragentAct, attachmentId: number) {
    this.contragentContracts.map((contract) => {
      const currentAttachment = contract.attachments.find(
        (attachment) => attachment.id.toString() === attachmentId.toString()
      );

      if (currentAttachment) {
        currentAttachment.act = act;
      }

      return currentAttachment;
    });
  }
  addPayment(id: number, documentType: string, documentId: number) {
    return ContractorService.addPayment(id, documentType, documentId)
      .then((res) => {
        const { payment, availablePaymentsForAttachment, availablePaymentsForContract } = res.data;

        if (documentType === DocumentType.ATTACHMENT) {
          const contract = this.contragentContracts.find((contract) =>
            contract.attachments.some((attachment) => attachment.id === documentId)
          );

          if (contract) {
            const attachment = contract.attachments.find(
              (attachment) => attachment.id === documentId
            );

            if (attachment) {
              attachment.payment = [...attachment.payment, payment];
              contract.availablePaymentsForAttachment = availablePaymentsForAttachment;
            }
          }
        } else if (documentType === DocumentType.CONTRACT) {
          this.setAvailablePaymentsForContract(availablePaymentsForContract);
          const contract = this.contragentContracts.find((contract) => contract.id === documentId);

          if (contract) {
            contract.payment = [...contract?.payment, payment];
            contract.availablePaymentsForAttachment = availablePaymentsForAttachment;
          }
        }
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorMessage(err.message);
      });
  }

  deletePayment(id: number, documentType: string) {
    return ContractorService.deletePayment(id, documentType)
      .then((res) => {
        const { availablePaymentsForAttachment, availablePaymentsForContract } = res.data;

        if (documentType === DocumentType.CONTRACT) {
          const contract = this.contragentContracts.find((contract) =>
            contract.payment.some((payment) => payment.id === id)
          );

          if (contract) {
            contract.payment = contract.payment.filter((payment) => payment.id !== id);
            contract.availablePaymentsForAttachment = availablePaymentsForAttachment;
            this.setAvailablePaymentsForContract(availablePaymentsForContract);
          }
        } else if (documentType === DocumentType.ATTACHMENT) {
          const contract = this.contragentContracts.find((contract) =>
            contract.attachments.some((attachment) =>
              attachment.payment.some((payment) => payment.id === id)
            )
          );

          if (contract) {
            const attachment = contract.attachments.find((attachment) =>
              attachment.payment.some((payment) => payment.id === id)
            );

            contract.availablePaymentsForAttachment = availablePaymentsForAttachment;

            if (attachment) {
              attachment.payment = attachment.payment.filter((payment) => payment.id !== id);
            }
          }
        }
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorMessage(err.response.data.message);
      });
  }

  removeDocumentFromTable(id: number, documentType: string) {
    if (documentType === DocumentType.ATTACHMENT) {
      this.contragentContracts = this.contragentContracts.map((contract) => {
        const updatedAttachments = contract.attachments.filter(
          (attachment) => attachment.id !== id
        );
        return {
          ...contract,
          attachments: updatedAttachments,
        };
      });
      this.getAttachmentsOptions();
    } else if (documentType === DocumentType.CONTRACT) {
      this.contragentContracts = this.contragentContracts.filter((contract) => {
        return contract.id !== id;
      });

      this.getContractsOptions();
    } else if (documentType === DocumentType.ACT) {
      this.contragentContracts = this.contragentContracts.map((contract) => {
        const updatedAttachments = contract.attachments.map((attachment) => {
          if (attachment.act?.id === id) {
            return { ...attachment, act: null };
          }
          return attachment;
        });
        return {
          ...contract,
          attachments: updatedAttachments,
        };
      });
    }
  }

  showDocument(id: number, documentType: string) {
    return ContractorService.downloadDocument(id, documentType)
      .then((res) => {
        this.setBlob(res.data);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorMessage("Ошибка при просмотре договора");
      });
  }

  resetContract() {
    this.contract = {
      contractNumber: "",
      contractDate: null,
      city: "",
      ourMarking: true,
      isNDS: false,
    };
  }

  resetAttachment() {
    this.attachment = {
      contractId: 0,
      attachmentNumber: "",
      attachmentDate: null,
      placementCharacter: "",
      channelType: "",
      channelLink: "",
      placementDateUntil: null,
      serviceCost: 0,
      serviceCostInWords: "",
      paymentDate: null,
    };
    this.setPlacementCharacterSelect("");
  }

  resetAct() {
    this.act = {
      attachmentId: 0,
      actNumber: "",
      actDate: null,
      directorTitle: undefined,
      fio: undefined,
    };
  }

  setChatOpen(value: boolean) {
    this.chatOpen = value;
  }

  setBlob(blob: Blob) {
    this.documentBlob = blob;
  }

  resetBlob() {
    this.documentBlob = null;
  }

  setError(value: boolean) {
    this.error = value;
  }

  setSuccess(value: boolean) {
    this.success = value;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setSuccessMessage(value: string) {
    this.successMessage = value;
  }

  setErrorMessage(value: string) {
    this.errorMessage = value;
  }
}

export default new CreateDocumentStore();
