import { AxiosProgressEvent, AxiosResponse } from "axios";
import $gateway_api from "../../shared/api/gatewayAxios";
import {
  IContractorAttachmentRequest,
  IContractorContractRequest,
  IContrActorCreate,
  IContractorCreateActRequest,
  IContractorCreateNotOurAttachment,
  IContractorCreateNotOurContract,
  IContrActorEdit,
  IContrActorEditContacts,
  IContractorEditContract,
  IContractorGetAllRequest,
} from "./IContractorIRequest";
import {
  IAgent,
  IContractorCreateResponse,
  IContractorEditContractResponse,
  IContrActorFullResponse,
  IContractorGetAllPesponse,
  IContragentAct,
  IContragentAttachment,
  IContragentContract,
  IContragentContractsResponse,
  IDeletePaymentResponse,
  IPaymentResponse,
} from "./IContractorIResponse";

export default class ContractorService {
  static all(option: IContractorGetAllRequest): Promise<AxiosResponse<IContractorGetAllPesponse>> {
    return $gateway_api.post("/api/counteragents/all", option);
  }

  static create(option: IContrActorCreate): Promise<AxiosResponse<IContractorCreateResponse>> {
    return $gateway_api.post("/api/counteragents/create", option);
  }

  static getFull(id: number): Promise<AxiosResponse<IContrActorFullResponse>> {
    return $gateway_api.get(`/api/counteragents/full/${id}`);
  }

  static edit(option: IContrActorEdit): Promise<AxiosResponse<IAgent>> {
    return $gateway_api.post("/api/counteragents/edit", option);
  }

  static editContacts(option: IContrActorEditContacts): Promise<AxiosResponse<IAgent>> {
    return $gateway_api.post("/api/counteragents/edit-contacts", option);
  }

  static counteragentsAllExcel(
    option: IContractorGetAllRequest,
    onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
  ): Promise<AxiosResponse<Blob>> {
    return $gateway_api.post("/api/counteragents/all/excel", option, {
      responseType: "blob",
      onDownloadProgress,
    });
  }

  static createContract(
    options: IContractorContractRequest | IContractorCreateNotOurContract,
    id: string,
    generationType: "auto" | "manual"
  ): Promise<AxiosResponse<IContragentContract>> {
    return $gateway_api.post(
      `/api/counteragents/generate/${id}/contract/${generationType}`,
      options
    );
  }

  static createAttachment(
    options: IContractorAttachmentRequest | IContractorCreateNotOurAttachment,
    id: string,
    generationType: "auto" | "manual"
  ): Promise<AxiosResponse<IContragentAttachment>> {
    return $gateway_api.post(
      `/api/counteragents/generate/${id}/attachment/${generationType}`,
      options
    );
  }

  static createAct(options: IContractorCreateActRequest, id: string): Promise<AxiosResponse<IContragentAct>> {
    return $gateway_api.post(`/api/counteragents/generate/${id}/act`, options);
  }

  static getContragentContracts(id: string): Promise<AxiosResponse<IContragentContractsResponse>> {
    return $gateway_api.get(`/api/counteragents/generate/${id}`);
  }

  static deleteContract(id: number, documentType: string): Promise<AxiosResponse> {
    return $gateway_api.get(`/api/counteragents/generate/delete/${documentType}/${id}`);
  }

  static editContract(
    options: IContractorEditContract,
    id: number,
    documentType: string
  ): Promise<AxiosResponse<IContractorEditContractResponse>> {
    return $gateway_api.post(
      `/api/counteragents/generate/edit-document/${documentType}/${id}`,
      options
    );
  }

  static addPayment(
    id: number,
    documentType: string,
    documentId: number
  ): Promise<AxiosResponse<IPaymentResponse>> {
    return $gateway_api.post(`/api/payments/${id}/${documentType}/add/${documentId}`);
  }

  static deletePayment(
    id: number,
    documentType: string
  ): Promise<AxiosResponse<IDeletePaymentResponse>> {
    return $gateway_api.post(`/api/payments/${id}/${documentType}/delete`);
  }

  static downloadDocument(id: number, documentType: string): Promise<AxiosResponse<Blob>> {
    return $gateway_api.get(`/api/counteragents/generate/download/${documentType}/${id}`, {
      responseType: "blob",
    });
  }
}
