import { FormControlLabel, FormGroup, Radio, RadioGroup, Typography } from "@mui/material";
import { FC } from "react";
import { purple_color } from "../../../../../../app/styles/variables";
import { ContrActorFieldContainer } from "../ContrActorFieldContainer/ContrActorFieldContainer";
import { IContrActorCreateRadioField } from "./IContrActorCreateRadioField";

export const ContrActorCreateRadioField: FC<IContrActorCreateRadioField> = (props) => {
  const { title, required, minWidth = "300px", onChange, name, ...restProps } = props;

  return (
    <ContrActorFieldContainer title={title} required={required}>
      <FormGroup sx={{ minWidth: { minWidth } }}>
        <RadioGroup defaultValue={true} {...restProps} onChange={onChange} row>
          <FormControlLabel
            name={"edo"}
            value={true}
            control={<Radio />}
            label={<Typography sx={{ "&:hover": { color: purple_color } }}>Есть</Typography>}
          />
          <FormControlLabel
            name={"edo"}
            value={false}
            control={<Radio />}
            label={<Typography sx={{ "&:hover": { color: purple_color } }}>Нет</Typography>}
          />
        </RadioGroup>
      </FormGroup>
    </ContrActorFieldContainer>
  );
};
