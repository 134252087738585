import { useState, useEffect } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Divider, Stack, Typography,} from "@mui/material"
import { IIntegrationMinMaxResponse } from "../../../services/integration/IIntegrationsResponse"
import IntegrationsService from "../../../services/integration/integrations.service"
import { AxiosError } from "axios"
import { getFilterIntegrationQuery } from "../shared/dataList"
import { ExpandMore, Save, Settings } from "@mui/icons-material"
import { IIntegrationsRequest, includeInSelect,} from "../../../services/integration/IntegrationsRequest"
import { observer } from "mobx-react-lite"
import userStore from "../../../store/user.store"
import integrationTableStore from "../../../store/integrationTable.store"
import { useSearchParams } from "react-router-dom"
import { LS_FILTERS_ALL_INTEGRATION, LS_FILTERS_INTEGRATION, LS_TAKE_REQUEST_INTEGRATION } from "../../../shared/constants/localstorage"
import { IntegrationPresetList } from "./IntegrationPresetList"
import { SaveModalFilter } from "../../SaveModalFilter/SaveModalFilter"
import presetFiltersStore from "../../../store/presetFilters.store"
import { IReferencesPresetResponse } from "../../../services/references/IReferencesResponse"
import { CustomIconButton } from "../../UI/Buttons/CustomIconButton/CustomIconButton"
import { CustomButton } from "../../UI/Buttons/CustomButton/CustomButton"
import { CancelButton } from "../../UI/Buttons/CancelButton/CancelButton"
import { ModalFilter } from "../../Filters/components/ModalFilter/ModalFilter"
import { IRenderFilterLists } from "../../Filters/interfaces/IRenderFilterItem"
import { RenderFiltersList } from "../../Filters/components/RenderFiltersList/RenderFiltersItem"
import { IStrategyFiltersField } from "../../../strategy/Filters/interface/IStrategyFiltersField"

export const IntergationFilterTableSettings = observer(() => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [isOpenModalFilter, setOpenModalFilter] = useState(false)

  const [isSavePresetOpen, setSavePresetOpen] = useState(false)
  const [isFiltersOpen, setFilterOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [dataMinMax, setDataMinMax] = useState<IIntegrationMinMaxResponse | null>(null)

  const getFiltersTableItems = (isClear: boolean) => {
    integrationTableStore.setLoading(true)

    const params = new URLSearchParams(searchParams)

    if(!isClear){
      const currentFiltersArr:IStrategyFiltersField[] = integrationTableStore.integrationFilters

      const allSearchParamsUrl:string[] = []

      for (let value of searchParams.keys() as any) {
        allSearchParamsUrl.push(value)
      }

      const allCodeFilters:string[] = currentFiltersArr.map(x=>x.searchUrl)

      const diff:{isActive:boolean, searchUrl:string}[] = allSearchParamsUrl
        .map(x=>allCodeFilters.includes(x) ? {isActive:true, searchUrl:x} : {isActive:false, searchUrl:x})
        .filter(x=>x.isActive === false)


      diff.map(x=>params.delete(x.searchUrl))
      setSearchParams(params)
    }

    const take = localStorage.getItem(LS_TAKE_REQUEST_INTEGRATION) ? localStorage.getItem(LS_TAKE_REQUEST_INTEGRATION) : 100

    const option: IIntegrationsRequest = {
      company: userStore.currentCompany,
      filterQuery: isClear ? {} : getFilterIntegrationQuery(params),
      includeInSelect: integrationTableStore.allFilter as includeInSelect[],
      page: 1,
      take: +`${take}`,
    }

    IntegrationsService.getIntegrations(option)
      .then((res) => {
        // console.log(res, "res confirm filters")
        integrationTableStore.setPage(1)
        
        integrationTableStore.setTableItems(res.data.items)
        integrationTableStore.setTotalCount(res.data.totalCount)
        integrationTableStore.setLoading(false)

        setFilterOpen(false)
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, "err filter confirm")
          integrationTableStore.setLoading(false)
        }
      })
  }

  const clearFilter = () => {
    setSearchParams({})
    getFiltersTableItems(true)
    integrationTableStore.setIntegrationPresetFilters([])
    integrationTableStore.setIntegrationPresetAllFilters([])
  }

  const getMinMaxData = () => {
    setLoading(true)

    IntegrationsService.getMinMaxFilter()
      .then((res) => {
        // console.log(res, "res min max filter")
        setDataMinMax(res.data)
        setLoading(false)
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, "err filter")
          setLoading(false)
        }
      })
  }

  const openSavePresetModal = () => setSavePresetOpen(true)
  const closeSavePresetModal = () => setSavePresetOpen(false)
  const addPresetStore = (preset:IReferencesPresetResponse) => presetFiltersStore.addPresetIntegrations(preset)

  const saveFilters = (list:IRenderFilterLists[], allFilterLists:IRenderFilterLists[]) => {

    if(integrationTableStore.integrationPresetFilters.length>0){
      
      integrationTableStore.setIntegrationPresetFilters(list)
      integrationTableStore.setIntegrationPresetAllFilters(allFilterLists)

    } else {

      localStorage.setItem(LS_FILTERS_INTEGRATION, JSON.stringify(list))
      integrationTableStore.setIntegrationFilters(list)
  
      localStorage.setItem(LS_FILTERS_ALL_INTEGRATION, JSON.stringify(allFilterLists))
      integrationTableStore.setIntegrationAllFilters(allFilterLists)
    }

  }

  useEffect(() => {
    getMinMaxData()
  }, [])

  return (
    <>
        <Accordion
          elevation={0}
          expanded={isFiltersOpen}
          disableGutters
          TransitionProps={{timeout:0}}
          sx={{mb: 0, border: "none", borderRadius: "16px", "&.MuiAccordion-root":{borderRadius: "16px" }, boxShadow:'0px 0px 8px 0px rgba(34, 60, 80, 0.2)', position:'relative'}}
        >
          <Stack direction={'row'} alignItems={'center'} sx={{pl:{xs:1, sm:2}, pr:2}}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <CustomIconButton sx={{position:'absolute', top:'-15px', left:'-4px'}} onClick={()=>setOpenModalFilter(true)}>
                <Settings fontSize="medium"/>
              </CustomIconButton>
              <Typography variant="h5" sx={{display:{xs:"none", sm:'inline-block'}}}>Интеграции</Typography>
              <AccordionSummary
                expandIcon={<CustomIconButton><ExpandMore fontSize="medium" /></CustomIconButton>}
                onClick={() => setFilterOpen((prev) => !prev)}
                sx={{pr:1, pl:1, width:'180px', "&:hover":{color:'#7C64D9'}}}
              >
                <Typography sx={{}}>
                  {isFiltersOpen ? "Свернуть фильтр" : "Развернуть фильтр"}
                </Typography>
              </AccordionSummary>
            </Stack>
          </Stack>
          <Divider />
          <AccordionDetails sx={{ p: 1, pb: 2 }}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns:{xl:"repeat(5, 1fr)", lg:'repeat(4,1fr)', md:'repeat(3,1fr)', sm:'repeat(2,1fr)', xs:'repeat(1,1fr)'},
                rowGap:1, 
                columnGap:2,
                pl:1,
                pr:1,
                overflow:'auto',
                maxHeight:{xs:'300px', md:'450px'},
                pb:1
              }}
            >
              <RenderFiltersList
                filters={
                  integrationTableStore.integrationPresetFilters?.length===0
                    ? localStorage.getItem(LS_FILTERS_INTEGRATION)
                      ? integrationTableStore.integrationFilters
                      : integrationTableStore.integrationFilters.filter(x=>x.isSelected)
                    : integrationTableStore.integrationPresetFilters
                }
                dataMinMax={dataMinMax}
                minMaxLoading={isLoading}
              />
            </Box>
            <Divider sx={{margin:'0 -8px'}}/>
            <Box
              sx={{
                mt:2,
                display:"grid",
                gridTemplateColumns:{xs:'1fr', sm:"min-content 1fr min-content"},
                columnGap:1,
                rowGap:{xs:1, sm:0},
                alignItems:"start",
                overflow:'auto',
                maxHeight:82,
                pr:1
              }}
            >
              <Stack direction={{xs:'column', md:"row"}} spacing={1}>
                <CustomButton
                  variant="contained"
                  onClick={() => getFiltersTableItems(false)}
                  disabled={integrationTableStore.isLoading}
                  endIcon={
                    integrationTableStore.isLoading && (
                      <CircularProgress sx={{ maxHeight: 20, maxWidth: 20 }} />
                    )
                  }
                >
                  Применить
                </CustomButton>
                <CancelButton
                  variant="outlined"
                  disabled={integrationTableStore.integrationPresetFilters.length>0 ? false : searchParams.toString() === ""}
                  onClick={clearFilter}
                >
                  Сбросить
                </CancelButton>
              </Stack>
              <IntegrationPresetList onFilterClose={()=>setFilterOpen(false)}/>
              <CustomButton
                variant="outlined"
                endIcon={<Save />}
                disabled={searchParams.toString() === ""}
                onClick={openSavePresetModal}
                sx={{gridRow:{xs:2, sm:'auto'}}}
              >
                Сохранить
              </CustomButton>
            </Box>
          </AccordionDetails>
        </Accordion>
      {isSavePresetOpen && (
        <SaveModalFilter
          open={isSavePresetOpen}
          onClose={closeSavePresetModal}
          addPresetStore={addPresetStore}
          entity="integration"
        />
      )}
      {isOpenModalFilter &&
        <ModalFilter
          list={
            integrationTableStore.integrationPresetFilters.length>0
              ? integrationTableStore.integrationAllPresetFilters 
              : integrationTableStore.integrationAllFilters
          }
          open={isOpenModalFilter}
          onClose={()=>setOpenModalFilter(false)}
          onSubmit={saveFilters}
        />
      }
    </>
  )
})