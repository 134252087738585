import {FC, useCallback, useState} from 'react';
import {IChannelsTable} from "./IChannelsTable";
import {TableItem} from "../../../../../../components/Table/ui/TableItem";
import {
    IAsideTableStatisticField
} from "../../../../../TableAsidePage/ui/AsideTableStatistic/models/IAsideTableStatisticField";
import {Box, Collapse, List, TableCell, TableRow} from "@mui/material";
import IntegrationContrTable from "../IntegrationContrTable/IntegrationContrTable";
import {IBurgerItem} from "../../../../../../components/Table/models/ITableItem";

const ChannelsTable: FC<IChannelsTable> = (props) => {
    const {
        rows,
        dataField,
        dataCont,
        dataId
    } = props

    const [open, setOpen] = useState(false)

    const createItem = (fields:IAsideTableStatisticField[]):Object => {
        const item:any = {}

        for(let property of fields) {
            item[property.code] = property.value
        }

        return item
    }
    const newProps = {
        dataId,
        ...createItem(dataField),
    }
    const [burgerItemLists] = useState<IBurgerItem[]>([
        {title:'Посмотреть', onClick:()=>{setOpen(true)}},
    ])

    const onKeyDown = useCallback((e:any) => {
        if(e.altKey){

        } else {
            setOpen(prev=>!prev)
        }

    },[])
    return (
        <Box sx={{display:'contents'}}>
            <TableItem
                cells={rows}
                onKeyDown={onKeyDown}
                showBurgerMenu
                burgerItemLists={burgerItemLists}
                isAccordion={true}
                {...newProps}
            />
            <TableRow sx={{p:0, border:'none'}}>
                <TableCell sx={{p: 0, transition:'all ease .3s', borderBottom:!open ? 'none' : '1px solid rgba(224, 224, 224, 1)'}} colSpan={3}>
                    <Collapse in={open} timeout="auto" unmountOnExit sx={{p:0}}>
                        <List sx={{p:1}}>
                            <IntegrationContrTable
                                data={dataCont}
                            />
                        </List>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Box>
    );
};

export default ChannelsTable;