export const placementCharacterSamples = [
  {
    type: "youtube",
    label: "Ютуб. Шаблон 1",
    value: `Создание и размещение 1 (одной) рекламной интеграции на YouTube канале "НАЗВАНИЕ КАНАЛА" с указанием промокода "ПРОМОКОД" и условием размещения: первым слотом/вторым слотом и тд., с закреплением описания первой/второй и тд строкой под видео, с автором в кадре/без автора в кадре.
Исполнитель гарантирует 100 000 (Сто тысяч) просмотров в течении 30 (Тридцати) дней с момента размещения рекламной интеграции, при невыполнении данного условия Исполнитель обязуется повторно и безвозмездно разместить интеграцию на YouTube канале "НАЗВАНИЕ КАНАЛА" в следующем ролике/в течении 30 (тридцати) дней/без выбора.`,
  },
  {
    type: "telegram",
    label: "Telegram. Шаблон 1",
    value: `Размещение 1 (Одного)/2 (двух) и тд. рекламного поста/ов в Telegram канале "НАЗВАНИЕ КАНАЛА" с указанием промокода/ов: «ПРОМОКОД» и условием размещения: 1 (один) час в топе/и тд., удаление с канала через 30 (тридцать) дней с момента размещения/без удаления с ленты канала.`,
  },
  {
    type: "other",
    label: "Шаблон универсальный",
    value: `Создание и размещение 1 (одной) рекламной интеграции на "НАЗВАНИЕ ПЛОЩАДКИ" канале "НАЗВАНИЕ КАНАЛА" с указанием промокода "ПРОМОКОД" и условием размещения: первым слотом/вторым слотом и тд., с закреплением описания первой/второй и тд строкой под видео, с автором в кадре/без автора в кадре.
Исполнитель гарантирует 100 000 (Сто тысяч) просмотров в течении 30 (Тридцати) дней с момента размещения рекламной интеграции, при невыполнении данного условия Исполнитель обязуется повторно и безвозмездно разместить интеграцию на YouTube канале "НАЗВАНИЕ КАНАЛА" в следующем ролике/в течении 30 (тридцати) дней/без выбора.`
  }
];
