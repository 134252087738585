import { Box, Stack } from "@mui/material";
import { FC, useMemo } from "react";
import { EmptyText } from "../../../../../../../../../../../../components/EmptyText/EmptyText";
import { EditModeButton } from "../../../../../../../../../../../../components/UI/Buttons/EditModeButton/EditModeButton";
import CreateDocumentSelect from "../../../../../../../CreateDocumentSelect/CreateDocumentSelect";
import { IAddPayment } from "./IAddPayment";

export const AddPayment: FC<IAddPayment> = (props) => {
  const {
    options,
    handleSelectChange,
    submitPayment,
    handlePaymentSelectOpen,
    isPaymentSelectOpen,
  } = props;

  const mappedOptions = useMemo(() => {
    return options.map((item) => ({
      label: `Оплата №${item}`,
      value: item,
    }));
  }, [options]);

  return (
    <Stack direction={"row"} spacing={1} alignItems={"center"} height={'37px'}>
      {isPaymentSelectOpen ? (
        <CreateDocumentSelect
          name="payment"
          options={mappedOptions}
          onChange={handleSelectChange}
          minWidth="190px"
        />
      ) : (
        <EmptyText>Выберите оплату</EmptyText>
      )}
      <Box>
        <EditModeButton
          onClose={handlePaymentSelectOpen}
          isEdit={isPaymentSelectOpen}
          onOpenEdit={handlePaymentSelectOpen}
          onSubmit={submitPayment}
        />
      </Box>
    </Stack>
  );
};
