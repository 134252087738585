import { Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { NotificationAlerts } from "../../../../components/NotificationAlerts/NotificationAlerts";
import createDocumentStore from "../../../../store/createDocument.store";
import ContrActorGridLayout from "../ui/ContrActorGridLayout/ContrActorGridLayout";
import ICreateDocument from "./ICreateDocument";
import ContractsTable from "./ui/ContractsTable/ContractsTable";
import { CreateAct } from "./ui/CreateAct/CreateAct";
import CreateAttachment from "./ui/CreateAttachment/CreateAttachment";
import CreateContract from "./ui/CreateContract/CreateContract";
import ShowDocumentModal from "./ui/ShowDocumentModal/ShowDocumentModal";

export const CreateDocument: FC<ICreateDocument> = observer((props) => {
  const { data } = props;

  useEffect(() => {
    return () => {
      createDocumentStore.resetAttachment();
      createDocumentStore.resetContract();
      createDocumentStore.resetAct();
    };
  }, []);

  return (
    <ContrActorGridLayout data={data}>
      <CreateContract />
      <Stack spacing={2}>
        <CreateAttachment />
        <CreateAct />
      </Stack>
      <ContractsTable />
      <ShowDocumentModal />
      <NotificationAlerts
        sucsess={{
          text: createDocumentStore.successMessage,
          open: createDocumentStore.success,
          onClose: () => createDocumentStore.setSuccess(false),
        }}
        error={{
          text: createDocumentStore.errorMessage,
          open: createDocumentStore.error,
          onClose: () => createDocumentStore.setError(false),
        }}
      />
    </ContrActorGridLayout>
  );
});
