import { List, Lock, PriorityHigh } from "@mui/icons-material";
import { Avatar, Box, SxProps, TableCell, TableRow, Typography } from "@mui/material";
import { FC } from "react";
import { TableSort } from "../../../Table/ui/TableSort";
import { ThemeAddButtonDialog } from "../../../Table/ui/Columns/ThemeAddButtonDialog/ThemeAddButtonDialog";
import { PositioningAddButtonDialog } from "../../../Table/ui/Columns/PositioningAddButtonDialog/PositioningAddButtonDialog";
import { IStrategyField } from "../../../../strategy/interface/IStrategyField";

interface ITestTableHead {
  showCheckboxCell?: boolean;
  talbeHeaderColor?: string;
  showBurgerCell?: boolean;
  showAgreeIconsCell?: boolean;
  showDeleteIcon?: boolean;
  firstCellIcon?: boolean;
  sx?: SxProps;
  tableRow: IStrategyField[];
  widthCell?: string;
  isShowSort?: boolean;
  sortHandler?: (type: string, sortType: "asc" | "desc") => void;
  currentSortType?: string;
  onChangeCurrentSortType?: (type: string) => void;
  hideUrlSortCode?: boolean;
  showLastCell?: boolean;
}

export const TestTableHead: FC<ITestTableHead> = (props) => {
  const {
    showCheckboxCell,
    talbeHeaderColor = "#f2f3f5",
    showBurgerCell,
    showAgreeIconsCell,
    showDeleteIcon,
    firstCellIcon,
    sx,
    tableRow,
    widthCell,
    isShowSort,
    sortHandler,
    currentSortType,
    onChangeCurrentSortType,
    hideUrlSortCode,
    showLastCell,
  } = props;
  return (
    <TableRow>
      {showCheckboxCell && (
        <TableCell
          align="left"
          sx={{ width: "37px", p: 1, background: talbeHeaderColor }}></TableCell>
      )}
      {showBurgerCell && (
        <TableCell align="left" sx={{ width: "37px", p: 1, background: talbeHeaderColor }}>
          <List sx={{ display: "flex" }} />
        </TableCell>
      )}
      {showAgreeIconsCell && (
        <TableCell align="left" sx={{ p: 1, pl: 0, background: talbeHeaderColor }}>
          <PriorityHigh fontSize="small" sx={{ display: "flex", ml: -0.5 }} />
        </TableCell>
      )}
      {showDeleteIcon && (
        <TableCell align="left" sx={{ width: "37px", p: 1, background: talbeHeaderColor }}>
          <List sx={{ display: "flex" }} />
        </TableCell>
      )}
      {firstCellIcon && (
        <TableCell
          sx={{ p: 0, pl: 0, background: talbeHeaderColor, ...sx }}
          onClick={(e) => e.stopPropagation()}></TableCell>
      )}
      {tableRow.map((item, ind) => {
        if (item) {
          return (
            <TableCell
              key={ind + item.code}
              sx={{
                p: 1,
                width: widthCell ? widthCell : "auto",
                maxWidth: item.maxWidth,
                minWidth: item.minWidth,
                background: talbeHeaderColor,
              }}>
              {isShowSort && item.isSort ? (
                <TableSort
                  type={item.code}
                  onClick={sortHandler ? sortHandler : () => ""}
                  currentSortType={currentSortType ? currentSortType : ""}
                  onChangeCurrentSortType={
                    onChangeCurrentSortType ? onChangeCurrentSortType : () => ""
                  }
                  hideUrlSortCode={hideUrlSortCode}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      display: "inline-flex",
                      verticalAlign: "super",
                      whiteSpace: "wrap",
                    }}>
                    {item.title}
                  </Typography>
                  {item.immutable && <Lock sx={{ ml: 1 }} />}
                  {item.isTheme && <ThemeAddButtonDialog />}
                </TableSort>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {item.columnTitleiconSrc && (
                    <Avatar sx={{ height: 20, width: 20, mr: 0.5 }} src={item.columnTitleiconSrc} />
                  )}
                  <Typography
                    sx={{
                      textAlign: item.textAlign,
                      fontWeight: "bold",
                      display: "inline-flex",
                      verticalAlign: "super",
                      whiteSpace: "wrap",
                    }}>
                    {item.title}
                  </Typography>
                  {item.immutable && <Lock sx={{ ml: 1 }} />}
                  {item.isTheme && <ThemeAddButtonDialog />}
                  {item.isPositioning && <PositioningAddButtonDialog />}
                </Box>
              )}
            </TableCell>
          );
        } else return;
      })}
      {showLastCell && (
        <TableCell align="right" sx={{ p: 1, background: talbeHeaderColor }}></TableCell>
      )}
    </TableRow>
  );
};
