import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { IContrActorFieldContainer } from "./IContrActorFieldContainer";

export const ContrActorFieldContainer: FC<IContrActorFieldContainer> = (props) => {
  const { title, children, required } = props;
  return (
    <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={"space-between"}>
      <Typography minWidth={'130px'}>
        {title}
        {":"}
        {required && (
          <Typography component={"span"} color={"error"}>
            *
          </Typography>
        )}
      </Typography>
      {children}
    </Stack>
  );
};
