import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { FC, forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { TableVirtuoso } from "react-virtuoso";
import { IStrategyField } from "../../strategy/interface/IStrategyField";
import { TestTableHead } from "./UI/TestTableHead/TestTableHead";
import { TestTableItem } from "./UI/TestTableItem/TestTableItem";
import { LibraryBooks, OpenInNew, Settings } from "@mui/icons-material";
import { IBurgerItem } from "../Table/models/ITableItem";
import { CustomIconButton } from "../UI/Buttons/CustomIconButton/CustomIconButton";

const TableComponents = {
  Scroller: forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props: any) => <Table {...props} style={{ borderCollapse: "separate"}} />,
  TableHead: forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
    (props: any) => <TableHead {...props} />
  ),
  TableRow: forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
    (props: any) => <TableRow {...props} />
  ),
  TableBody: forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
    (props, ref) => <TableBody {...props} ref={ref} />
  ),
};

interface ITestTable {
  tableRow: IStrategyField[];
  [x: string]: any;
}

export const TestTable: FC<ITestTable> = (props) => {
  const { tableRow, data, settingsOnclick } = props;
  const [controlButtonPanel] = useState<IBurgerItem[]>([
    {
      title: "Открыть и посмотреть",
      onNavigate: props.navigateAside,
      isShow: true,
      icon: <OpenInNew />,
    },
    {
      title: "Копировать",
      isShow: true,
      alertProtacted: true,
      alertProtectedSetting: {
        title: "Внимание!",
        subtext: "Вы действительно хотите копировать интеграцию?",
        buttonCancelName: "Отмена",
        buttonSubmitName: "Копировать",
      },
      icon: <LibraryBooks />,
    },
  ]);

  const [renderTime, setRenderTime] = useState<number | null>(null);

  useEffect(() => {
    const start = performance.now();

    // Даем React возможность закончить рендеринг
    requestAnimationFrame(() => {
      const end = performance.now();
      setRenderTime(end - start);
      console.log(`⏱ Время рендеринга таблицы: ${end - start} ms`);
    });
  }, [data]);

  const renderItem = useCallback((index: number, item: any) => (
    <TestTableItem cells={tableRow} {...item} controlButtonPanel={controlButtonPanel} showCheckboxCell />
  ), [tableRow, controlButtonPanel]);

  return (
    <Box
      sx={{
        position: "relative",
        maxWidth: "calc(100vw - (70px + (16px * 2)))",
        overflow: "auto",
        pt: 2,
      }}>
      <Tooltip title="Настройки" placement="right">
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            left: "-3px",
            zIndex: 12,
            display: "inline-block",
          }}>
          <CustomIconButton onClick={settingsOnclick}>
            <Settings />
          </CustomIconButton>
        </Box>
      </Tooltip>
      <TableVirtuoso
        style={{ height: "calc(100% - 30px)" }}
        data={data}
        components={TableComponents}
        fixedHeaderContent={() => (
          <TestTableHead tableRow={tableRow} showBurgerCell isShowSort showCheckboxCell />
        )}
        itemContent={renderItem}
        overscan={20}
        increaseViewportBy={1500}
      />
    </Box>
  );
};
