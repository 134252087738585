import { FC, useState } from "react";
import CreateDocumentAccordion from "../CreateDocumentAccordion/CreateDocumentAccordion";
import { FormControl, SelectChangeEvent, Stack } from "@mui/material";
import CreateDocumentSelect from "../CreateDocumentSelect/CreateDocumentSelect";
import createDocumentStore from "../../../../../../store/createDocument.store";
import { IContractorCreateAct } from "../../../../../../services/contractor/IContractorIRequest";
import dayjs, { Dayjs } from "dayjs";
import CreateDocumentField from "../CreateDocumentField/CreateDocumentField";
import CreateDocumentDatePicker from "../CreateDocumentDatePicker/CreateDocumentDatePicker";
import { ContractorTypesEnum } from "../../../../../../entities/Contractor/models/ContractorTypesEnum";
import { CustomButton } from "../../../../../../components/UI/Buttons/CustomButton/CustomButton";
import { observer } from "mobx-react-lite";
import editContrActorStore from "../../../../../../store/editContrActor.store";

export const CreateAct: FC = observer(() => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const attachmentsOptions = createDocumentStore.attachmentsOptions;
  const contractorTypeOtherOrOOO =
    editContrActorStore.agent?.type.code === ContractorTypesEnum.OOO ||
    editContrActorStore.agent?.type.code === ContractorTypesEnum.OTHER;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    createDocumentStore.setActField(name as keyof IContractorCreateAct, value);
    validateRequired();
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;

    if (name === "channelType") {
      createDocumentStore.setDefaultPlacementCharacter(value);
    }

    createDocumentStore.setActField(name as keyof IContractorCreateAct, value);

    if (contractorTypeOtherOrOOO) {
      createDocumentStore.setActDirectorTitle(editContrActorStore.agent?.directorTitle);
      createDocumentStore.setActFio(editContrActorStore.agent?.fio);
    }

    validateRequired();
  };

  const handleDateChange = (date: Dayjs | null, name?: string) => {
    if (date && date.isValid()) {
      createDocumentStore.setActField(name as keyof IContractorCreateAct, date);
    } else {
      createDocumentStore.setActField(name as keyof IContractorCreateAct, "");
    }
    validateRequired();
  };

  const validateRequired = () => {
    const isFormValid = Object.values(createDocumentStore.act).every((value) => {
      if (dayjs.isDayjs(value)) {
        return value.isValid();
      }

      if (typeof value === "string") {
        return value.length > 0;
      }

      if (value === undefined) {
        return true;
      }

      if (typeof value === "number") {
        return value !== 0;
      }

      return !!value;
    });

    setIsFormValid(isFormValid);
  };

  const createAct = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    createDocumentStore.createAct().then(() => {
      setIsOpen(false);
      setIsFormValid(false);
    });
  };

  return (
    <CreateDocumentAccordion title="Создание акта" isOpen={isOpen} setIsOpen={setIsOpen}>
      <FormControl onSubmit={createAct} component={"form"} fullWidth>
        <Stack direction={"column"} spacing={2}>
          <CreateDocumentSelect
            name="attachmentId"
            onChange={handleSelectChange}
            options={attachmentsOptions}
            value={
              createDocumentStore.act.attachmentId.toString() === "0"
                ? ""
                : createDocumentStore.act.attachmentId.toString()
            }
            title="Акт к Приложению №"
            required
            placeholder="Выберите номер приложения"
          />
          <CreateDocumentField
            name="actNumber"
            title="№ Акта"
            onChange={handleChange}
            placeholder="Введите номер акта"
            value={createDocumentStore.act.actNumber}
            required
          />
          <CreateDocumentDatePicker
            name="actDate"
            title="Дата Акта"
            onChange={handleDateChange}
            placeholder="дд.мм.гггг"
            required
            value={createDocumentStore.act.actDate}
          />
          {contractorTypeOtherOrOOO && (
            <>
              <CreateDocumentField
                name="directorTitle"
                title="Должность исполнителя в Родительном падеже"
                onChange={handleChange}
                placeholder="Введите должность"
                value={createDocumentStore.act.directorTitle || ""}
                required
              />

              <CreateDocumentField
                name="fio"
                title={"ФИО контрагента ПОЛНОСТЬЮ в родительном падеже"}
                onChange={handleChange}
                placeholder="Введите ФИО"
                value={createDocumentStore.act.fio || ""}
                alignItems="start"
                required
              />
            </>
          )}
          <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>
            <CustomButton
              sx={{ mt: 1 }}
              variant={"contained"}
              type="submit"
              disabled={!isFormValid}>
              Создать акт
            </CustomButton>
          </Stack>
        </Stack>
      </FormControl>
    </CreateDocumentAccordion>
  );
});
