import { CircularProgress, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { FC } from "react";
import { ContrActorFieldContainer } from "../ContrActorFieldContainer/ContrActorFieldContainer";
import { IContrActorCreateSelect } from "./IContrActorCreateSelect";

export const ContrActorCreateSelect: FC<IContrActorCreateSelect> = (props) => {
  const {
    title,
    required,
    placeholder,
    options,
    form,
    field,
    onChange,
    maxWidth = "300px",
    isLoading,
    ...restProps
  } = props;

  const errorText =
    form.touched[field.name] && form.errors[field.name] ? (form.errors[field.name] as string) : "";
  return (
    <ContrActorFieldContainer title={title} required={required}>
      <FormControl
        variant="outlined"
        size="small"
        fullWidth
        required={required}
        error={!!errorText}
        sx={{ position: "relative", maxWidth: { maxWidth } }}>
        <Select
          {...restProps}
          {...field}
          onChange={onChange}
          displayEmpty
          defaultValue=""
          IconComponent={(props) =>
            isLoading ? (
              <CircularProgress size={20} sx={{ position: "absolute", top: "8px", right: "8px" }} />
            ) : (
              <ArrowDropDownIcon {...props} />
            )
          }
          renderValue={(selected) => {
            if (!selected && placeholder) {
              return (
                <Typography
                  sx={{
                    color: "rgba(187, 187, 187, 1)",
                    fontSize: 14,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}>
                  {placeholder}
                </Typography>
              );
            }

            const selectedItem = options.find((option) => option.value === selected);

            return selectedItem?.label;
          }}>
          {!required && <MenuItem value={""}>{"-"}</MenuItem>}
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {errorText && (
          <Typography
            variant="caption"
            color="error"
            component={"span"}
            sx={{
              position: "absolute",
              bottom: "-16px",
              left: "0px",
              margin: "0",
            }}>
            {errorText}
          </Typography>
        )}
      </FormControl>
    </ContrActorFieldContainer>
  );
};
