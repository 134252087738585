import { CoPresent, Download, Visibility } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Link,
  SelectChangeEvent,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { FC, useMemo, useRef, useState } from "react";
import { purple_color } from "../../../../../../../../../../app/styles/variables";
import { CopyIconButton } from "../../../../../../../../../../components/UI/Buttons/CopyIconButton/CopyIconButton";
import { CustomIconButton } from "../../../../../../../../../../components/UI/Buttons/CustomIconButton/CustomIconButton";
import { EditModeButton } from "../../../../../../../../../../components/UI/Buttons/EditModeButton/EditModeButton";
import { IContragentAttachment } from "../../../../../../../../../../services/contractor/IContractorIResponse";
import { priceTransormFormat } from "../../../../../../../../../../shared/functions/functions";
import createDocumentStore from "../../../../../../../../../../store/createDocument.store";
import IContractListItem from "./IContractListItem";
import DocumentType from "./shared/documentTypeEnum";
import { AddPayment } from "./ui/AddPayment/AddPayment";
import ContractDeleteBtn from "./ui/ContractDeleteBtn/ContractDeleteBtn";
import { ContractPayment } from "./ui/ContractPayment/ContractPayment";

const ContractListItem: FC<IContractListItem> = observer((props) => {
  const { document, lastEl, attachment, act, contractNumber, contractDate, availablePayments } =
    props;
  const [linkValue, setLinkValue] = useState(document.link);
  const [summValue, setSummValue] = useState(document.summ);
  const [selectPaymentValue, setSelectPaymentValue] = useState<string | null>(null);
  const [isPaymentSelecetOpen, setIsPaymentSelectOpen] = useState(false);
  const [isEditLinkOpen, setIsEditLinkOpen] = useState(false);
  const [isEditSummOpen, setIsEditSummOpen] = useState(false);

  const handlePaymentSelectOpen = () => {
    setIsPaymentSelectOpen(!isPaymentSelecetOpen);
  };

  const cellRef = useRef<HTMLTableCellElement | null>(null);

  const handleChangeLinkValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkValue(e.target.value);
  };

  const handleChangeSummValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const summ = Number(e.target.value);
    setSummValue(summ);
  };

  const currentDocumentType = useMemo(() => {
    return attachment ? DocumentType.ATTACHMENT : act ? DocumentType.ACT : DocumentType.CONTRACT;
  }, [act, attachment]);

  const handleEditLinkOpen = () => {
    setIsEditLinkOpen(!isEditLinkOpen);
    setLinkValue(document.link);
  };

  const handleEditSummOpen = () => {
    setIsEditSummOpen(!isEditSummOpen);
    setSummValue(document.summ);
  };

  const handleConfirmDelete = () => {
    return createDocumentStore.deleteDocument(document.id, currentDocumentType);
  };

  const handleChangeLink = () => {
    const options = {
      value: linkValue || "",
      field: "link",
    };

    createDocumentStore.editDocument(options, document.id, currentDocumentType).then(() => {
      setLinkValue(document.link);
      setIsEditLinkOpen(false);
    });
  };

  const handleChangeSumm = () => {
    const options = {
      value: summValue === 0 ? null : summValue,
      field: "summ",
    };

    createDocumentStore.editDocument(options, document.id, currentDocumentType).then(() => {
      setSummValue(document.summ);
      setIsEditSummOpen(false);
    });
  };

  const handleDownload = () => {
    const name = cellRef.current?.textContent || "Документ";

    createDocumentStore.downloadDocument(document.id, currentDocumentType, name);
  };

  const handleShowDocument = () => {
    createDocumentStore.showDocument(document.id, currentDocumentType);
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { value } = e.target;

    setSelectPaymentValue(value);
  };

  const submitPayment = () => {
    if (!selectPaymentValue) {
      createDocumentStore.setError(true);
      createDocumentStore.setErrorMessage("Заполните поле оплаты");
    } else {
      createDocumentStore
        .addPayment(+selectPaymentValue, currentDocumentType, document.id)
        .then(() => setIsPaymentSelectOpen(false));
    }
  };

  const createdAt = useMemo(() => {
    return dayjs(document.createdAt).format("DD.MM.YYYY");
  }, [document.createdAt]);

  const dated = useMemo(() => {
    return dayjs(document.date).format("DD.MM.YYYY");
  }, [document.date]);

  const cost = useMemo(() => {
    if (document.summ) {
      return priceTransormFormat(document.summ, true);
    }

    if ((document as IContragentAttachment).serviceCost) {
      return priceTransormFormat((document as IContragentAttachment).serviceCost, true);
    }

    return null;
  }, [document]);

  const isDateValid = (): boolean => {
    const referenceDate = dayjs("2024-11-24");
    return (
      dayjs(document.createdAt).isAfter(referenceDate) ||
      dayjs(document.createdAt).isSame(referenceDate)
    );
  };

  const tooltipTitle = `${
    currentDocumentType === DocumentType.CONTRACT
      ? "Договор создан контрагентом"
      : currentDocumentType === DocumentType.ATTACHMENT
      ? "Приложение создано контрагентом"
      : "Акт создан контрагентом"
  }`;

  return (
    <>
      <TableRow
        sx={{
          borderBottom: lastEl ? "2px solid" : "none",
          verticalAlign: "top",
          minHeight: "37px",
        }}>
        <TableCell sx={{ maxWidth: "90px", borderBottom: lastEl ? "2px solid inherit" : "none" }}>
          <Typography pt={1}>{createdAt}</Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: lastEl ? "2px solid inherit" : "none", maxWidth: "150px" }}>
          <Typography ref={cellRef} pt={(document.ourDocument || act) ? 1 : 0} position={"relative"}>
            {!document.ourDocument && !act && (
              <Tooltip title={tooltipTitle}>
                <Typography
                  component={"span"}
                  position={"relative"}
                  top={"4px"}
                  left={0}
                  pr={"4px"}>
                  <CoPresent />
                </Typography>
              </Tooltip>
            )}
            {attachment
              ? `Приложение № ${document.number} от ${dated} к Договору № ${contractNumber} от ${contractDate}`
              : act
              ? `Акт № ${document.number} от ${dated} к Приложению № ${contractNumber}`
              : `Договор № ${document.number} от ${dated}`}
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: lastEl ? "2px solid inherit" : "none" }}>
          <Checkbox checked={document.isNDS} disabled />
        </TableCell>
        <TableCell sx={{ borderBottom: lastEl ? "2px solid inherit" : "none" }}>
          <Checkbox checked={document.ourMarking} disabled />
        </TableCell>
        <TableCell sx={{ borderBottom: lastEl ? "2px solid inherit" : "none" }}>
          <Stack direction={"column"} rowGap={"2px"}>
            {availablePayments &&
              availablePayments?.length > 0 &&
              ((attachment && document.payment.length === 0) || !attachment) && (
                <AddPayment
                  options={availablePayments}
                  handleSelectChange={handleSelectChange}
                  submitPayment={submitPayment}
                  handlePaymentSelectOpen={handlePaymentSelectOpen}
                  isPaymentSelectOpen={isPaymentSelecetOpen}
                />
              )}
            {document.payment &&
              document.payment.length !== 0 &&
              document.payment.map((paymentItem) => (
                <ContractPayment
                  key={paymentItem.id}
                  payment={paymentItem}
                  documentId={document.id}
                  documentType={currentDocumentType}
                  showDeleteBtn={!act && isDateValid()}
                />
              ))}
          </Stack>
        </TableCell>
        <TableCell
          sx={{ borderBottom: lastEl ? "2px solid inherit" : "none", textAlign: "center" }}>
          <Box
            display={"flex"}
            columnGap={1}
            alignItems={"center"}
            minHeight={"37px"}>
            {isEditSummOpen && (
              <TextField
                type="number"
                value={summValue}
                size="small"
                onChange={handleChangeSummValue}
                sx={{ minWidth: "55px", maxWidth: '100px' }}></TextField>
            )}
            {!isEditSummOpen &&
              (cost ? (
                <Typography>{cost}</Typography>
              ) : !act ? (
                <Typography
                  onClick={handleEditSummOpen}
                  sx={{ cursor: "pointer", "&:hover": { color: purple_color } }}>
                  Добавить
                </Typography>
              ) : <Typography>&mdash;</Typography>)}
            {!attachment && !act && (
              <EditModeButton
                onClose={handleEditSummOpen}
                isEdit={isEditSummOpen}
                onOpenEdit={handleEditSummOpen}
                onSubmit={handleChangeSumm}
              />
            )}
          </Box>
        </TableCell>
        <TableCell sx={{ borderBottom: lastEl ? "2px solid inherit" : "none" }}>
          <Box
            display={"flex"}
            columnGap={1}
            alignItems={"center"}
            minHeight={"37px"}
            width={"220px"}>
            {isEditLinkOpen && (
              <TextField
                value={linkValue ? linkValue : ""}
                size="small"
                onChange={handleChangeLinkValue}></TextField>
            )}
            {!isEditLinkOpen &&
              (document.link ? (
                <Tooltip title={<Typography>{document.link}</Typography>}>
                  <Link color={"secondary"} href={document.link} target="_blank">
                    <Typography noWrap maxWidth={"150px"}>
                      {document.link}
                    </Typography>
                  </Link>
                </Tooltip>
              ) : (
                <Typography
                  onClick={handleEditLinkOpen}
                  sx={{ cursor: "pointer", "&:hover": { color: purple_color } }}>
                  Добавить ссылку
                </Typography>
              ))}
            {document.link !== null && document.link !== "" && !isEditLinkOpen && (
              <CopyIconButton copyText={document.link} />
            )}
            <EditModeButton
              onClose={handleEditLinkOpen}
              isEdit={isEditLinkOpen}
              onOpenEdit={handleEditLinkOpen}
              onSubmit={handleChangeLink}
            />
          </Box>
        </TableCell>
        <TableCell
          sx={{ textAlign: "-webkit-center", borderBottom: lastEl ? "2px solid inherit" : "none" }}>
          {document.documentPath ? (
            <Box sx={{ display: "flex", maxWidth: "fit-content", columnGap: 1 }} pt={1}>
              <CustomIconButton onClick={handleShowDocument}>
                <Visibility fontSize="small" />
              </CustomIconButton>
              <CustomIconButton onClick={handleDownload}>
                <Download fontSize="small" />
              </CustomIconButton>
              <ContractDeleteBtn
                onDelete={handleConfirmDelete}
                subtext="Вы действительно хотите удалить документ?"
              />
            </Box>
          ) : (
            !document.ourDocument && (
              <ContractDeleteBtn
                onDelete={handleConfirmDelete}
                subtext="Вы действительно хотите удалить документ?"
              />
            )
          )}
        </TableCell>
      </TableRow>
    </>
  );
});

export default ContractListItem;
