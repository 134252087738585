import { OpenInNew, VideoCameraFrontOutlined } from "@mui/icons-material";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Checkbox, TableCell, Typography } from "@mui/material";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { ChannelDate } from "../../../../pages/ChannelCardPage/ui/ChannelDate/ChannelDate";
import { WrapperCellIcons } from "../../../../pages/DashboardPage/pages/AgreementPage/ui/AgreementTableItem/ui/WrapperCellIcons/WrapperCellIcons";
import { TechnicalTask } from "../../../../pages/TableAsidePage/pages/Main/ui/TechnicalTask/TechnicalTask";
import { priceTransormFormat } from "../../../../shared/functions/functions";
import tableRowsStore from "../../../../store/tableRows.store";
import { ReleaseList } from "../../../ReleaseList/ReleaseList";
import { ITableItem } from "../../../Table/models/ITableItem";
import { changeTableColor } from "../../../Table/shared/functions";
import { ControlButtonPanel } from "../../../Table/ui/ControlButtonPanel/ControlButtonPanel";
import { AddPromokodField } from "../../../Table/ui/Fields/AddPromokodField/AddPromokodField";
import { AddReleaseField } from "../../../Table/ui/Fields/AddReleaseField/AddReleaseField";
import { ChannelPopUp } from "../../../Table/ui/Fields/ChannelPopUp/ChannelPopUp";
import { ChannelPriceEdit } from "../../../Table/ui/Fields/ChannelPriceEdit/ChannelPriceEdit";
import { CheckboxField } from "../../../Table/ui/Fields/CheckboxField/CheckboxField";
import { CheckboxFieldPayment } from "../../../Table/ui/Fields/CheckboxFieldPayment/CheckboxFieldPayment";
import { CommentField } from "../../../Table/ui/Fields/CommentField/CommentField";
import { DateEditField } from "../../../Table/ui/Fields/DateEditField/DateEditField";
import { GoodsField } from "../../../Table/ui/Fields/GoodsField/GoodsField";
import { GuaranteeCheckboxField } from "../../../Table/ui/Fields/GuaranteeCheckboxField/GuaranteeCheckboxField";
import { GuaranteeField } from "../../../Table/ui/Fields/GuaranteeField/GuaranteeField";
import { IntegrationPaymentDate } from "../../../Table/ui/Fields/IntegrationPaymentDate/IntegrationPaymentDate";
import { IntegrationPopUp } from "../../../Table/ui/Fields/IntegrationPopUp/IntegrationPopUp";
import { LastCommentField } from "../../../Table/ui/Fields/LastCommentField/LastCommentField";
import { LinkField } from "../../../Table/ui/Fields/LinkField/LinkField";
import { PaymentStatusIntegrationField } from "../../../Table/ui/Fields/PaymentStatusIntegrationField/PaymentStatusIntegrationField";
import { PredictionField } from "../../../Table/ui/Fields/PredictionField/PredictionField";
import { PriceEdit } from "../../../Table/ui/Fields/PriceEdit/PriceEdit";
import { PromokodField } from "../../../Table/ui/Fields/PromokodField/PromokodField";
import { ReleaseManager } from "../../../Table/ui/Fields/ReleaseManager/ReleaseManager";
import { StatusField } from "../../../Table/ui/Fields/StatusField/StatusField";
import { TableBurgerMenu } from "../../../Table/ui/TableBurgerMenu";
import { CopyIconButton } from "../../../UI/Buttons/CopyIconButton/CopyIconButton";
import { CustomIconButton } from "../../../UI/Buttons/CustomIconButton/CustomIconButton";
import { RemoveItemButton } from "../../../UI/Buttons/RemoveItemButton/RemoveItemButton";

export const TestTableItem: FC<ITableItem> = observer((props) => {
  const {
    cells,
    showBurgerMenu = false,
    onChangeStatus,
    onUpdateStatus,
    burgerItemLists,
    isPriceEdit = false,
    hover = false,
    isStatusReadOnly,
    statusStore,
    readonly = false,
    showDeleteIcon,
    onDeleteItem,
    deleteDisabled,
    isSelected = false,
    onKeyDown,
    changeCallbackNumEdit,
    deleteIconSize = "medium",
    agreementStatusReadOnly = false,
    onChangeUpdateId,
    backgroundColor,
    agreementStatusCallback,
    showCheckboxCell = false,
    sx,
    allAllowedMoves,
    changePredictionNumEdit,
    readOnlyDateGiven,
    isBanned,
    enableCodesBurderItem,
    showDelete,
    lastCellContent,
    hiddenDateGivenEdit,
    isAccordion,
    onChangeType,
    borderColor,
    readOnlyChannelPrice,
    showAgreeIconsCell,
    tableRowId,
    searchBackgroundColor,
    isShowNavigateIcon,
    onNavigate,
    changePaymentInTable,
    hiddenDateGivenNotValueText,
    onChangePriceAndStatus,
    hiddenDateFoundEdit,
    isUsingChannelId = false,
    firstCellIcon,
    controlButtonPanel,
    changePaymentStatusInIntegrationTable,
    style,
    ...restProps
  } = props;

  // const [selected, setSelected] = useState(false);

  // const handleCheckBoxChange = () => {
  //   tableRowsStore.setCheckedRows(restProps.id);
  //   setSelected(!selected);
  // };

  return (
    <>
      {showCheckboxCell && (
        <TableCell
          sx={{ p: 0, pl: 0, background: "inherit", ...sx }}
          onClick={(e) => e.stopPropagation()}>
          <Checkbox checked={true} onChange={() => ''} />
        </TableCell>
      )}
      {isShowNavigateIcon && onNavigate && (
        <TableCell
          align="left"
          sx={{ p: 0, pl: 0, background: "inherit", ...sx }}
          onClick={(e) => e.stopPropagation()}>
          <CustomIconButton
            onClick={() =>
              isUsingChannelId ? onNavigate(restProps?.channel?.id) : onNavigate(restProps?.id)
            }>
            <OpenInNew />
          </CustomIconButton>
        </TableCell>
      )}
      {showBurgerMenu && !isAccordion && (
        <TableCell align="left" sx={{ p: "6px 8px", background: "inherit", ...sx }}>
          {burgerItemLists && (
            <TableBurgerMenu
              lists={burgerItemLists}
              restProps={restProps}
              isBanned={isBanned}
              update_id={restProps["update_id"]}
              enableCodesItem={enableCodesBurderItem}
              showDelete={showDelete}
            />
          )}
        </TableCell>
      )}
      {controlButtonPanel && (
        <TableCell align="left" sx={{ p: "6px 8px", background: "inherit", ...sx }}>
          <ControlButtonPanel
            lists={controlButtonPanel}
            restProps={restProps}
            isBanned={isBanned}
          />
        </TableCell>
      )}
      {showBurgerMenu && isAccordion && (
        <TableCell align="left" sx={{ p: "6px 8px", background: "inherit", ...sx }}>
          {/* <KeyboardArrowRightIcon /> */}
        </TableCell>
      )}
      {showAgreeIconsCell && (
        <TableCell
          sx={{ p: 0, pl: 0, background: "inherit", ...sx }}
          onClick={(e) => e.stopPropagation()}>
          <WrapperCellIcons
            userApprovments={restProps?.userApprovments}
            id={restProps?.agreementId}
          />
        </TableCell>
      )}
      {showDeleteIcon && (
        <TableCell align="center" sx={{ p: "6px 8px", ...sx }}>
          <RemoveItemButton
            size={deleteIconSize}
            disabled={deleteDisabled}
            onClick={onDeleteItem ? () => onDeleteItem(restProps) : undefined}
            sx={{ ml: 0, mr: 0 }}
          />
        </TableCell>
      )}
      {firstCellIcon && (
        <TableCell
          sx={{ p: 0, pl: 0, background: "inherit", ...sx }}
          onClick={(e) => e.stopPropagation()}>
          {firstCellIcon}
        </TableCell>
      )}
      {cells.map((cell, ind) => {
        if (cell) {
          if (restProps[cell.code] !== undefined && restProps[cell.code] !== null) {
            return (
              <TableCell
                key={cell.code + ind}
                sx={{
                  cursor: !readonly && hover ? "pointer" : cell?.immutable ? "not-allowed" : "auto",
                  background: cell.backgroundColor
                    ? cell.backgroundColor
                    : changeTableColor(cell.code, +restProps[cell.code], cell.isBackgroundColor),
                  p: "6px 8px",
                  minWidth: cell?.minWidth ? cell.minWidth : "auto",
                  maxWidth: cell.maxWidth ? cell.maxWidth : "inherit",
                  borderColor: borderColor,
                  ...sx,
                }}>
                {cell.isPomokod && restProps[cell.code].code ? (
                  <Box sx={{ display: "inline-block" }} onClick={(e) => e.stopPropagation()}>
                    <PromokodField
                      id={restProps["id"]}
                      promokod={restProps[cell.code].code}
                      dummies={restProps[cell.code].dummies}
                      isEditMode
                      readOnly={readonly}
                      isActivated={restProps[cell.code]?.isActivated}
                    />
                  </Box>
                ) : null}
                {cell.isIntegrationPaymentsDateEdit && cell.isDate && cell.fieldDataDateCode && (
                  <IntegrationPaymentDate
                    update_id={restProps?.payment?.update_id}
                    date={restProps[cell.code]}
                    field={cell.code}
                    oplataId={restProps?.payment?.id}
                    readOnly={readonly}
                    integrationId={restProps?.id}
                    field_data_code={cell.fieldDataDateCode}
                  />
                )}

                {cell.isDate &&
                  !cell.isIntegrationDatesEdit &&
                  !cell.isIntegrationPaymentsDateEdit && (
                    <>
                      {!cell.isDateEdit ? (
                        <DateEditField
                          transferStatus={
                            cell.code === "dateGiven" && restProps.transfer?.status?.code
                          }
                          id={restProps["id"]}
                          update_id={restProps["update_id"]}
                          field={cell.code}
                          date={restProps[cell.code]}
                          readonly={readonly ? true : !readonly || cell.readOnly!}
                          isFullYears={cell.isFullYears}
                        />
                      ) : (
                        <ChannelDate
                          id={restProps["id"]}
                          initValue={restProps[cell.code]}
                          update_id={restProps["update_id"]}
                          readOnly={cell.code === "dateGiven" ? readOnlyDateGiven : readonly}
                          hiddenEdit={
                            cell.code === "dateGiven"
                              ? hiddenDateGivenEdit
                              : cell.code === "dateFound"
                              ? hiddenDateFoundEdit
                              : false
                          }
                          field={cell.code}
                        />
                      )}
                    </>
                  )}
                {cell.isDate && cell.isIntegrationDatesEdit && (
                  <DateEditField
                    id={restProps["id"]}
                    update_id={restProps["update_id"]}
                    field={cell.code}
                    date={restProps[cell.code]}
                    readonly={readonly || cell.readOnly!}
                    isFullYears={cell.isFullYears}
                    isIntegrationDates={cell.isIntegrationDatesEdit}
                  />
                )}
                {cell.isReleases ? (
                  restProps[cell.code].length > 0 ? (
                    <Box onClick={(e) => e.stopPropagation()}>
                      <ReleaseList
                        id={restProps["id"]}
                        array={restProps[cell.code]}
                        readOnly={readonly}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ display: "inline-block" }} onClick={(e) => e.stopPropagation()}>
                      <AddReleaseField
                        type={restProps["channel"]?.type?.code}
                        id={restProps["id"]}
                        readOnly={readonly}
                      />
                    </Box>
                  )
                ) : null}
                {cell.isStatuses ? (
                  <>
                    <Box onClick={(e) => e.stopPropagation()}>
                      {cell.isIntegrationPaymentStatus !== true &&
                      statusStore &&
                      onChangeStatus &&
                      onUpdateStatus ? (
                        <StatusField
                          id={restProps["id"] as number}
                          update_id={restProps["update_id"] as number}
                          type={restProps[cell.code].code}
                          statusStore={statusStore}
                          readOnly={isStatusReadOnly ? true : readonly}
                          onChangeStatus={onChangeStatus}
                          onChange={restProps.onChange}
                          onUpdateStatus={onUpdateStatus}
                          integrationId={restProps?.id}
                          allAllowedMoves={allAllowedMoves}
                          onChangeType={onChangeType}
                        />
                      ) : null}
                      {cell.isIntegrationPaymentStatus && (
                        <PaymentStatusIntegrationField
                          changePaymentStatusInIntegrationTable={
                            changePaymentStatusInIntegrationTable
                          }
                          integrationId={restProps?.id}
                          id={restProps?.payment?.id}
                          type={restProps[cell.code].code}
                          update_id={restProps?.payment?.update_id}
                          readOnly={readonly}
                        />
                      )}
                    </Box>
                  </>
                ) : null}
                {cell.isPaymentCheckbox && cell.fieldCode && (
                  <CheckboxFieldPayment
                    integrationId={restProps?.id}
                    checked={restProps?.payment?.[cell.fieldCode]}
                    field={cell.fieldCode}
                    id={restProps?.payment?.id}
                    readOnly={readonly}
                    update_id={restProps?.payment?.update_id}
                  />
                )}

                {cell.isChannel && (
                  <Box onClick={(e) => e.stopPropagation()}>
                    {cell.code !== "channel" ? (
                      <ChannelPopUp
                        showArrowLink={cell.showArrowLink}
                        name={restProps?.name}
                        link={restProps?.link}
                        channelType={restProps?.type?.code}
                        channelId={restProps?.id}
                        update_id={restProps?.update_id}
                        readOnly={readonly}
                        isBanned={isBanned || restProps?.isBanned}
                        showPaymentOnCardIcon={restProps.isPaymentOnCard || false}
                        showVerifiedIcon={restProps.isVerifiedByGov || false}
                        audience={restProps.audience}
                      />
                    ) : (
                      <IntegrationPopUp
                        name={restProps[cell.code]?.name}
                        link={restProps[cell.code]?.link}
                        channelType={restProps[cell.code]?.type?.code}
                        showChannelButton
                        channelId={restProps[cell.code]?.id}
                        update_id={restProps[cell.code]?.update_id}
                        channelName={restProps[cell.code]?.name}
                        readOnly={readonly}
                        isBanned={restProps?.channel?.isBanned}
                        showCopy={restProps?.showCopy}
                        showIcon={restProps?.showIcon}
                        width={restProps?.width}
                        showVideoReviewIcon={restProps?.isVideoReview || false}
                        showPaymentOnCardIcon={restProps.isPaymentOnCard || false}
                        showVerifiedIcon={restProps[cell.code]?.isVerifiedByGov || false}
                        audience={restProps[cell.code]?.audience}
                      />
                    )}
                  </Box>
                )}

                {cell.isFirstIntegrationWithChannel !== undefined && (
                  <Typography>{restProps[cell.code] === true ? "Новый" : "Старый"}</Typography>
                )}
                {cell.isLinks && (
                  <LinkField
                    link={cell?.href ? cell.href(restProps["id"]) : restProps[cell.code]}
                    maxWidth={cell.maxWidth}
                  />
                )}

                {cell.isGuarantee && (
                  <GuaranteeField
                    isGuaranteed={Boolean(restProps?.guarantee?.repeat)}
                    color={restProps[cell.code]?.status?.color}
                  />
                )}
                {cell.isGuaranteeCheckbox && (
                  <GuaranteeCheckboxField checked={Boolean(restProps?.guarantee?.repeat)} />
                )}

                {cell.isGoods && (
                  <Box onClick={(e) => e.stopPropagation()}>
                    <GoodsField
                      id={restProps["id"]}
                      goods={restProps[cell.code]}
                      readOnly={readonly}
                      forFirstIntegrationWithChannel={restProps?.firstIntegrationWithChannel}
                    />
                  </Box>
                )}

                {cell.isPredictions && (
                  <PredictionField
                    id={restProps["id"]}
                    field_code={cell.code}
                    initValue={restProps[cell.code]}
                    update_id={restProps["update_id"]}
                    readOnly={readonly || cell.readOnly ? true : false}
                    onChangeUpdateId={onChangeUpdateId}
                    symbol={cell.isPercent ? "%" : undefined}
                    showRemains={cell.isShowRemains ? true : false}
                  />
                )}

                {cell.isLastComments ? (
                  <>
                    {restProps[cell.code]?.length > 0 ? (
                      <LastCommentField
                        id={restProps?.id}
                        text={restProps[cell.code][restProps[cell.code]?.length - 1]?.text}
                        comments={restProps[cell.code]}
                      />
                    ) : (
                      <Typography sx={{ display: "inline-flex", verticalAlign: "super" }}>
                        —
                      </Typography>
                    )}
                  </>
                ) : null}

                {cell.isReleaseUsers ? (
                  <Box sx={{ float: "left" }}>
                    <ReleaseManager isMenu={cell.isMenu} id={restProps[cell.code].id} />
                  </Box>
                ) : null}
                {cell.isSize && (
                  <Typography>
                    {cell.fieldCode
                      ? restProps?.[cell.code]?.[cell.fieldCode]?.name
                      : restProps[cell.code]?.name}
                  </Typography>
                )}

                {cell.isMoney && (
                  <>
                    {cell.isChannelPriceEdit === false ? (
                      <PriceEdit
                        id={restProps["id"]}
                        price={restProps[cell.code]}
                        field={cell.code}
                        update_id={restProps["update_id"]}
                        minWidth="200px"
                        isEditMode={cell.readOnly ? false : isPriceEdit && !readonly}
                        showRemains={cell.isShowRemains}
                        onChangePriceAndStatus={onChangePriceAndStatus}
                      />
                    ) : (
                      <ChannelPriceEdit
                        id={restProps["id"]}
                        value={restProps[cell.code]}
                        field={cell.code}
                        update_id={restProps["update_id"]}
                        readOnly={readonly}
                      />
                    )}
                  </>
                )}

                {cell.isCheckList && (
                  <CheckboxField
                    id={restProps?.id}
                    field={cell.code}
                    update_id={restProps["update_id"]}
                    checked={restProps[cell.code]}
                    readOnly={readonly}
                  />
                )}
                {cell.isIntegrationTheame && (
                  <Typography
                    noWrap
                    sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                    {restProps?.channel?.theme?.name || "—"}
                  </Typography>
                )}

                {cell.isTz && (
                  <TechnicalTask
                    goods={restProps?.goods}
                    goodsCategoryCode={restProps?.goodsCategory?.code}
                    integrationId={restProps["id"]}
                    update_id={restProps["update_id"]}
                    readOnly={readonly || cell.readOnly}
                    iniValue={{
                      id: restProps[cell.code]?.id,
                      link: restProps[cell.code]?.link,
                      name: restProps[cell.code]?.name,
                      allowUsage: restProps[cell.code]?.allowUsage,
                      goodsCategoryCode: restProps[cell.code]?.goodsCategoryCode,
                    }}
                    minWidth={175}
                    channelType={restProps["channel"]?.type?.code}
                  />
                )}

                {cell.isCtrls ? (
                  <Typography sx={{ minWidth: cell.minWidth }} noWrap>
                    {priceTransormFormat(
                      restProps[cell.code],
                      false,
                      cell.isShowRemains ? true : false,
                      cell.isShowRemains ? true : false
                    )}{" "}
                    {cell.ctrlsIcon}
                  </Typography>
                ) : null}

                {cell.isSimple &&
                cell.code !== "comment" &&
                cell.isWritting !== true &&
                cell.isMoneySimple !== true ? (
                  <>
                    <Typography noWrap>
                      {cell.showSimpleVideoReviewIcon && restProps?.isVideoReview === true && (
                        <VideoCameraFrontOutlined sx={{ verticalAlign: "top", mr: 0.5 }} />
                      )}
                      {cell.isShowRemains === false
                        ? Math.round(restProps[cell.code])
                        : restProps[cell.code]}{" "}
                      {cell.isPercent && " %"} {cell.ctrlsIcon ? cell.ctrlsIcon : null}
                    </Typography>
                    {cell.isSimpleCopy && <CopyIconButton copyText={restProps[cell.code]} />}
                  </>
                ) : (
                  <>
                    {cell.code === "comment" && cell.isWritting !== true ? (
                      <CommentField
                        text={restProps[cell.code]}
                        subtext={cell.isPercent ? " %" : ""}
                      />
                    ) : null}
                  </>
                )}
              </TableCell>
            );
          } else {
            if (cell.isTz && restProps[cell.code] === null) {
              return (
                <TableCell key={cell.title} sx={{ background: "inherit", p: "6px 8px", ...sx }}>
                  <TechnicalTask
                    integrationId={restProps["id"]}
                    update_id={restProps["update_id"]}
                    readOnly={readonly || cell.readOnly}
                    iniValue={null}
                    minWidth={200}
                    channelType={restProps["channel"]?.type?.code}
                  />
                </TableCell>
              );
            } else if (cell.isPomokod && restProps[cell.code] === null) {
              return (
                <TableCell
                  key={cell.title}
                  sx={{ p: "6px 8px", background: "inherit", ...sx, borderColor: borderColor }}>
                  <Box sx={{ display: "inline-block" }} onClick={(e) => e.stopPropagation()}>
                    <AddPromokodField id={restProps["id"]} readOnly={readonly} />
                  </Box>
                </TableCell>
              );
            } else {
              return (
                <TableCell
                  key={cell.title}
                  sx={{ p: "6px 8px", background: "inherit", ...sx, borderColor: borderColor }}>
                  <Typography>—</Typography>
                </TableCell>
              );
            }
          }
        } else return;
      })}
      {lastCellContent && (
        <TableCell sx={{ p: "6px 8px", background: "inherit", ...sx, borderColor: borderColor }}>
          {lastCellContent}
        </TableCell>
      )}
    </>
  );
});
