import {FC} from 'react';
import {
    IAsideTableStatisticField
} from "../../../../../TableAsidePage/ui/AsideTableStatistic/models/IAsideTableStatisticField";
import {TableItem} from "../../../../../../components/Table/ui/TableItem";
import {IIntegrationContrTableItem} from "./IIntegrationContrTable";

const IntegrationContrTableItem:FC<IIntegrationContrTableItem> = (props) => {
    const {
        rows,
        dataField,
    } = props

    const createItem = (fields:IAsideTableStatisticField[]):Object => {
        const item:any = {}

        for(let property of fields) {
            item[property.code] = property.value
        }

        return item
    }


    return (
        <TableItem
            cells={rows}
            {...createItem(dataField)}
            sx={{p:1, pt:1,pb:1}}
        />
    );
};

export default IntegrationContrTableItem;