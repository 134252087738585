import { makeAutoObservable } from "mobx";
import ContractorService from "../services/contractor/contractor.service";
import { IContrActorCreate } from "../services/contractor/IContractorIRequest";
import { IContrActorSimpleObjResponse } from "../services/contractor/IContractorIResponse";
import ReferencesService from "../services/references/references.service";
import contrActorTableStore from "./contrActorTable.store";

class CreateContrActorStore {
  success: boolean = false;
  error: boolean = false;
  successMessage: string = "";
  errorMessage: string = "";
  isLoading: boolean = false;
  closeModal: boolean = false;

  contractor: IContrActorCreate = {
    typeCode: "",
    name: "",
    inn: "",
    edo: true,
    operatorCode: "",
    contractLink: "",
    contact: {
      documentContact: "",
    },
    companyDetails: {
      ogrn: "",
      kpp: "",
      businessAddress: "",
    },
    otherDetails: {
      ogrn: "",
      kpp: "",
      businessAddress: "",
    },
    ipDetails: {
      ogrnip: "",
      registrationAddress: "",
    },
    selfEmployedDetails: {
      registrationAddress: "",
    },
    bankName: "",
    accountNumber: "",
    corrAccount: "",
    bic: "",
    fio: "",
    directorTitle: "",
  };

  contractorTypeOptions: { value: string | number; label: string }[] = [];
  contractorOperatorOptions: { value: string | number; label: string }[] = [];
  contractorTypeValue: string = "type";
  contractorOperatorValue: string = "operator";
  constructor() {
    makeAutoObservable(this);
  }

  setContractorTypeOptions(data: IContrActorSimpleObjResponse[]) {
    this.contractorTypeOptions = data.map((item) => ({ value: item.code, label: item.name }));
  }

  setContractorOperatorOptions(data: IContrActorSimpleObjResponse[]) {
    this.contractorOperatorOptions = data.map((item) => ({ value: item.code, label: item.name }));
  }

  getContractorOperatorOptions() {
    return ReferencesService.referencesContrActor({ field: this.contractorOperatorValue })
      .then((res) => {
        this.setContractorOperatorOptions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getContractorTypeOptions() {
    return ReferencesService.referencesContrActor({ field: this.contractorTypeValue })
      .then((res) => {
        this.setContractorTypeOptions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  setSucces(value: boolean) {
    this.success = value;
  }

  setSuccessMessage(message: string) {
    this.successMessage = message;
  }

  setError(value: boolean) {
    this.error = value;
  }

  setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  setContractor(contractor: IContrActorCreate) {
    this.contractor = contractor;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setCloseModal(value: boolean) {
    this.closeModal = value;
  }

  // Костыль из за спешки
  cleanFormValues(values: any): any {
    const cleanedValues: any = { ...values };

    Object.keys(cleanedValues).forEach((key) => {
      const value = cleanedValues[key];

      if (typeof value === "string" && value === "") {
        // Удаляем поля с пустыми строками
        delete cleanedValues[key];
      } else if (typeof value === "object" && value !== null) {
        // Рекурсивная очистка вложенных объектов
        cleanedValues[key] = this.cleanFormValues(value);

        // Удаляем объект, если все его поля пусты после очистки
        if (Object.keys(cleanedValues[key]).length === 0) {
          delete cleanedValues[key];
        }
      }
    });

    return cleanedValues;
  }

  createContractor(values: IContrActorCreate) {
    this.setIsLoading(true);
    const clearedValues = this.cleanFormValues(values);
    return ContractorService.create(clearedValues)
      .then((res) => {
        const mappedObj = {
          counteragent_id: res.data.id,
          id: res.data.id,
          counteragent_edo: res.data.edo === true ? "Есть" : "Нет",
          counteragent_inn: res.data.inn,
          counteragent_name: res.data.name,
          counteragent_type: res.data.type,
          counteragent_operator: res.data.operator?.name,
          counteragent_shortName: res.data.shortName,
          counteragent_createdAt: res.data.createdAt,
          counteragent_createdBy: res.data.createdBy,
        };
        contrActorTableStore.addItem(mappedObj);

        this.setSucces(true);
        this.setSuccessMessage("Контрагент успешно создан!");
        this.setCloseModal(true);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorMessage(`Ошибка при создании контрагента: "${err.response.data.message}"`);
        this.setCloseModal(false);
      })
      .finally(() => this.setIsLoading(false));
  }

  resetContractor() {
    this.contractor = {
      typeCode: "",
      name: "",
      inn: "",
      edo: true,
      operatorCode: "",
      contractLink: "",
      contact: {
        documentContact: "",
      },
      companyDetails: {
        ogrn: "",
        kpp: "",
        businessAddress: "",
      },
      otherDetails: {
        ogrn: "",
        kpp: "",
        businessAddress: "",
      },
      ipDetails: {
        ogrnip: "",
        registrationAddress: "",
      },
      selfEmployedDetails: {
        registrationAddress: "",
      },
      bankName: "",
      accountNumber: "",
      corrAccount: "",
      bic: "",
      fio: "",
      directorTitle: "",
    };
  }
}

export default new CreateContrActorStore();
