import { Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { EmptyText } from "../../../../../../../../components/EmptyText/EmptyText";
import editContrActorStore from "../../../../../../../../store/editContrActor.store";
import { ContractorTypesEnum } from "../../../../../../../../entities/Contractor/models/ContractorTypesEnum";

export const ContractorInfo: FC = observer(() => {
  const contractor = editContrActorStore.agent;

  return (
    <Stack direction={"column"} rowGap={1} pt={2}>
      <Stack direction={"row"} alignItems={"center"}>
        <Typography minWidth={"160px"} noWrap>
          Контрагент:
        </Typography>
        <Typography>{contractor?.shortName}</Typography>
      </Stack>
      <Stack direction={"row"} alignItems={"center"}>
        <Typography minWidth={"160px"}> ИНН:</Typography>
        <Typography>{contractor?.inn}</Typography>
      </Stack>
      {!contractor?.operator && (
        <Stack direction={"row"} alignItems={"center"}>
          <Typography minWidth={"160px"}>ЭДО:</Typography>
          <Typography>нет</Typography>
        </Stack>
      )}
      {contractor?.operator && (
        <Stack direction={"row"} alignItems={"center"}>
          <Typography minWidth={"160px"}>Оператор ЭДО:</Typography>
          <Typography>{contractor.operator.name}</Typography>
        </Stack>
      )}
      {contractor?.type.code === ContractorTypesEnum.OOO ||
      contractor?.type.code === ContractorTypesEnum.OTHER ? (
        <>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography minWidth={"160px"}>ФИО представителя:</Typography>
            <Typography whiteSpace={"nowrap"} textOverflow={"ellipsis"} overflow={"hidden"}>
              {contractor?.fio || <EmptyText component={"span"}>данные отсутствуют</EmptyText>}
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography minWidth={"160px"} maxWidth={"160px"}>
              Должность представителя:
            </Typography>
            <Typography whiteSpace={"nowrap"} textOverflow={"ellipsis"} overflow={"hidden"}>
              {contractor?.directorTitle || (
                <EmptyText component={"span"}>данные отсутствуют</EmptyText>
              )}
            </Typography>
          </Stack>
        </>
      ) : null}
      <Stack direction={"row"} alignItems={"center"}>
        <Typography minWidth={"160px"}>Наименование банка:</Typography>
        <Typography whiteSpace={"nowrap"} textOverflow={"ellipsis"} overflow={"hidden"}>
          {contractor?.bankName || <EmptyText component={"span"}>данные отсутствуют</EmptyText>}
        </Typography>
      </Stack>
      <Stack direction={"row"} alignItems={"center"}>
        <Typography minWidth={"160px"}>Расчетный счет:</Typography>
        <Typography>
          {contractor?.accountNumber || (
            <EmptyText component={"span"}>данные отсутствуют</EmptyText>
          )}
        </Typography>
      </Stack>
      <Stack direction={"row"} alignItems={"center"}>
        <Typography minWidth={"160px"}>Корр./счет:</Typography>
        <Typography>
          {contractor?.corrAccount || <EmptyText component={"span"}>данные отсутствуют</EmptyText>}
        </Typography>
      </Stack>
      <Stack direction={"row"} alignItems={"center"}>
        <Typography minWidth={"160px"}>БИК:</Typography>
        <Typography>
          {contractor?.bic || <EmptyText component={"span"}>данные отсутствуют</EmptyText>}
        </Typography>
      </Stack>
      {contractor?.type?.code === ContractorTypesEnum.OOO && (
        <>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography minWidth={"160px"}>ОГРН:</Typography>
            <Typography>
              {contractor?.companyDetails?.ogrn || (
                <EmptyText component={"span"}>данные отсутствуют</EmptyText>
              )}
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography minWidth={"160px"}>Юридический адрес:</Typography>
            <Typography>
              {contractor?.companyDetails?.businessAddress || (
                <EmptyText component={"span"}>данные отсутствуют</EmptyText>
              )}
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography minWidth={"160px"}>КПП:</Typography>
            <Typography>
              {contractor?.companyDetails?.kpp || (
                <EmptyText component={"span"}>данные отсутствуют</EmptyText>
              )}
            </Typography>
          </Stack>
        </>
      )}
      {contractor?.type?.code === ContractorTypesEnum.OTHER && (
        <>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography minWidth={"160px"}>ОГРН:</Typography>
            <Typography>
              {contractor?.otherDetails?.ogrn || (
                <EmptyText component={"span"}>данные отсутствуют</EmptyText>
              )}
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography minWidth={"160px"}>Юридический адрес:</Typography>
            <Typography>
              {contractor?.otherDetails?.businessAddress || (
                <EmptyText component={"span"}>данные отсутствуют</EmptyText>
              )}
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography minWidth={"160px"}>КПП:</Typography>
            <Typography>
              {contractor?.otherDetails?.kpp || (
                <EmptyText component={"span"}>данные отсутствуют</EmptyText>
              )}
            </Typography>
          </Stack>
        </>
      )}
      {(contractor?.type.code === ContractorTypesEnum.IP ||
        contractor?.type.code === ContractorTypesEnum.IP_ARBIN) && (
        <>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography minWidth={"160px"}>ОГРНИП:</Typography>
            <Typography>
              {contractor?.ipDetails?.ogrnip || (
                <EmptyText component={"span"}>данные отсутствуют</EmptyText>
              )}
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography minWidth={"160px"}>Адрес регистрации:</Typography>
            <Typography>
              {contractor?.ipDetails?.registrationAddress || (
                <EmptyText component={"span"}>данные отсутствуют</EmptyText>
              )}
            </Typography>
          </Stack>
        </>
      )}
      {contractor?.type?.code === ContractorTypesEnum.SELF_EMPLOYED && (
        <Stack direction={"row"} alignItems={"center"}>
          <Typography minWidth={"160px"}>Адрес регистрации:</Typography>
          <Typography>
            {contractor?.selfEmployedDetails?.registrationAddress || (
              <EmptyText component={"span"}>данные отсутствуют</EmptyText>
            )}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
});
