import { Box, CircularProgress, Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { AsideCardLayout } from "../../../../../../components/AsideCardLayout/AsideCardLayout";
import { TableDefault } from "../../../../../../components/Table/TableDefault";
import createDocumentStore from "../../../../../../store/createDocument.store";
import { getContractsFields } from "./shared/dataList";
import ContractsList from "./ui/ContractsList/ContractsList";

const ContractsTable: FC = observer(() => {
  return (
    <Box
      sx={{
        gridColumnStart: 1,
        gridColumnEnd: { xs: "auto", md: 3 },
        maxWidth: { xs: "calc(100vw - 32px)", sm: "calc(100vw - 70px - 32px)", md: "100%" },
      }}>
      <AsideCardLayout headerTitle="Договоры" showDivider={false}>
        {createDocumentStore.isLoading ? (
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ height: "100%" }}>
            <CircularProgress sx={{ minHeight: 100, minWidth: 100 }} />
          </Stack>
        ) : (
          <TableDefault
            showBorderRadius={false}
            tableRow={getContractsFields()}
            sx={{ mt: 1 }}
            maxHeight={"calc(100vh - 155px)"}>
            <ContractsList />
          </TableDefault>
        )}
      </AsideCardLayout>
    </Box>
  );
});

export default ContractsTable;
