import { FC, useState } from "react";
import { AlertModal } from "../../../../../../../../../../../../components/AlertModal/AlertModal";
import { RemoveItemButton } from "../../../../../../../../../../../../components/UI/Buttons/RemoveItemButton/RemoveItemButton";
import IContractDeleteBtn from "./IContractDeleteBtn";

const ContractDeleteBtn: FC<IContractDeleteBtn> = (props) => {
  const { onDelete, subtext } = props;

  const [isShowAlert, setIsShowAlert] = useState(false);

  const confirmDelete = () => {
    onDelete().finally(() => {
      setIsShowAlert(false);
    });
  };

  return (
    <>
      <RemoveItemButton sx={{ flex: 1 }} onClick={() => setIsShowAlert(true)} />
      {isShowAlert && (
        <AlertModal
          open={isShowAlert}
          title={"Внимание!"}
          subtext={subtext}
          onSubmit={confirmDelete}
          onClose={() => setIsShowAlert(false)}
          colorSubmit="error"
          buttonCancelName="Отмена"
          buttonSubmitName="Удалить"
        />
      )}
    </>
  );
};

export default ContractDeleteBtn;
