import { FC, useEffect, useState } from "react";
import { Formik } from "formik";
import createContrActorStore from "../../../../../../store/createContrActor.store";
import { validationSchema } from "./models/validationSchema";
import { IContrActorCreateForm } from "./IContrActorCreateForm";
import { FormFields } from "../FormFields/FormFieldts";

export const ContrActorCreateForm: FC<IContrActorCreateForm> = (props) => {
  const {
    initialValues,
    onSubmit,
    onFormReady,
    onValidityChange,
    onFormReset,
    sxFields,
    isEdit,
    onFormDirty,
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadOptions = async () => {
      setIsLoading(true);
      try {
        await createContrActorStore.getContractorOperatorOptions();
        await createContrActorStore.getContractorTypeOptions();
      } finally {
        setIsLoading(false);
      }
    };

    loadOptions();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      validateOnMount={true}>
      <FormFields
        onFormReady={onFormReady}
        onFormDirty={onFormDirty}
        onValidityChange={onValidityChange}
        isLoading={isLoading}
        onFormReset={onFormReset}
        sxFields={sxFields}
        isEdit={isEdit}
      />
    </Formik>
  );
};
