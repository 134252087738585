import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { ContrActorAsideCardComments } from "../../Main/ui/ContrActorAsideCardComments";
import createDocumentStore from "../../../../../store/createDocument.store";
import IContrActorGridLayout from "./IContrActorGridLayout";

const ContrActorGridLayout: FC<IContrActorGridLayout> = observer((props) => {
  const { data, children } = props;

  return (
    <Box sx={{ height: "100%", overflowY: "auto", overflowX: "hidden" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "100%",
            lg: `${createDocumentStore.chatOpen ? "66% 32.5%" : "93.5% 5%"}`,
          },
          justifyContent: "space-between",
          columnGap: 2,
          rowGap: 2,
          transition: "all 0.3s ease",
        }}>
        <Box
          sx={{
            display: "grid",
            justifyContent: "space-between",
            gridTemplateColumns: {
              xs: "100%",
              md: "50% 48%",
            },
            columnGap: 2,
            rowGap: 2,
          }}>
          {children}
        </Box>
        {data?.agent.id && (
          <ContrActorAsideCardComments
            posted_comments={data?.agent?.posted_comments}
            id={data?.agent?.id}
          />
        )}
      </Box>
    </Box>
  );
});

export default ContrActorGridLayout;
