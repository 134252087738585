import { FormControlLabel, FormGroup, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { purple_color } from "../../../../../../app/styles/variables";

interface ICreateDocumentRadioGroup {
  value: boolean;
  onChange: ((event: React.ChangeEvent<HTMLInputElement>, value: string) => void) | undefined;
  name: string;
  title: string;
  defaultValue: boolean;
}

export const CreateDocumentRadioGroup: FC<ICreateDocumentRadioGroup> = (props) => {
  const { value, onChange, name, title, defaultValue } = props;
  return (
    <Stack alignItems={"center"} direction={"row"}>
      <Typography minWidth={"200px"}>{title}:</Typography>
      <FormGroup>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={defaultValue}
          name={name}
          row
          value={value}
          onChange={onChange}>
          <FormControlLabel
            value={true}
            control={<Radio />}
            label={<Typography sx={{ "&:hover": { color: purple_color } }}>Да</Typography>}
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label={<Typography sx={{ "&:hover": { color: purple_color } }}>Нет</Typography>}
          />
        </RadioGroup>
      </FormGroup>
    </Stack>
  );
};
