import {IChannelsContr, IIntegrationsContr} from "../../../../../services/contractor/IContractorIResponse";
import {
    IAsideTableStatisticField
} from "../../../../TableAsidePage/ui/AsideTableStatistic/models/IAsideTableStatisticField";
import {IStrategyField} from "../../../../../strategy/interface/IStrategyField";
import dayjs from "dayjs";

export const getFieldChannelsContr= (data:IChannelsContr):IAsideTableStatisticField[] => {

    const fields:IAsideTableStatisticField[] = [
        {code:'id', value:data.id,},
        {code:'channel_name', value:data},
    ]

    return fields
}
export const getChannelContr = ():IStrategyField[] => {
    const channelContrRow:IStrategyField[] = [
        {title:'ID ', code:'id', immutable:false, isSimple:true, maxWidth: '15px', minWidth:'15px'},
        {title:'Канал', code:'channel_name', immutable:false, isSimple:false, isContrActorChannel: true},
    ]

    return channelContrRow
}

export const getFieldIntegrationContr= (data:IIntegrationsContr):IAsideTableStatisticField[] => {
    const attachment = data?.payment?.contractBlock?.attachment &&
        `Приложение № ${data?.payment?.contractBlock?.attachment?.number} от ${dayjs(data?.payment?.contractBlock?.attachment?.date).format('DD.MM.YYYY')} г.`
    const contractPart = data?.payment?.contractBlock?.contract &&
    ` ${data?.payment?.contractBlock?.attachment ?'к ' : ''}Договор${data?.payment?.contractBlock?.attachment ?'у' : ''} № ${data?.payment?.contractBlock?.contract.number} от ${dayjs(data?.payment?.contractBlock?.contract.date).format('DD.MM.YYYY')} г.`
    const contract = attachment && contractPart ? attachment + contractPart : contractPart ? contractPart : '—'
    const fields:IAsideTableStatisticField[] = [
        {code:'contr_integration_id', value:data?.id,},
        {code:'contr_integration_promo', value:`${data?.promokod?.code}`},
        {code:'contr_integration_payment_id', value:`${data?.payment?.id}`},
        {code:'contr_integration_payment_statusCode',value:data?.payment?.status},
        {code:'contr_integration_contract', value: contract},
    ]

    return fields
}

export const getIntegrationContr = ():IStrategyField[] =>{
    const integrationContrRow:IStrategyField[] = [
        {title:'ID ', code:'contr_integration_id', immutable:false, isSimple:true, maxWidth: '30px', minWidth:'30px'},
        {title:'Промокод', code:'contr_integration_promo', immutable:false, isSimple:true},
        {title:'ID оплаты', code:'contr_integration_payment_id', immutable:false, isSimple:true, maxWidth: '35px'},
        {title:'Статус оплаты', code:'contr_integration_payment_statusCode', immutable:false, isSimple:false, paymentStatusReadOnly: true, maxWidth: '84px', minWidth: '84px'},
        {title:'Договор', code:'contr_integration_contract', immutable:false, isSimple:true, maxWidth: '300px', minWidth:'300px'},
    ]

    return integrationContrRow
}