import { FC } from "react";
import { AccordionWrapper } from "../../../../../../components/AccordionWrapper/AccordionWrapper";
import { AsideCardLayout } from "../../../../../../components/AsideCardLayout/AsideCardLayout";
import ICreateDocumentAccordion from "./ICreateDocumentAccordion";

const CreateDocumentAccordion: FC<ICreateDocumentAccordion> = (props) => {
  const { children, title, isOpen, setIsOpen } = props;
  return (
    <AccordionWrapper
      title={title}
      sxPropsAccordSummary={{ backgroundColor: "white" }}
      sxAccordionDetails={{ pt: 0, pb: 0 }}
      sxPropsAccord={{ boxShadow: "0px 0px 8px 0px rgba(34, 60, 80, 0.2)" }}
      showDivider={false}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      externalControl>
      <AsideCardLayout sx={{ borderTopLeftRadius: "0", borderTopRightRadius: "0" }}>
        {children}
      </AsideCardLayout>
    </AccordionWrapper>
  );
};

export default CreateDocumentAccordion;
