import { FC, useEffect, useState } from "react"
import { Box, Skeleton } from "@mui/material"
import { observer } from "mobx-react-lite"
import presetFiltersStore from "../../../store/presetFilters.store"
import { FilterPresetItem } from "../../Filters/UI/FilterPresetItem/FilterPresetItem"
import userStore from "../../../store/user.store"
import { IReferencesPresetPropsRequest } from "../../../services/references/IReferencesRequest"
import ReferencesService from "../../../services/references/references.service"
import { AxiosError } from "axios"
import integrationTableStore from "../../../store/integrationTable.store"
import { LS_TAKE_REQUEST_INTEGRATION } from "../../../shared/constants/localstorage"
import { IIntegrationsRequest, includeInSelect } from "../../../services/integration/IntegrationsRequest"
import { getFilterIntegrationQuery } from "../shared/dataList"
import IntegrationsService from "../../../services/integration/integrations.service"
import { checkRemovePresetRole } from "../../ChannelTable/ui/ChannelFiter/shared/functions"
import { IPresetList } from "../models/IPresetList"

import allFilterListJson from '../../../shared/mock/filters.mock.json'

export const IntegrationPresetList:FC<IPresetList> = observer((props) => {
  const {onFilterClose} = props

  const [isLoading, setLoading] = useState(false)

  const getFiltersTableItems = (urlQuery:URLSearchParams) => {
    integrationTableStore.setLoading(true)

    const take = localStorage.getItem(LS_TAKE_REQUEST_INTEGRATION) ? localStorage.getItem(LS_TAKE_REQUEST_INTEGRATION) : 100

    const option: IIntegrationsRequest = {
      company: userStore.currentCompany,
      filterQuery: getFilterIntegrationQuery(urlQuery),
      includeInSelect: integrationTableStore.allFilter as includeInSelect[],
      page: 1,
      take: +`${take}`,
    }

    IntegrationsService.getIntegrations(option)
      .then((res) => {
        // console.log(res, "res confirm filters")
        const allSearchParamsUrl:string[] = []

        for (let value of urlQuery.keys() as any) {
          allSearchParamsUrl.push(value)
        }

        const filtersPreset:any[] = allFilterListJson.integrationAllFilters.filter(x=>allSearchParamsUrl.includes(x.searchUrl))
        integrationTableStore.setIntegrationPresetFilters(filtersPreset)

        const allFiltersPreset:any[] = allFilterListJson.integrationAllFilters.map(x=>{
          if(allSearchParamsUrl.includes(x.searchUrl)){
            return {...x, isSelected:true}
          } else return {...x, isSelected:false}
        })
        integrationTableStore.setIntegrationPresetAllFilters(allFiltersPreset)

        integrationTableStore.setPage(1)
        integrationTableStore.setTableItems(res.data.items)
        integrationTableStore.setTotalCount(res.data.totalCount)
        integrationTableStore.setLoading(false)
        onFilterClose()
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, "err filter confirm")
          integrationTableStore.setLoading(false)
        }
      })
  }

  const getIntegrationsPreset = () => {
    if (userStore.user) {
      setLoading(true)

      const option: IReferencesPresetPropsRequest = {
        entity: "integration",
        userId: userStore.user?.id,
      }

      ReferencesService.getPresetWithProps(option)
        .then((res) => {
          // console.log(res, "res integr preset")
          presetFiltersStore.setAllPresetIntegrations(res.data)
          setLoading(false)
        })
        .catch((err) => {
          if (err instanceof AxiosError) {
            // console.log(err, "err integr preset")
            setLoading(false)
          }
        })
    }
  }

  useEffect(() => {
    if(Array.isArray(presetFiltersStore.presetIntegrations) === false){
      getIntegrationsPreset()
    }
  }, [])

  return (
    <Box>
      {!isLoading && (
        <Box sx={{display:'grid', columnGap:1, rowGap:1, gridTemplateColumns:'repeat(auto-fill, minmax(125px, max-content))',ml:'30px'}}>
          {presetFiltersStore.presetIntegrations &&
            presetFiltersStore.presetIntegrations.map((item) => (
              <FilterPresetItem
                key={item.id}
                {...item}
                onClick={getFiltersTableItems} 
                onDelete={presetFiltersStore.deletePresetIntegrations}
                readOnly={!checkRemovePresetRole(item.user)}
              />
            ))}
        </Box>
      )}
      {isLoading && presetFiltersStore.presetIntegrations?.length === 0 &&
       <Skeleton variant="rounded" />
      }
    </Box>
  )
})