import { AxiosResponse } from "axios";
import $gateway_api from "../../shared/api/gatewayAxios";
import { IReleasesRequestEdit, IReleasesValidateViews } from "./IReleasesRequest";
import { IReleasesResponseEdit } from "./IReleasesResponse";

export default class ReleasesService {
  static removeReleaes(id: number): Promise<AxiosResponse> {
    return $gateway_api.post("/api/releases/remove", { id });
  }

  static releaesEdit(option: IReleasesRequestEdit): Promise<AxiosResponse<IReleasesResponseEdit>> {
    return $gateway_api.post("/api/releases/edit", option);
  }

  static releaseValidateViews(
    option: IReleasesValidateViews
  ): Promise<AxiosResponse<Boolean>> {
    return $gateway_api.post("api/releases/validate-views-edit", option);
  }
}
