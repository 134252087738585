import { Stack } from "@mui/material";
import { Field, Form, useFormikContext } from "formik";
import { FC, useEffect } from "react";
import { ContrActorCreateSelect } from "../ContrActorCreateSelect/ContrActorCreateSelect";
import createContrActorStore from "../../../../../../store/createContrActor.store";
import { IContrActorCreate } from "../../../../../../services/contractor/IContractorIRequest";
import { ContrActorCreateField } from "../ContrActorCreateField/ContrActorCreateField";
import { ContractorTypesEnum } from "../../../../../../entities/Contractor/models/ContractorTypesEnum";
import { ContrActorCreateRadioField } from "../ContrActorCreateRadioField/ContrActorCreateRadioField";
import { IFormFields } from "./IFormFields";

export const FormFields: FC<IFormFields> = (props) => {
  const {
    onFormReady,
    onValidityChange,
    onFormReset,
    isLoading,
    sxFields,
    onFormDirty,
    isEdit = false,
  } = props;

  const {
    values,
    isValid,
    handleSubmit,
    setFieldValue,
    resetForm,
    validateForm,
    touched,
    errors,
    handleChange,
    setFieldTouched,
    validateField,
    dirty,
  } = useFormikContext<IContrActorCreate>(); // Доступ к контексту формы

  const disabled = values.typeCode === "";

  const handleNumberFieldChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    const newValue = value.replace(/\D/g, "");
    setFieldValue(name, newValue);
    validateField(name);
  };

  const handleFocus = (e: React.ChangeEvent<any>) => {
    const { name } = e.target;
    setFieldTouched(name, true);
  };

  useEffect(() => {
    onFormReady(handleSubmit);
    if (onValidityChange) {
      onValidityChange(isValid);
    }
    if (onFormReset) {
      onFormReset(resetForm);
    }

    if (onFormDirty) {
      onFormDirty(dirty);
    }
  }, [
    onFormReady,
    onValidityChange,
    isValid,
    handleSubmit,
    onFormReset,
    resetForm,
    onFormDirty,
    dirty,
  ]);

  return (
    <Form>
      <Stack direction={"column"} spacing={2} p={3} sx={{ ...sxFields }}>
        <Field
          component={ContrActorCreateSelect}
          isLoading={isEdit ? null : isLoading}
          name="typeCode"
          required
          title="Тип контрагента"
          options={createContrActorStore.contractorTypeOptions}
          value={values.typeCode || ""}
          onChange={(e: React.ChangeEvent<any>) => {
            resetForm();
            setFieldValue(e.target.name, e.target.value);
          }}
          disabled={isLoading || isEdit}
        />
        <Field
          component={ContrActorCreateField}
          name="name"
          title={
            values.typeCode !== ContractorTypesEnum.OOO &&
            values.typeCode !== ContractorTypesEnum.OTHER
              ? "ФИО"
              : "Название"
          }
          required
          placeholder={
            values.typeCode !== ContractorTypesEnum.OOO &&
            values.typeCode !== ContractorTypesEnum.OTHER
              ? "Введите ФИО"
              : "Введите название"
          }
          onChange={handleChange}
          value={values.name}
          disabled={disabled}
          onFocus={handleFocus}
        />
        <Field
          component={ContrActorCreateField}
          name="inn"
          title="ИНН"
          required
          type="text"
          placeholder="Введите ИНН"
          onChange={(e: React.ChangeEvent<any>) => {
            handleNumberFieldChange(e);
            validateField("inn");
          }}
          value={values.inn}
          disabled={disabled}
          onFocus={handleFocus}
        />
        <Field
          component={ContrActorCreateRadioField}
          name="edo"
          title={"ЭДО"}
          value={values.edo ? "true" : "false"}
          onChange={async (e: React.ChangeEvent<any>) => {
            const parsedValue = e.target.value === "true";
            await setFieldValue("edo", parsedValue);
            await setFieldValue("operatorCode", "");
            validateForm();
          }}
          error={touched.edo && errors.edo}
          required
          disabled={disabled}
          onFocus={handleFocus}
        />
        <Field
          component={ContrActorCreateSelect}
          name="operatorCode"
          required={values.edo}
          title="Оператор"
          options={createContrActorStore.contractorOperatorOptions}
          value={values.operatorCode || ""}
          onChange={handleChange}
          error={touched.operatorCode && !!errors.operatorCode}
          disabled={disabled || !values.edo}
        />
        {(values.typeCode === ContractorTypesEnum.OOO ||
          values.typeCode === ContractorTypesEnum.OTHER) && (
          <Field
            component={ContrActorCreateField}
            name="fio"
            title="ФИО представителя"
            placeholder="Введите ФИО"
            onChange={handleChange}
            value={values.fio}
            disabled={disabled}
            onFocus={handleFocus}
          />
        )}
        {(values.typeCode === ContractorTypesEnum.OOO ||
          values.typeCode === ContractorTypesEnum.OTHER) && (
          <Field
            component={ContrActorCreateField}
            name="directorTitle"
            title="Должность представителя"
            placeholder="Введите значение"
            onChange={handleChange}
            value={values.directorTitle}
            disabled={disabled}
            onFocus={handleFocus}
          />
        )}
        {!isEdit && (
          <Field
            component={ContrActorCreateField}
            name="contact.documentContact"
            title="Контакт по документам"
            placeholder="Введите значение"
            onChange={handleChange}
            value={values.contact?.documentContact}
            disabled={disabled}
            onFocus={handleFocus}
          />
        )}
        <Field
          component={ContrActorCreateField}
          name="bankName"
          title="Наименование банка"
          placeholder="Введите значение"
          onChange={handleChange}
          value={values.bankName}
          disabled={disabled}
          onFocus={handleFocus}
        />
        <Field
          component={ContrActorCreateField}
          name="accountNumber"
          title="Расчетный счет"
          type="text"
          placeholder="Введите значение"
          onChange={handleNumberFieldChange}
          value={values.accountNumber}
          disabled={disabled}
          onFocus={handleFocus}
        />
        <Field
          component={ContrActorCreateField}
          name="corrAccount"
          title="Корр./счет"
          type="text"
          placeholder="Введите значение"
          onChange={handleNumberFieldChange}
          value={values.corrAccount}
          disabled={disabled}
          onFocus={handleFocus}
        />
        <Field
          component={ContrActorCreateField}
          name="bic"
          title="БИК"
          type="text"
          placeholder="Введите значение"
          onChange={handleNumberFieldChange}
          value={values.bic}
          disabled={disabled}
          onFocus={handleFocus}
        />
        {values.typeCode === ContractorTypesEnum.IP ||
        values.typeCode === ContractorTypesEnum.IP_ARBIN ? (
          <Field
            component={ContrActorCreateField}
            name="ipDetails.ogrnip"
            title="ОГРНИП"
            type="text"
            placeholder="Введите значение"
            onChange={handleNumberFieldChange}
            value={values.ipDetails?.ogrnip}
            onFocus={handleFocus}
          />
        ) : values.typeCode === ContractorTypesEnum.OOO ? (
          <Field
            component={ContrActorCreateField}
            name="companyDetails.ogrn"
            title="ОГРН"
            type="text"
            placeholder="Введите значение"
            onChange={handleNumberFieldChange}
            value={values.companyDetails?.ogrn}
            onFocus={handleFocus}
          />
        ) : values.typeCode === ContractorTypesEnum.OTHER && (
          <Field
            component={ContrActorCreateField}
            name="otherDetails.ogrn"
            title="ОГРН"
            type="text"
            placeholder="Введите значение"
            onChange={handleNumberFieldChange}
            value={values.otherDetails?.ogrn}
            onFocus={handleFocus}
          />
        )}
        {values.typeCode !== ContractorTypesEnum.OOO &&
        values.typeCode !== ContractorTypesEnum.OTHER ? (
          <Field
            component={ContrActorCreateField}
            name={
              values.typeCode === ContractorTypesEnum.SELF_EMPLOYED
                ? "selfEmployedDetails.registrationAddress"
                : "ipDetails.registrationAddress"
            }
            title="Адрес регистрации"
            placeholder="Введите значение"
            value={
              values.typeCode === ContractorTypesEnum.SELF_EMPLOYED
                ? values.selfEmployedDetails?.registrationAddress
                : values.ipDetails?.registrationAddress
            }
            onChange={handleChange}
            disabled={disabled}
            onFocus={handleFocus}
          />
        ) : values.typeCode === ContractorTypesEnum.OOO ? (
          <Field
            component={ContrActorCreateField}
            name="companyDetails.businessAddress"
            title="Юридический адрес"
            placeholder="Введите значение"
            value={values.companyDetails?.businessAddress}
            onChange={handleChange}
            onFocus={handleFocus}
          />
        ) : values.typeCode === ContractorTypesEnum.OTHER && (
          <Field
            component={ContrActorCreateField}
            name="otherDetails.businessAddress"
            title="Юридический адрес"
            placeholder="Введите значение"
            value={values.otherDetails?.businessAddress}
            onChange={handleChange}
            onFocus={handleFocus}
          />
        )}
        {values.typeCode === ContractorTypesEnum.OOO ? (
          <Field
            component={ContrActorCreateField}
            name="companyDetails.kpp"
            title="КПП"
            type="text"
            placeholder="Введите значение"
            value={values.companyDetails?.kpp}
            onChange={handleNumberFieldChange}
            onFocus={handleFocus}
          />
        ) : values.typeCode === ContractorTypesEnum.OTHER && (
          <Field
            component={ContrActorCreateField}
            name="otherDetails.kpp"
            title="КПП"
            type="text"
            placeholder="Введите значение"
            value={values.otherDetails?.kpp}
            onChange={handleNumberFieldChange}
            onFocus={handleFocus}
          />
        )}
      </Stack>
    </Form>
  );
};
