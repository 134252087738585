import { makeAutoObservable, toJS } from "mobx";
import {
  IContrActorEdit,
  IContrActorEditContacts,
  IContrActorEditContactsRequest,
} from "../services/contractor/IContractorIRequest";
import { IAgent, IChannelsContr } from "../services/contractor/IContractorIResponse";
import ContractorService from "../services/contractor/contractor.service";
import contrActorTableStore from "./contrActorTable.store";
import createContrActorStore from "./createContrActor.store";

class EditContrActorStore {
  agent: IAgent | null = null;
  editableContractor: IContrActorEdit | null = null;
  contacts: IContrActorEditContactsRequest | null = null;
  channels: IChannelsContr[] | [] = [];
  success: boolean = false;
  error: boolean = false;
  successMessage: string = "";
  errorMessage: string = "";
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setAgent(agent: IAgent) {
    this.agent = agent;
    // Когда мы устанавливаем агента, сразу маппим его на editableContractor
    this.setEditableContractor(this.mapAgentToContrActor(agent));
  }

  setContacts(id: number, contacts: IContrActorEditContacts) {
    this.contacts = {
      counteragentId: id,
      documentContact: contacts?.documentContact || "",
      email: contacts?.email || "",
      phone: contacts?.phone || "",
      telegram: contacts?.telegram || "",
      additionalContact: contacts?.additionalContact || "",
    };
  }

  setChannels(channels: IChannelsContr[]) {
    this.channels = channels;
  }

  setEditableContractor(contractor: IContrActorEdit) {
    this.editableContractor = contractor;
  }

  editContractor(values: IContrActorEdit) {
    this.setIsLoading(true);
    const clearedValues = createContrActorStore.cleanFormValues(values);
    return ContractorService.edit(clearedValues)
      .then((res) => {
        const mappedObj = {
          id: res.data.id,
          counteragent_edo: res.data.edo === true ? "Есть" : "Нет",
          counteragent_inn: res.data.inn,
          counteragent_name: res.data.name,
          counteragent_type: res.data.type,
          counteragent_operator: res.data.operator?.name,
          counteragent_shortName: res.data.shortName,
          counteragent_createdAt: res.data.createdAt,
          counteragent_createdBy: res.data.createdBy,
        };
        contrActorTableStore.editContrActor(res.data.id, mappedObj);
        this.setAgent(res.data);
        this.setSuccess(true);
        this.setSuccessMessage("Контрагент успешно отредактирован!");
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorMessage(
          `Ошибка при редактировании контрагента: "${err.response.data.message}"`
        );
      })
      .finally(() => this.setIsLoading(false));
  }

  editContractorContacts(values: IContrActorEditContactsRequest) {
    this.setIsLoading(true);
    return ContractorService.editContacts(values)
      .then((res) => {
        this.setContacts(res.data.id, res.data.contact);
        this.setSuccess(true);
        this.setSuccessMessage("Контакты успешно обновлены!");
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorMessage(`Ошибка при редактировании контактов: "${err.response.data.message}"`);
      })
      .finally(() => this.setIsLoading(false));
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setSuccess(value: boolean) {
    this.success = value;
  }

  setSuccessMessage(message: string) {
    this.successMessage = message;
  }

  setError(value: boolean) {
    this.error = value;
  }

  setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  // Функция для маппинга IAgent в IContrActorEdit
  private mapAgentToContrActor(agent: IAgent): IContrActorEdit {
    const {
      id,
      name,
      shortName,
      inn,
      edo,
      operator,
      type,
      contractLink,
      contact,
      companyDetails,
      otherDetails,
      ipDetails,
      selfEmployedDetails,
      bankName,
      accountNumber,
      corrAccount,
      bic,
      createdAt,
      createdBy,
      fio,
      directorTitle,
    } = agent;

    return {
      id: id ?? "",
      name: name ?? "",
      shortName: shortName ?? "",
      inn: inn ?? "",
      edo: edo ?? false,
      operatorCode: operator?.code ?? "",
      fio: fio ?? "",
      directorTitle: directorTitle ?? "",
      typeCode: type?.code ?? "",
      contractLink: contractLink ?? "",
      contact: {
        documentContact: contact?.documentContact ?? "",
      },
      companyDetails: {
        ogrn: companyDetails?.ogrn ?? "",
        kpp: companyDetails?.kpp ?? "",
        businessAddress: companyDetails?.businessAddress ?? "",
      },
      otherDetails: {
        ogrn: otherDetails?.ogrn ?? "",
        kpp: otherDetails?.kpp ?? "",
        businessAddress: otherDetails?.businessAddress ?? "",
      },
      ipDetails: {
        ogrnip: ipDetails?.ogrnip ?? "",
        registrationAddress: ipDetails?.registrationAddress ?? "",
      },
      selfEmployedDetails: {
        registrationAddress: selfEmployedDetails?.registrationAddress ?? "",
      },
      bankName: bankName ?? "",
      accountNumber: accountNumber ?? "",
      corrAccount: corrAccount ?? "",
      bic: bic ?? "",
      createdAt: createdAt ?? "",
      createdBy: createdBy ?? "",
    };
  }

  getLog() {
    console.log(toJS(this.editableContractor));
  }
}

export default new EditContrActorStore();
