import { FormControl, SelectChangeEvent, Stack } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react-lite";
import { ChangeEvent, FC, useState } from "react";
import { CustomButton } from "../../../../../../components/UI/Buttons/CustomButton/CustomButton";
import { IContractorCreateAttachment } from "../../../../../../services/contractor/IContractorIRequest";
import channelStore from "../../../../../../store/channel.store";
import createDocumentStore from "../../../../../../store/createDocument.store";
import { getSocialTypeInSelect } from "../../../../../CreateChannelPage/shared/dataList";
import CreateDocumentAccordion from "../CreateDocumentAccordion/CreateDocumentAccordion";
import CreateDocumentDatePicker from "../CreateDocumentDatePicker/CreateDocumentDatePicker";
import CreateDocumentField from "../CreateDocumentField/CreateDocumentField";
import CreateDocumentSelect from "../CreateDocumentSelect/CreateDocumentSelect";
import { PlacementCharacterField } from "../PlacementCharacterField/PlacementCharacterField";
import { CreateDocumentRadioGroup } from "../CreateDocumentRadioGroup/CreateDocumentRadioGroup";
import { formatNumberWithSpaces } from "../CreateDocumentField/shared/functions";

const CreateAttachment: FC = observer(() => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [formattedCost, setFormattedCost] = useState<string>("");

  const handleChangeCreationMethod = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    createDocumentStore.setOurAttachment(value === "true");
    validateRequired();
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    createDocumentStore.setAttachmentField(name as keyof IContractorCreateAttachment, value);
    validateRequired();
  };

  const handleServiceCostChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    const rawValue = value.replace(/\D/g, "");
    const formatted = formatNumberWithSpaces(rawValue);
    setFormattedCost(formatted);

    createDocumentStore.setAttachmentField(name as keyof IContractorCreateAttachment, +rawValue);
    validateRequired();
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;

    if (name === "channelType") {
      createDocumentStore.setDefaultPlacementCharacter(value);
    }

    createDocumentStore.setAttachmentField(name as keyof IContractorCreateAttachment, value);
    validateRequired();
  };

  const handleDateChange = (date: Dayjs | null, name?: string) => {
    if (date && date.isValid()) {
      createDocumentStore.setAttachmentField(name as keyof IContractorCreateAttachment, date);
    } else {
      createDocumentStore.setAttachmentField(name as keyof IContractorCreateAttachment, "");
    }
    validateRequired();
  };

  const validateRequired = () => {
    const isFormValid = Object.entries(createDocumentStore.attachment).every(([key, value]) => {
      if (dayjs.isDayjs(value)) {
        return value.isValid();
      }

      if (
        (key === "link" ||
          key === "channelType" ||
          key === "placementCharacter" ||
          key === "channelLink" ||
          key === "placementDateUntil" ||
          key === "paymentDate") &&
        !createDocumentStore.ourAttachment
      ) {
        return true;
      }

      if (typeof value === "string") {
        return value.length > 0;
      }

      if (typeof value === "number") {
        return value !== 0;
      }

      return !!value;
    });

    setIsFormValid(isFormValid);
  };

  const createAttachment = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    createDocumentStore.createAttachment().then(() => {
      setIsFormValid(false);
      setIsOpen(false);
      setFormattedCost("");
    });
  };

  const contractsOptions = createDocumentStore.contractsOptions;

  return (
    <CreateDocumentAccordion title="Создание приложения" isOpen={isOpen} setIsOpen={setIsOpen}>
      <FormControl onSubmit={createAttachment} component={"form"} fullWidth>
        <Stack direction={"column"} spacing={2}>
          <CreateDocumentRadioGroup
            name=""
            title="Мы создаем приложение"
            value={createDocumentStore.ourAttachment}
            onChange={handleChangeCreationMethod}
            defaultValue={true}
          />
          <CreateDocumentSelect
            name="contractId"
            onChange={handleSelectChange}
            options={contractsOptions}
            value={
              createDocumentStore.attachment.contractId.toString() === "0"
                ? ""
                : createDocumentStore.attachment.contractId.toString()
            }
            title="Приложение к договору №"
            required
            placeholder="Выберите номер договора"
          />
          <CreateDocumentField
            name="attachmentNumber"
            title="№ приложения"
            onChange={handleChange}
            placeholder="Введите номер приложения"
            value={createDocumentStore.attachment.attachmentNumber}
            required
          />
          <CreateDocumentDatePicker
            name="attachmentDate"
            title="Дата приложения"
            onChange={handleDateChange}
            placeholder="дд.мм.гггг"
            required
            value={createDocumentStore.attachment.attachmentDate}
          />
          {createDocumentStore.ourAttachment && (
            <>
              {channelStore.channels && (
                <CreateDocumentSelect
                  name="channelType"
                  onChange={handleSelectChange}
                  content={getSocialTypeInSelect(channelStore.channels)}
                  required
                  value={createDocumentStore.attachment.channelType}
                  placeholder="Выберите тип канала"
                  title="Тип канала"
                />
              )}
              <PlacementCharacterField onChange={handleChange} name="placementCharacter" />
              <CreateDocumentField
                name="channelLink"
                title="Ссылка на канал"
                onChange={handleChange}
                placeholder="Вставьте ссылку на канал"
                required
                value={createDocumentStore.attachment.channelLink}
              />
              <CreateDocumentDatePicker
                name="placementDateUntil"
                title="Дата размещения до"
                placeholder="дд.мм.гггг"
                required
                onChange={handleDateChange}
                value={createDocumentStore.attachment.placementDateUntil}
              />
            </>
          )}
          <CreateDocumentField
            name="serviceCost"
            title="Стоимость услуг цифрами"
            onChange={handleServiceCostChange}
            placeholder="Введите стоимость услуг цифрами"
            required
            inputProps={{ maxLenght: 10, max: 999999999 }}
            type="text"
            value={formattedCost}
          />
          <CreateDocumentField
            name="serviceCostInWords"
            title="Стоимость услуг прописью"
            onChange={handleChange}
            placeholder="Введите стоимость услуг прописью"
            required
            value={createDocumentStore.attachment.serviceCostInWords}
          />
          {createDocumentStore.ourAttachment ? (
            <CreateDocumentDatePicker
              name="paymentDate"
              title="Дата оплаты"
              required
              onChange={handleDateChange}
              placeholder="дд.мм.гггг"
              value={createDocumentStore.attachment.paymentDate}
            />
          ) : (
            <CreateDocumentField
              name="link"
              title="Ссылка на приложение"
              onChange={handleChange}
              placeholder="Введите ссылку"
              value={createDocumentStore.attachment.link || ""}
            />
          )}

          <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>
            <CustomButton
              sx={{ mt: 1 }}
              variant={"contained"}
              type="submit"
              disabled={!isFormValid}>
              Создать приложение
            </CustomButton>
          </Stack>
        </Stack>
      </FormControl>
    </CreateDocumentAccordion>
  );
});

export default CreateAttachment;
