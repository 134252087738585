import {FC} from 'react';
import {IIntegrationContrTable} from "./IIntegrationContrTable";
import {
    getFieldIntegrationContr,
    getIntegrationContr
} from "../../shared/dataList";
import {TableDefault} from "../../../../../../components/Table/TableDefault";
import IntegrationContrTableItem from "./IntegrationContrTableItem";
import {Stack} from "@mui/material";

const IntegrationContrTable:FC<IIntegrationContrTable> = (props) => {
    const {data} = props
    return (
        <Stack>
            <TableDefault maxWidth='100%' tableRow={getIntegrationContr()} showBorderRadius={false} >
                {
                    data.integrations && data.integrations.map(item =>
                        <IntegrationContrTableItem
                            rows={getIntegrationContr()}
                            dataField={getFieldIntegrationContr(item)}
                        />
                    )
                }
            </TableDefault>
        </Stack>
    );
};

export default IntegrationContrTable;