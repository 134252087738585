import {FC} from 'react';
import {IContractsPage} from "./IContractsPage";
import ContractItem from "./ui/ContractItem/ContractItem";
import {Box, Typography} from "@mui/material";
import {AsideCardLayout} from "../../../../components/AsideCardLayout/AsideCardLayout";

const ContractsPage:FC<IContractsPage> = (props) => {
    const {
        data,
    } = props

    return (
        <Box
            sx={{
                gridColumnStart:1,
                gridColumnEnd:{xs:'auto', md:3},
                maxWidth:{xs:'calc(100vw - 32px)', sm:'calc(100vw - 70px - 32px)', md:'100%'}
            }}>
            {data.length > 0
                ?
                    data.map((item, i) => <ContractItem key={i} data={item}/>)
                :
                <AsideCardLayout>
                    <Typography variant='body1' noWrap sx={{ml:2, mr:2,mt:1}}>В настоящий момент нет договоров</Typography>
                </AsideCardLayout>
            }
        </Box>
    );
};

export default ContractsPage;