import { Check, Clear } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { AsideCardLayout } from "../../../../../../components/AsideCardLayout/AsideCardLayout";
import { ContrActorCreateForm } from "../../../../../../components/ContrActorTable/ui/ContrActorCreateModals/ui/ContrActorCreateForm/ContrActorCreateForm";
import { NotificationAlerts } from "../../../../../../components/NotificationAlerts/NotificationAlerts";
import { CustomIconButton } from "../../../../../../components/UI/Buttons/CustomIconButton/CustomIconButton";
import { EditIconButton } from "../../../../../../components/UI/Buttons/EditIconButton/EditIconButton";
import editContrActorStore from "../../../../../../store/editContrActor.store";
import { ContractorInfo } from "./ui/ContractorInfo/ContractorInfo";

export const BasicInformation: FC = observer(() => {
  const [isEdit, setIsEdit] = useState(false);

  const [submitForm, setSubmitForm] = useState<() => void>(() => {});
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const handleFormReady = (submitFunction: () => void) => {
    setSubmitForm(() => submitFunction);
  };

  const handleValidityChange = (isValid: boolean) => {
    setIsFormValid(isValid);
  };

  const handleFormDirtyChange = (isDirty: boolean) => {
    setIsDirty(isDirty);
  };

  const handleSubmitForm = () => {
    if (!isDirty) {
      setIsEdit(false);
      return;
    }

    submitForm();
    if (isFormValid) {
      setIsEdit(false);
    }
  };

  const initialValues = toJS(editContrActorStore.editableContractor);
  return (
    <AsideCardLayout
      headerTitle="Общая информация"
      rightContent={
        isEdit ? (
          <Stack direction={"row"} alignItems={"center"}>
            <CustomIconButton size="small" onClick={() => setIsEdit(false)}>
              <Clear fontSize="small" />
            </CustomIconButton>
            <CustomIconButton size="small" onClick={handleSubmitForm} showBackground>
              <Check fontSize="small" />
            </CustomIconButton>
          </Stack>
        ) : (
          <Box sx={{ display: "flex" }}>
            <EditIconButton size="small" onClick={() => setIsEdit(true)} />
          </Box>
        )
      }>
      {isEdit ? (
        <ContrActorCreateForm
          initialValues={initialValues}
          onSubmit={(values) => {
            editContrActorStore.editContractor(values);
          }}
          onFormDirty={handleFormDirtyChange}
          onFormReady={handleFormReady}
          onValidityChange={handleValidityChange}
          sxFields={{ padding: "16px 0 0 0", maxWidth: "530px" }}
          isEdit
        />
      ) : (
        <ContractorInfo />
      )}
      <NotificationAlerts
        sucsess={{
          text: editContrActorStore.successMessage,
          open: editContrActorStore.success,
          onClose: () => editContrActorStore.setSuccess(false),
        }}
        error={{
          text: editContrActorStore.errorMessage,
          open: editContrActorStore.error,
          onClose: () => editContrActorStore.setError(false),
        }}
      />
    </AsideCardLayout>
  );
});
