import { FC, useState } from "react";
import { Select, MenuItem, FormControl, Typography, Stack, Box, Avatar } from "@mui/material";
import ICreateDocumentSelect from "./ICreateDocumentSelect";

const CreateDocumentSelect: FC<ICreateDocumentSelect> = (props) => {
  const {
    title,
    name,
    value,
    onChange,
    required = false,
    options,
    placeholder,
    content,
    maxWidth = "250px",
    minWidth,
    disabled,
  } = props;
  const [error, setError] = useState<string | null>(null);

  const handleBlur = () => {
    if (required && !value) {
      setError("Заполните поле");
    } else {
      setError(null);
    }
  };

  const renderValue = (selected: string) => {
    if (!selected && placeholder) {
      return (
        <Typography
          sx={{
            color: "rgba(187, 187, 187, 1)",
            fontSize: 14,
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}>
          {placeholder}
        </Typography>
      );
    } else if (content) {
      const selectedItem = content.find((item) => item.value === selected);

      return (
        <Box display={"flex"} alignItems={"center"}>
          {selectedItem?.iconUrl && (
            <Avatar src={selectedItem.iconUrl} sx={{ height: 20, width: 20, mr: 0.5 }} />
          )}
          <Typography>{selectedItem?.name}</Typography>
        </Box>
      );
    } else if (options) {
      const selectedItem = options.find((item) => item.value === selected);

      return <Typography>{selectedItem?.label}</Typography>;
    }
  };

  return (
    <Stack direction={"row"} alignItems={"center"}>
      {title && (
        <Typography minWidth={"200px"}>
          {title}
          {": "}
          {required ? (
            <Typography component={"span"} color={"error"}>
              *
            </Typography>
          ) : (
            ""
          )}
        </Typography>
      )}
      <FormControl
        variant="outlined"
        size="small"
        fullWidth
        required={required}
        error={!!error}
        sx={{ position: "relative", maxWidth: maxWidth, minWidth: minWidth }}>
        <Select
          name={name}
          value={value}
          onBlur={handleBlur}
          onChange={onChange}
          disabled={disabled}
          displayEmpty
          renderValue={renderValue}>
          {content
            ? content.map((item) => (
                <MenuItem
                  key={item.value}
                  value={item.value}
                  sx={{ display: "flex", alignItems: "center" }}>
                  {item.iconUrl ? (
                    <Avatar src={item.iconUrl} sx={{ height: 20, width: 20, mr: 0.5 }} />
                  ) : null}
                  <Typography>{item.name}</Typography>
                </MenuItem>
              ))
            : options &&
              options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
        </Select>
        {error && (
          <Typography
            variant="caption"
            color="error"
            component={"span"}
            sx={{
              position: "absolute",
              bottom: "-14px",
              left: "14px",
              margin: "0",
            }}>
            {error}
          </Typography>
        )}
      </FormControl>
    </Stack>
  );
};

export default CreateDocumentSelect;
