import { observer } from "mobx-react-lite";
import { FC, Fragment } from "react";
import createDocumentStore from "../../../../../../../../store/createDocument.store";
import ContractListItem from "./ui/ContractListItem/ContractListItem";
import dayjs from "dayjs";

export const ContractsList: FC = observer(() => {
  return (
    <>
      {createDocumentStore.contragentContracts.map((contract) => (
        <Fragment key={contract.id}>
          <ContractListItem
            document={contract}
            lastEl={contract.attachments ? contract.attachments?.length === 0 : true}
            availablePayments={createDocumentStore.availablePaymentsForContract}
          />
          {contract.attachments &&
            contract.attachments?.length !== 0 &&
            [...contract.attachments].reverse().map((attachment, index) => {
              const newAttachment = {
                ...attachment,
                isNDS: contract.isNDS,
                ourMarking: contract.ourMarking,
              };
              return (
                <Fragment key={newAttachment.id}>
                  <ContractListItem
                    document={newAttachment}
                    attachment
                    lastEl={!newAttachment.act && index === contract.attachments.length - 1}
                    contractNumber={contract.number}
                    contractDate={dayjs(contract.date).format("DD.MM.YYYY")}
                    key={newAttachment.id}
                    availablePayments={contract.availablePaymentsForAttachment}
                  />
                  {newAttachment.act &&
                    (() => {
                      const act = {
                        ...newAttachment.act,
                        payment: newAttachment.payment,
                        isNDS: newAttachment.isNDS,
                        ourMarking: newAttachment.ourMarking,
                      };
                      return (
                        <ContractListItem
                          document={act}
                          act
                          lastEl={index === contract.attachments.length - 1}
                          contractNumber={newAttachment.number}
                        />
                      );
                    })()}
                </Fragment>
              );
            })}
        </Fragment>
      ))}
    </>
  );
});

export default ContractsList;
