import { Box } from "@mui/material";
import { IntegrationTable } from "../../components/IntegrationTable/IntegrationTable";
import { IntergationFilterTableSettings } from "../../components/IntegrationTable/ui/IntergationFilterTableSettings";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  CHANNEL_CARD_OPEN,
  CONTRACTOR_CARD_OPEN,
  GOODS_CREATE_PAGE_OPEN,
  INTEGRATION_CARD_OPEN,
  PAYMENT_NUMBER,
  PRODUCT_CARD_OPEN,
} from "../../shared/constants/searchParams";
import { ChannelCardPage } from "../ChannelCardPage/ChannelCardPage";
import { TableAsidePage } from "../TableAsidePage/TableAsidePage";
import integrationTableStore from "../../store/integrationTable.store";
import { PageNotFound } from "../PageNotFound/PageNotFound";
import { GoodFullPage } from "../GoodFullPage/GoodFullPage";
import { GoodsCreatePage } from "../GoodsCreatePage/GoodsCreatePage";
import { ContrActorAsidePage } from "../ContrActorAsidePage/ContrActorAsidePage";

const IntegraionsPage = observer(() => {
  const [searchParams, setSearchParams] = useSearchParams();

  const closeChannel = () => {
    searchParams.delete(CHANNEL_CARD_OPEN);
    setSearchParams(searchParams);
  };

  const integrationChannel = () => {
    searchParams.delete(INTEGRATION_CARD_OPEN);
    setSearchParams(searchParams);
  };

  const closeProduct = () => {
    searchParams.delete(PRODUCT_CARD_OPEN);
    setSearchParams(searchParams);
  };

  const closeContractor = () => {
    searchParams.delete(CONTRACTOR_CARD_OPEN);
    searchParams.delete(PAYMENT_NUMBER);
    setSearchParams(searchParams);
  };

  return (
    <>
      {integrationTableStore.isPageNotFound ? (
        <PageNotFound />
      ) : (
        <Box
          sx={{
            p: 2,
            boxSizing: "border-box",
            height: "100vh",
            overflow: "hidden",
            display: "grid",
            gridTemplateRows: "min-content 1fr",
          }}>
          <IntergationFilterTableSettings />
          <IntegrationTable maxHeight={"calc(100% - 55px)"} />
          {searchParams.get(INTEGRATION_CARD_OPEN) && (
            <TableAsidePage
              integrationId={searchParams.get(INTEGRATION_CARD_OPEN)!}
              onClose={integrationChannel}
            />
          )}
          {searchParams.get(CHANNEL_CARD_OPEN) && (
            <ChannelCardPage
              channelId={searchParams.get(CHANNEL_CARD_OPEN)!}
              onClose={closeChannel}
            />
          )}
          {searchParams.get(PRODUCT_CARD_OPEN) && (
            <GoodFullPage id={+searchParams.get(PRODUCT_CARD_OPEN)!} onClose={closeProduct} />
          )}
          {searchParams.get(CONTRACTOR_CARD_OPEN) && (
            <>
              <ContrActorAsidePage
                contrActorId={searchParams.get(CONTRACTOR_CARD_OPEN)!}
                onClose={closeContractor}
              />
            </>
          )}
          {searchParams.get(GOODS_CREATE_PAGE_OPEN) && <GoodsCreatePage />}
        </Box>
      )}
    </>
  );
});

export default IntegraionsPage;
